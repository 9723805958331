import {
  decimalRegex,
  decimalRegexWithoutNegative,
  numberRegexWithNegative,
  numberRegexWithoutNegative,
} from './regex';

export const getNumberRegexToUse = (
  permiteDecimal: boolean,
  permiteNegative: boolean
) => {
  if (permiteDecimal) {
    return permiteNegative ? decimalRegex : decimalRegexWithoutNegative;
  }
  return permiteNegative ? numberRegexWithNegative : numberRegexWithoutNegative;
};

export const processInputValue = (
  value: string,
  typeNumber: boolean,
  permiteDecimal: boolean,
  name: string
) => {
  let result = value;
  if (typeNumber) {
    if (permiteDecimal) {
      result = result.replace(/,/g, '.');
    }
    if (name.includes('siret')) {
      result = result.replace(/\s+/g, '');
    }
  }
  return result;
};

export const isWithinRange = (
  value: string,
  minNumber: number | undefined,
  maxNumber: number | undefined
) => {
  const numericValue = parseFloat(value);

  if (minNumber === undefined && maxNumber === undefined) {
    return true;
  }

  if (Number.isNaN(numericValue)) {
    return false;
  }

  return (
    (minNumber === undefined || numericValue >= minNumber) &&
    (maxNumber === undefined || numericValue <= maxNumber)
  );
};

export const isValueNumberValid = (
  value: string,
  numberRegexToUse: RegExp,
  name: string,
  allowZero: boolean | undefined
) => {
  return (
    (numberRegexToUse.test(value) &&
      (name.includes('phone') || value !== '0' || allowZero)) ||
    value.trim() === ''
  );
};
