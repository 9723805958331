import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@context/authContext';
import { login } from '@models/auth/apiRequests/authRequests';
import { InputText } from '@components/atomic/inputs/InputText';
import { GlobalContext } from '@context/globalContext';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLoginSchema } from '@utils/validationSchemas';
import { AUTH_ROUTES } from '@utils/routesUrls';
import { placeholderExample } from '@utils/utils';
import { getThemeBySlug } from '@models/settings/apiRequests/settingsRequests';
import { IThemeResponse } from '@models/settings/utils/settingsTypes';
import { ThemeContext } from '@context/ThemeContext';

function Login() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';

  const { themeData, updateThemeData } = useContext(ThemeContext);

  const { updateToken } = useContext(AuthContext);
  const { updateLayoutIsLoading } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);

  const loginThemeButtonText = themeData?.login_button_text;

  interface FormInputs {
    email: string;
    password: string;
  }

  const methods = useForm<FormInputs>({
    resolver: yupResolver(getLoginSchema(t)),
  });

  const placeholders = placeholderExample(t);

  const { handleSubmit } = methods;

  const authenticate = async (formDatas: FieldValues) => {
    setLoading(true);
    const isLogged = await login(
      {
        email: formDatas.email,
        password: formDatas.password,
      },
      updateToken
    );
    setLoading(false);
    if (isLogged) {
      // TODO Sélectionner le thème à partir du slug
      updateLayoutIsLoading(true);
      navigate(from, { replace: true });
    }
  };

  const getThemeWithSlug = async (slugToGet: string) => {
    const response = await getThemeBySlug(slugToGet);
    if (response) {
      updateThemeData(response.theme as IThemeResponse);
      localStorage.setItem('whitelabel_id', response.entity_id.toString());
      updateLayoutIsLoading(false);
    } else {
      updateLayoutIsLoading(false);
      navigate(AUTH_ROUTES.LOGIN);
    }
  };

  useEffect(() => {
    if (slug) {
      if (!themeData) {
        updateLayoutIsLoading(true);
        getThemeWithSlug(slug);
      }
    } else {
      updateLayoutIsLoading(false);
    }
    localStorage.removeItem('user_view_switched');
    localStorage.removeItem('whitelabel_id');
  }, []);

  return (
    <AuthLayout>
      <div className="w-full lg:max-w-[35rem]">
        <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('auth.form_title')}
        </p>
        <FormProvider {...methods}>
          <form id="form-login" onSubmit={handleSubmit(authenticate)}>
            <div className="space-y-6">
              <div className="space-y-4">
                <p className="text-base font-medium leading-5">
                  {t('auth.registration.forms.email')}
                </p>
                <InputText
                  id="email"
                  name="email"
                  placeholder={placeholders.EMAIL}
                  required
                  hideLabel
                  data-test-id="email"
                />
              </div>
              <div className="space-y-4">
                <p className="text-[1rem] font-medium leading-5">
                  {t('forms.password.placeholder')}
                </p>
                <InputText
                  id="password"
                  name="password"
                  placeholder={placeholders.PASSWORD}
                  required
                  type="password"
                  hideLabel
                  data-test-id="password"
                />
              </div>
              <Link
                to={AUTH_ROUTES.FORGOT_PASSWORD}
                className="text-primaryText block w-full text-sm text-right"
              >
                {t('auth.forget_pwd.tooltip')}
              </Link>
              <ButtonOpx
                label={loginThemeButtonText || t('auth.login')}
                formId="form-login"
                isSubmitBtn
                disabled={loading}
                dataTestId="login_button"
                addClass="w-full"
                isLoading={loading}
              />
              {!slug && (
                <p className="text-center">
                  {t('auth.registration.already_registered.no_account')}{' '}
                  <Link
                    to={AUTH_ROUTES.REGISTER}
                    className="text-primaryText"
                    data-test-id="already_registered_link"
                  >
                    {t('auth.registration.already_registered.link')}
                  </Link>
                </p>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </AuthLayout>
  );
}

export { Login };
