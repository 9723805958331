import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IImageData,
  IImageResponse,
} from '@models/settings/utils/settingsTypes';
import { ThemeContext } from '@context/ThemeContext';
import { THEME_IMAGE_TYPES } from '@models/settings/utils/enums';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { WatchPageIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import ImageCustomizer from './ImageCustomizer';

interface LoginPageElementsProps {
  onUpdateImage: Dispatch<SetStateAction<IImageData[]>>;
  setShowLoginPageElementsPreview: Dispatch<SetStateAction<boolean>>;
}

function LoginPageElements({
  onUpdateImage,
  setShowLoginPageElementsPreview,
}: LoginPageElementsProps) {
  const { t } = useTranslation();
  const { themeData } = useContext(ThemeContext);

  const logoAuth: IImageResponse | undefined = themeData?.images.find(
    (image) => image.image === THEME_IMAGE_TYPES.LOGO_AUTH
  );

  const backgroundImageAuth: IImageResponse | undefined =
    themeData?.images.find(
      (image) => image.image === THEME_IMAGE_TYPES.BACKGROUND_IMAGE_AUTH
    );

  return (
    <div className="p-6 space-y-8 border-b border-borderGrey">
      <div className="flex justify-between">
        <p className="font-medium">
          {t('settings.white_label.login_page_elements')}
        </p>
        <ButtonOpx
          icon={<WatchPageIcon fill={blueOpx} />}
          label={t('settings.white_label.preview_login_page')}
          type="tab"
          onClick={() => setShowLoginPageElementsPreview(true)}
        />
      </div>
      <ImageCustomizer
        title={t('settings.white_label.logo')}
        name="logo_auth"
        aspectRatio={1 / 1}
        initialData={
          logoAuth
            ? {
                src: logoAuth.initial,
                customizedSrc: logoAuth.custom,
                brightness: logoAuth.brightness,
                contrast: logoAuth.contrast,
                saturation: logoAuth.saturation,
                crop: {
                  x: logoAuth.crop.x,
                  y: logoAuth.crop.y,
                },
                zoom: logoAuth.crop.zoom,
                croppedAreaPixels: logoAuth.croppedAreaPixels,
              }
            : undefined
        }
        onUpdateImage={onUpdateImage}
      />
      <ImageCustomizer
        name="background_image_auth"
        title={t('settings.white_label.picture')}
        aspectRatio={7 / 12}
        initialData={
          backgroundImageAuth
            ? {
                src: backgroundImageAuth.initial,
                customizedSrc: backgroundImageAuth.custom,
                brightness: backgroundImageAuth.brightness,
                contrast: backgroundImageAuth.contrast,
                saturation: backgroundImageAuth.saturation,
                crop: {
                  x: backgroundImageAuth.crop.x,
                  y: backgroundImageAuth.crop.y,
                },
                zoom: backgroundImageAuth.crop.zoom,
                croppedAreaPixels: backgroundImageAuth.croppedAreaPixels,
              }
            : undefined
        }
        onUpdateImage={onUpdateImage}
      />
    </div>
  );
}

export default LoginPageElements;
