export const urlPartners = '/linkedentities';
export const urlContracts = '/contracts';
export const urlContacts = '/contacts';
export const urlUsers = '/users';
export const urlWorksites = '/worksites';
export const urlRequest = '/demands';
export const urlChanges = '/events';
export const urlOperations = '/operations';
export const urlMaterials = '/materials';
export const apiKeyGoogle = process.env.REACT_APP_GOOGLE;
export const urlDeposits = '/deposits';
export const urlCofrac = '/cofrac';
export const urlUser = '/user';
export const urlRegistration = '/registration';
export const urlInvitation = '/invitation';
export const urlEntities = '/entities';
export const urlSirenSiret = '/sirenSiret';
export const urlCertifications = '/certifications';
export const urlDashboard = '/dashboard';
export const urlAhGeneralData = '/document/ah/generalData';
export const urlAhOperationData = '/document/ah/operationData';
export const urlAides = '/aides';
export const urlMar = '/mar';

// ------------------ GLOBAL ------------------ //
export const urlGetGlobalEnum = '/utils/enum';
export const urlPostLinkedFiles = '/linkedfiles';
export const urlPostWorksiteLinkedFiles = (id: number) =>
  `/linkedfiles/worksite/${id}`;
export const urlGetCheckLinkedFileSignature = '/linkedfiles/check/signature';
export const urlGetFilters = (filter: number) => `/utils/filters/${filter}`;
export const urlPostGlobalSearch = 'utils/search';
export const urlGetLinkedFiles = (id: number, type: string) =>
  `/linkedfiles/list/${id}/${type}`;

export const urlGetLinkedFileArchives = (id: number) =>
  `/linkedfiles/archives/${id}`;
export const urlGetSignatureProcedure = (id: number) =>
  `/linkedfiles/${id}/signature_procedure`;

export const urlLinkedFileAction = (id: number) => `/linkedfiles/${id}`;
export const urlPutLinkedFilesCustomName = (id: number) =>
  `/linkedfiles/${id}/custom_name`;
export const urlGetLinkedFileDocumentAnalysis = (id: number) =>
  `/linkedfiles/analysis/${id}`;
export const urlGetExportCsvDocumentAnalysis = (id: number) =>
  `/linkedfiles/analysis/${id}/export_csv`;

// ------------------ AUTHENTICATION ------------------ //
export const urlPostAuthentication = '/authenticate';
export const urlGetUser = urlUser;
export const urlPostPasswordForgotten = '/forgot_password';
export const urlPostPasswordReset = '/reset_password';
export const urlAuth = '/auth';
export const urlPostPwdSendCode = `${urlUser}/send/code_reset_password`;
export const urlPostActivateResetPwd = `${urlUser}/activate/reset_password`;
export const urlLogout = '/logout';

// ------------------ REGISTRATION ------------------ //
export const urlPostSiretInfo = `${urlRegistration}/siret_info`;
export const urlPostRepresentativesInfo = `${urlRegistration}/representatives`;
export const urlPostRegisteredEmail = `${urlRegistration}/check_registered_email`;
export const urlPutRegistrationCreate = `${urlRegistration}/create`;
export const urlPostRegistrationLinkedFiles = `${urlRegistration}/linkedfiles`;
export const urlDeleteRegistrationLinkedFiles = `${urlRegistration}/linkedfiles`;
export const urlPostRegistrationConfirm = `${urlRegistration}/confirm`;
export const urlPostCompleteCollabDetails = `${urlRegistration}/complete_collab_details`;
export const urlPostSubscriptionUpgrade = (entityTypeId: number) =>
  `${urlEntities}/${entityTypeId}/subscribe`;

// ------------------ INVITATION ------------------ //
export const urlGetInvitationInfo = (id: string) =>
  `${urlInvitation}/info/${id}`;
export const urlSetPartnership = (id: string) =>
  `${urlInvitation}/partnership/${id}`;
export const urlValidateInvitation = (id: string) =>
  `${urlInvitation}/validate/${id}`;
export const urlPostInvitationAccountCreated = `${urlInvitation}/account/confirm`;

// ------------------ BENEFICIARIES ------------------ //
// TODO Vérifier la correspondance avec le back (Revoir le nommage des const)
export const urlBeneficiaries = '/beneficiaries';

export const urlGetBeneficiariesByOperationType = `${urlBeneficiaries}/list`;

export const urlPostStoreBeneficiary = (conventionId?: number) =>
  conventionId ? `${urlBeneficiaries}/${conventionId}` : urlBeneficiaries;
export const urlGetOneBeneficiary = (id: number | null) =>
  `${urlBeneficiaries}/${id}`;

export const urlGetRecentsBeneficiaries = `${urlBeneficiaries}/list/recents`;

export const urlGetAndPostBeneficiary = (id: number) =>
  `${urlBeneficiaries}/${id}`;

export const urlPutBeneficiary = (id: number) =>
  `${urlBeneficiaries}/${id}/address`;

export const urlPostBeneficiaryView = `${urlBeneficiaries}/view`;

// ------------------ CONTRACTS ------------------ //
export const urlGetContractTypesSale = `${urlContracts}/contractType/vente`;
export const urlGetContractTypesMandant = `${urlContracts}/contractType/mandant`;

export const urlGetContractInfos = (id: number) =>
  `${urlContracts}/${id}/informations`;
export const urlUpdateContact = (id: number) =>
  `${urlContracts}/contacts/${id}`;
export const urlGetContractsBuyingPrices = (id: number) =>
  `${urlContracts}/${id}/buyingPrice`;
export const urlGetVolumeDetail = (id: number) =>
  `${urlContracts}/${id}/volumeDetails`;

export const urlPostContractDraft = `${urlContracts}/save-draft`;
export const urlGetContractDraft = (id: number) =>
  `${urlContracts}/get-draft/${id}`;

export const urlPostUpsertTransfer = `${urlContracts}/upsertTransfer`;
export const urlPostUpsertLot = `${urlContracts}/upsertLot`;

export const urlGetAndDeleteTransfer = (id: number) =>
  `${urlContracts}/transfers/${id}`;
export const urlGetTransferLinkedOperations = (id: number) =>
  `${urlContracts}/transfers/${id}/worksitesOperationsLinked`;
export const urlGetTransferOperationsToLink = (id: number) =>
  `${urlContracts}/transfers/${id}/worksitesOperationsToLink`;

export const urlPostTransferLinkOperation = `${urlContracts}/transfers/linkOperations`;
export const urlPostTransferUnlinkOperation = `${urlContracts}/transfers/unLinkOperations`;
export const urlPostUpdateVolume = `${urlContracts}/worksiteOperations/updateVolume`;
export const urlGetDeposits = (id: number) => `${urlContracts}/${id}/deposits`;
export const urlGetDeliveries = (id: number) =>
  `${urlContracts}/${id}/livraisons`;

export const urlControlLot = (id: number) =>
  `${urlContracts}/lots/${id}/updateControlLotStatus`;
export const urlLotGetListOperationsToControl = (id: number) =>
  `${urlContracts}/lots/${id}/getListOperationsToControl`;
export const urlLotValidateControl = (id: number) =>
  `${urlContracts}/lots/${id}/validateControlLot`;
export const urlFinalizeLot = `${urlContracts}/lots/getInfoToValidateLot`;
export const urlGetAndDeleteLot = (id: number) => `${urlContracts}/lots/${id}`;
export const urlGetLotLinkedOperations = (id: number) =>
  `${urlContracts}/lots/${id}/worksitesOperationsLinked`;
export const urlGetLotOperationsToLink = (id: number) =>
  `${urlContracts}/lots/${id}/worksitesOperationsToLink`;
export const urlLotLinkOperation = `${urlContracts}/lots/linkOperations`;
export const urlLotUnlinkOperation = `${urlContracts}/lots/unLinkOperations`;
export const urlPostOperationsDdl = `${urlOperations}/selection`;

// ------------------ NEW CONTRACT ------------------ //
export const urlGetTunnels = `${urlContracts}/tunnel`;
export const urlPostContractPartenaires = `${urlContracts}/partenaires`;
export const urlPutContractGeneralInfos = `${urlContracts}/generales`;
export const urlPostContractGenerateReference = `${urlContracts}/reference`;
export const urlPutContractInfos = (id: number, infosType: string) =>
  `${urlContracts}/${id}/info/${infosType}`;
export const urlPutContractBuyingPrices = (id: number) =>
  `${urlContracts}/${id}/storeBuyingPrice`;
export const urlDeleteContractBuyingPrices = (id: number) =>
  `${urlContracts}/operations/${id}`;
export const urlPostListActorsSales = `${urlContracts}/acteur`;
export const urlPutContractStatus = (id: number) =>
  `${urlContracts}/${id}/status`;

export const urlPostContractReferents = (id: number) =>
  `${urlContracts}/${id}/referents`;
export const urlCopyContract = (id: number) => `${urlContracts}/${id}/copy`;
export const urlTemporaryContractPayload = (id: number) =>
  `${urlContracts}/${id}/temp/payload`;
export const urlUpdateContract = `${urlContracts}/update`;

// ------------------ CONVENTIONS ------------------ //
export const urlConventions = '/conventions';
export const urlGetConventionModelTunnel = `${urlConventions}/model/tunnel`;
export const urlGetConventionGeneralInfos = (id: number) =>
  `${urlConventions}/${id}/informations`;

export const urlPostConventionContact = (id: number, contactId: number) =>
  `${urlConventions}/${id}/contacts/${contactId}/updateContact`;
export const urlPostConventionReferents = (id: number) =>
  `${urlConventions}/${id}/referents`;
export const urlGetAndPutConventionPaymentConditions = (id: number) =>
  `${urlConventions}/${id}/paymentConditions`;
export const urlPostConventionWorksites = (id: number) =>
  `${urlConventions}/${id}/worksites`;
export const urlGetConventionOperations = (id: number) =>
  `${urlConventions}/${id}/operations`;
export const urlPostModel = `${urlConventions}/model`;
export const urlPostGenerateModelReference = `${urlConventions}/model/reference`;
export const urlGetConventionModel = (id: number) =>
  `${urlConventions}/model/${id}`;
export const urlPostValidateModelInvitationFile = (id: number) =>
  `${urlConventions}/model/${id}/validate_invitation`;
export const urlGetConventionModelGeneralInfo = (id: number) =>
  `${urlConventions}/model/${id}/information`;

export const urlGetInvitedInstallerList = (id: number) =>
  `${urlConventions}/model/${id}/installer`;
export const urlGetInvitedInstallerForCount = (id: number) =>
  `${urlConventions}/model/${id}/installer/count`;
export const urlDeleteInvitedInstaller = (id: number) =>
  `${urlConventions}/model/${id}/unlink_installer`;

// ------------------ NEW CONVENTION ------------------ //
export const urlGetConventionsRaiList = `${urlConventions}/raiList`;
export const urlPutConventionInfos = (id: number) =>
  `${urlConventions}/${id}/contact`;
export const urlGetConventionBuyingPrices = (id: number) =>
  `${urlConventions}/${id}/buyingPrice`;
export const urlPutConventionBuyingPrices = (id: number) =>
  `${urlConventions}/${id}/storeBuyingPrice`;
export const urlDeleteConventionBuyingPrices = (id: number) =>
  `${urlConventions}/buyingPrice/${id}`;
export const urlGetBuyingPriceValidationConvention = (id: number) =>
  `${urlConventions}/${id}/validateBuyingPrice`;
export const urlGetBuyingPriceValidationContract = (id: number) =>
  `${urlContracts}/${id}/validateBuyingPrice`;
export const urlDuplicateConvention = (id: number) =>
  `${urlConventions}/${id}/copy`;
export const urlEditBuyingPriceConvention = (operationId: number) =>
  `${urlConventions}/operations/${operationId}`;
export const urlPutConventionStatus = (id: number) =>
  `${urlConventions}/${id}/status`;
export const urlPostInvitationFromModel = (id: number) =>
  `${urlConventions}/model/${id}/invitation`;

// ------------------ DEPOSITS ------------------ //
export const urlPostDepositsDeliveries = `${urlDeposits}/contracts_lots`;
export const urlGetDepositRaiList = `${urlDeposits}/rai_list`;
export const urlPutCreateDeposit = `${urlDeposits}/store`;
export const urlGetDepositInfos = (id: number) => `${urlDeposits}/${id}/info`;
export const urlGetDepositLinkedOperations = (id: number) =>
  `${urlDeposits}/${id}/worksites_operations_linked`;
export const urlGetDepositOperationsToLink = `${urlDeposits}/worksites_operations_to_link`;
export const urlDepositLinkOperation = `${urlDeposits}/link_operations`;
export const urlDepositUnLinkOperation = (id: number) =>
  `${urlDeposits}/operations/${id}`;
export const urlDepositDateDeposit = `${urlDeposits}/store`;
export const urlDepositOperationsToControl = (id: number) =>
  `${urlDeposits}/${id}/list_operations_to_control`;
export const urlDownloadDepositDocument = (id: number) =>
  `${urlDeposits}/${id}/documents`;
export const urlDeleteDeposit = (id: number) => `${urlDeposits}/${id}`;

// ------------------ PARTNERS ------------------ //
export const urlGetPartnerTabs = `${urlPartners}/menu`;
export const urlGetPartnerActiveTabs = `${urlPartners}/subMenu`;
export const urlGetPartnerGeneralInfos = (id: number) =>
  `${urlPartners}/${id}/infoGenerales`;
export const urlGetPartnerSubcontractors = (id: number) =>
  `${urlPartners}/${id}/subcontractors`;
export const urlPostPartnerConventions = (id: number) =>
  `${urlPartners}/${id}/conventions`;
export const urlGetPartnerDeposits = (id: number) =>
  `${urlPartners}/${id}/depots`;
export const urlGetPartnerContracts = (id: number) =>
  `${urlPartners}/${id}/contrats`;
export const urlUpdateContactReferent = (worksiteId: number) =>
  `${urlContacts}/worksite/${worksiteId}/referent/update`;
export const urlGetPartnerWorksites = (id: number) =>
  `${urlPartners}/${id}/chantiers`;
export const urlDownloadInstallerInvitationTemplate =
  '/documents/Template_Invitation_installateur.xlsx';
export const urlDownloadModelInvitationInstallerTemplate =
  '/documents/Model_Invitation_installateur.xlsx';

export const urlPostValidateInvitationFile = `${urlPartners}/validate_invitation_file`;
export const urlPostSendInvitationLink = `${urlPartners}/send_invitation_link`;

// ------------------ SUBCONTRACTORS ------------------ //
export const urlGetSubcontractorInfos = (
  installerId: number,
  entityId: number
) => `${urlPartners}/${installerId}/${entityId}/infoSousTraitant`;
export const urlPostSubcontractorInfos = (entityId: number, userId: number) =>
  `${urlPartners}/${entityId}/${userId}/updateSousTraitant`;
export const urlGetSubcontractorWorksites = (id: number) =>
  `${urlPartners}/${id}/chantiers`;
export const urlPostSubcontractor = (worksiteId?: number) =>
  worksiteId
    ? `${urlPartners}/sousTraitants/${worksiteId}`
    : `${urlPartners}/sousTraitants`;

export const urlPostSubcontractorsList = (worksiteId: number) =>
  `${urlPartners}/getSubcontractors/${worksiteId}`;
export const urlPostIsMySubcontractor = (id: number) =>
  `${urlPartners}/${id}/is_my_subcontractor`;

// ------------------ SIRENSiret ------------------ //
export const urlGetEtablissementData = `${urlSirenSiret}/siret`;
export const urlGetEtablissementRge = `${urlSirenSiret}/siret/rge`;

// ------------------ USER ------------------ //
export const urlGetUserContacts = `${urlUsers}/contacts`;

// ------------------ WORKSITES ------------------ //
export const urlGetWorksitesList = `${urlWorksites}/list`;
export const urlGetWorksiteDetails = (id: string) =>
  `${urlWorksites}/${id}?history=true`;
export const urlSaveWorksiteCustomFields = (id: string) =>
  `${urlWorksites}/${id}/custom-fields/save`;
export const urlPutOperationAccessible = `${urlWorksites}/set_operation_accessible`;
export const urlPostProjectedEnd = (id: number) =>
  `${urlWorksites}/${id}/extraDatas`;
export const urlPostSendCTAToBeRecontactedNotification = `${urlWorksites}/cta_to_be_recontacted`;
export const urlPostSubcontractorObjectionNotification = (
  worksiteId: number,
  subcontractorId: number
) =>
  `${urlWorksites}/${worksiteId}/subcontractor/${subcontractorId}/objection/notification`;
export const urlDownloadWorksiteDocuments = (
  worksiteId: number,
  fileName: string
) => `${urlWorksites}/${worksiteId}/${fileName}/download`;
export const urlUpdateWorksiteReferent = `${urlWorksites}/referents/update`;
export const urlUpdateBeneficiaryAmount = (id: number) =>
  `${urlWorksites}/${id}/beneficiary_amount`;

export const urlUpdateWorksitePrimes = (id: string) =>
  `${urlWorksites}/${id}/primes`;

// ------------------ DEMANDES ET ACTIVITES ------------------ //
export const urlGetRequestsList = `${urlRequest}/list`;
export const urlGetOneRequest = (id: string) => `${urlRequest}/${id}`;
export const urlGetRequestsMessages = (id: string) =>
  `${urlRequest}/${id}/message`;
export const urlPostRequestsNewMessage = (id: string) =>
  `${urlRequest}/${id}/message`;
export const urlGetListRequestGroup = `${urlRequest}/group`;
export const urlGetChangesList = `${urlChanges}/list`;
export const urlPostNewMessageChanges = `${urlChanges}/message`;
export const urlPostAnswerMessageChanges = (id: string) =>
  `${urlChanges}/${id}/message`;
export const urlPostMarkNoteAsRead = (id: number) =>
  `${urlRequest}/${id}/note/read`;

export const urlDeleteRequest = (id: string) => `${urlRequest}/${id}`;

// ------------------ NEW WORKSITE ------------------ //
export const urlGetOrDeleteWorksite = (id: number) => `${urlWorksites}/${id}`;
export const urlGetWorksiteWorkflow = `/worksites/workflow`;

export const urlPostWorksiteValidateCreation = `${urlWorksites}/validate`;
export const urlGetParcelMap = (lng: number, lat: number) =>
  `https://apicarto.ign.fr/api/cadastre/parcelle?geom=%7B%22type%22%3A%20%22Point%22%2C%22coordinates%22%3A%5B${lng}%2C${lat}%5D%7D`;
export const urlGetLatLngWithZipCode = (zipCode: string) =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKeyGoogle}`;
export const urlPostWorksiteInfosBeneficiary = (id: number) =>
  `${urlWorksites}/${id}/infos_beneficiary`;
export const urlPostWorksiteInfosLogement = (id: number) =>
  `${urlWorksites}/${id}/infos_accommodation`;
export const urlPostWorksiteInfosSubcontractors = (id: number) =>
  `${urlWorksites}/${id}/infosSubcontractors`;
export const urlPostWorksiteInfosMaterials = (id: number) =>
  `${urlWorksites}/${id}/infosMaterials`;
export const urlPostWorksiteValidateAfterUploadFiles = (id: number) =>
  `${urlWorksites}/${id}/done`;

export const urlDownloadWorksitesExportVue = `${urlWorksites}/exportVue`;

// ------------------ COFRAC ------------------ //
export const urlGetCofracEligibleOperations = `${urlCofrac}/eligible_operations`;
export const urlPutStoreCofrac = `${urlCofrac}/store`;
export const urlGetCofracInfos = (id: number) => `${urlCofrac}/${id}/info`;
export const urlGetCofracOperationsToLink = (id: number) =>
  `${urlCofrac}/${id}/worksites_operations_to_link`;
export const urlGetCofracOperationsLinked = (id: number) =>
  `${urlCofrac}/${id}/worksites_operations_linked`;
export const urlPostLinkCofracOperation = `${urlCofrac}/link_operations`;
export const urlDeleteLinkCofracOperation = (id: number) =>
  `${urlCofrac}/operations/${id}`;
export const urlDeleteCofrac = (id: number) => `${urlCofrac}/${id}`;

// ------------------ EXPORT CSV ------------------ //
export const urlDepositsExportCsv = (id: number) =>
  `${urlDeposits}/export_csv/create/${id}`;
export const urlCofracExportCsv = (id: number) =>
  `${urlCofrac}/export_csv/create/${id}`;

// ------------------ ADMIN ------------------ //
export const urlAdminGetUsersList = `/admin/users`;
export const urlAdminSetUserAccountCompliance = (id: number) =>
  `/admin/users/${id}/account_compliance`;
export const urlAdminImpersonateUser = (id: number) =>
  `/admin/users/${id}/impersonate`;
export const urlAdminLeaveImpersonation = `/admin/users/leave_impersonation`;
export const urlAdminMarkAsNonCompliant = (id: number) =>
  `/admin/users/${id}/non_compliant`;
export const urlAdminGetUserActionsHistory = (id: number) =>
  `/admin/users/${id}/revisions`;
export const urlAdminRenewIdentityCheckDemand = (id: number) =>
  `/admin/users/${id}/renew_identity_check_demand`;
export const urlAdminContracts = '/admin/contracts';

export const urlAdminGetWorksitesList = `/admin/worksites/list`;

// -------------- MATERIAL ------------------- //
export const urlMaterialProducts = `${urlMaterials}/products`;
export const urlMaterialBrands = `${urlMaterials}/brands`;
export const urlSettingsBrands = `${urlMaterialBrands}/onlyCustom`;
export const urlProductCreateByName = `${urlMaterialProducts}/create_by_name`;
export const urlBrandCreation = `${urlMaterialBrands}/create`;
export const urlBrandDelete = (id: number) =>
  `${urlMaterialBrands}/${id}/delete`;
export const urlProductCreation = `${urlMaterialProducts}/create`;
export const urlProductUpdate = (id: number) =>
  `${urlMaterialProducts}/${id}/update`;
export const urlProductDelete = (id: number) =>
  `${urlMaterialProducts}/${id}/delete`;
export const urlSettingsProductsUpdate = `${urlMaterials}/products/`;

// -------------- ENTITY ------------------- //
export const urlUpdateEntity = (id: number) => `${urlEntities}/${id}`;
export const urlGetSignatories = (id: number) =>
  `${urlEntities}/${id}/signatories/list`;
export const urlPostBuyCredits = (id: number) =>
  `${urlEntities}/${id}/buy_credits`;
export const urlGetNbCredits = (id: number) =>
  `${urlEntities}/${id}/nb_credits`;
export const urlPostAskSignature = (id: number) =>
  `${urlEntities}/${id}/ask_signature`;
export const urlGetCreditsPricingForEntity = (id: number) =>
  `${urlEntities}/${id}/pricing_credits`;

// -------------- CERTIFICATIONS ------------------- //
export const urlGetCertificationsList = (entityId: number) =>
  `${urlCertifications}${urlEntities}/${entityId}`;

export const urlGetCertification = (id: number) => `${urlCertifications}/${id}`;

// -------------- DASHBOARD ------------------- //
export const urlGetWorksiteStepsDatas = `${urlDashboard}/worksiteSteps`;
export const urlGetWorksitePerMonthDatas = `${urlDashboard}/worksitePerMonth`;

// -------------- Attestation sur l'honneur (AH) ------------------- //
export const urlGetAhGeneralData = (id: number) => `${urlAhGeneralData}/${id}`;

export const urlEditAhGeneralData = (id: number) =>
  `${urlAhGeneralData}/edit/${id}`;

export const urlPostAhOperationData = (id: number) =>
  `${urlAhOperationData}/${id}`;

export const urlEditAhOperationData = (id: number) =>
  `${urlAhOperationData}/edit/${id}`;

// -------------- SETTINGS ------------------- //

export const urlGetCustomFields = `${urlUser}/custom-fields`;
export const urlPostCustomField = `${urlUser}/custom-fields/create`;
export const urlUpdateCustomField = (customFieldId: number) =>
  `${urlUser}/custom-fields/${customFieldId}/update`;

export const urlDeleteCustomField = (customFieldId: number) =>
  `${urlUser}/custom-fields/${customFieldId}/delete`;

export const urlGetOrPostTheme = (entityId: number) =>
  `${urlEntities}/${entityId}/theme`;

export const urlGetThemeBySlug = (slug: string) =>
  `${urlEntities}/theme/${slug}`;

export const urlGetThemesList = `${urlUser}/themes_list`;

// ------------------ AIDES ------------------ //
export const urlGetWorksiteGraph = `${urlAides}/fields`;
export const urlGetIncomes = `${urlAides}/incomes`;
export const urlPostCalculatePrimes = `${urlAides}/amounts`;
export const urlPostPayload = `${urlAides}/payload`;

export const urlPostWorksiteUpdatePrimes = (id: string) =>
  `${urlAides}${urlWorksites}/${id}/amounts`;

// ------------------ SIGNATURE PROCEDURE ------------------ //
export const urlGetCancelSignatureProcedure = (id: number) =>
  `signature_procedure/cancel/${id}`;
export const urlPostResendSignatureProcedure = (id: number) =>
  `signature_procedure/resend/${id}`;

// ------------------ MAR ------------------ //
const urlCustomers = '/customers';
export const urlGetMarCustomers = `${urlMar}${urlCustomers}/list`;
export const urlGetMarRecentCustomers = `${urlMar}${urlCustomers}/list/recents`;
export const urlPutMarStoreCustomer = `${urlMar}${urlCustomers}`;
export const urlPostMarCreateWorksite = `${urlMar}${urlWorksites}`;
export const urlGetMarWorksiteDataWithId = (id: number) =>
  `${urlMar}${urlWorksites}/${id}`;
export const urlPostMarValidateHousingTypology = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/typologie_logement`;
export const urlFetchIncomesMar = `${urlMar}/aides/incomes`;
export const urlPostMarValidateCustomerInformation = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/informations_usager`;
export const urlGetCollaborators = `${urlMar}/collaborators`;
export const urlPostMarValidateEnergyAudit = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/audit`;
export const urlPostMarValidateHousingAnalysis = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/analyse_logement`;
export const urlPostEnergyClass = `${urlMar}${urlWorksites}/energyClass`;
export const urlPostMarOperationsList = `${urlMar}/operations`;
export const urlPostMarScenarioPrime = `${urlMar}/aides/prime`;

export const urlPostMarValidateOneScenario = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/scenarios/validate`;
export const urlPostMarValidateScenariosAndCalculations = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/scenarios`;
export const urlPostMarProsList = `${urlMar}${urlWorksites}/pros/list`;
export const urlPostMarPro = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/pro`;
export const urlPostMarValidateOneQuote = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/devis/validate`;
export const urlPostMarValidateQuotes = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/devis`;
export const urlDeleteMarQuotes = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/devis`;
export const urlGetQuoteWorksCertificate = (id: number) =>
  `/document/marWorksite/${id}/attestation-devis`;
export const urlPostmarValidateAdministrativeMandate = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/mandats`;
export const urlPostMandateGeneration = (id: number) =>
  `/document/marWorksite/${id}/mandats`;
export const urlPostMarValidateCeeAnah = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/cee_anah`;
export const urlPostGenerateAdditionalCommitmentsCertificate = (id: number) =>
  `/document/marWorksite/${id}/attestation-cee`;
export const urlGetMarDocuments = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/docs`;
export const urlGetMarDocumentsDownload = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/docs/download`;
export const urlPostMarValidateDeposit = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/depot_demande`;
export const urlPostMarValidateNotificationOfGrant = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/notification_octroi`;
export const urlPostMarValidateWorkInProgress = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/travaux_en_cours`;
export const urlGetMarQuotesSelected = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/devis/list`;
export const urlPostMarValidateInvoice = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/factures/validate`;
export const urlGetInvoiceWorksCertificate = (id: number) =>
  `/document/marWorksite/${id}/attestation-facture`;
export const urlPostMarValidateEndOfWork = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/travaux_fin`;
export const urlPostMarValidateAfterWork = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/travaux_apres`;
export const urlPostMarValidatePaymentLetter = (id: number) =>
  `${urlMar}${urlWorksites}/${id}/versement`;
export const urlPostMarWorksitesList = `${urlMar}${urlWorksites}/list`;
