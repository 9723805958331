import { LogoOpxIconBlueFill } from '@assets/images/svgComponents';
import authImg from '@assets/images/auth_bg_logo.svg';
import { Link, useLocation } from 'react-router-dom';
import ChatbotScript from '@models/auth/components/registrationSteps/ChatbotScript';
import { AUTH_ROUTES } from '@utils/routesUrls';
import { THEME_IMAGE_TYPES } from '@models/settings/utils/enums';
import { useContext } from 'react';
import { ThemeContext } from '@context/ThemeContext';

interface IAuthLayoutProps {
  children: JSX.Element;
  isModal?: boolean;
}

function AuthLayout({ children, isModal }: IAuthLayoutProps): JSX.Element {
  const { themeData } = useContext(ThemeContext);

  const authThemeLogoData = themeData?.images?.find(
    (image) => image.image === THEME_IMAGE_TYPES.LOGO_AUTH
  );

  const authThemeBackgroundImageData = themeData?.images?.find(
    (image) => image.image === THEME_IMAGE_TYPES.BACKGROUND_IMAGE_AUTH
  );

  const isRegister = useLocation().pathname.includes(AUTH_ROUTES.REGISTER);

  return (
    <div
      className={`h-screen  ${
        isModal
          ? 'absolute top-0 right-0 bg-[#12121280] z-[10000]'
          : 'bg-backgroundBody'
      } flex items-center justify-center w-screen `}
    >
      <ChatbotScript />
      <div className="form_container min-w-[90%] lg:min-w-[70rem] mx-auto">
        <div className="rounded-[16px] overflow-hidden bg-white mb-8 border border-borderGrey relative flex">
          <Link
            to={AUTH_ROUTES.LOGIN}
            className="absolute top-[1.5rem] left-[1.5rem] z-[1000]"
          >
            {authThemeLogoData && !isRegister ? (
              <img
                src={authThemeLogoData.custom}
                alt="logo"
                className="w-[3rem] h-[3rem]"
              />
            ) : (
              <LogoOpxIconBlueFill width="3rem" height="3rem" />
            )}
          </Link>
          <div className="lg:px-[6rem] px-10 py-[6rem] flex items-center flex-grow ">
            {children}
          </div>
          <div className="ml-auto pr-6 py-6 hidden lg:block min-h-[40rem]">
            <div className="bg-blueOpx h-full rounded-[1rem] min-w-[22rem] w-[22rem] flex items-end overflow-hidden">
              <img
                src={
                  authThemeBackgroundImageData && !isRegister
                    ? authThemeBackgroundImageData?.custom
                    : authImg
                }
                className="w-full"
                alt="auth background"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AuthLayout };

AuthLayout.defaultProps = {
  isModal: false,
};
