import { IAccountInfos, IAccountSignatory } from './userTypes';

export const ACCESS_TYPE_ENUM = {
  WORKSITES_MANAGEMENT: 1,
  DEPOSITS_MANAGEMENT: 2,
  CONTRACTS_CREATION: 3,
  OPERATIONS_DELIVERY_MANAGEMENT: 4,
  USERS_MANAGEMENT: 5,
  WORKSITE_REFERENT_MANAGEMENT: 6,
};

export const MY_ACCOUNT_TABS = [
  {
    entityId: 0,
    submenu: 1,
    content: 'informations',
  },
  {
    entityId: 0,
    submenu: 2,
    content: 'users',
  },
  {
    entityId: 0,
    submenu: 3,
    content: 'password',
  },
  {
    entityId: 0,
    submenu: 4,
    content: 'history',
  },
  {
    entityId: 0,
    submenu: 5,
    content: 'certifications',
  },
];

export const DEFAULT_ACCOUNT_INFOS: IAccountInfos = {
  informations_entite: {
    active: false,
    address: null,
    city: null,
    company_name: null,
    contact_email: null,
    contracts: [],
    conventions: [],
    country: null,
    employees_number: null,
    id: 0,
    logo: null,
    phone_number: null,
    revenue: null,
    signatories: [],
    siren: null,
    siret: null,
    type: null,
    status: 0,
    website: null,
    zipcode: null,
    iban_payment: null,
    associated_worksite_count: null,
    associated_contract_count: null,
  },
  informations_utilisateur: {
    active: true,
    current_entity_type: null,
    email: null,
    firstname: null,
    function: null,
    id: 0,
    lastname: null,
    permissions_names: [],
    phone_number: null,
    photo_url: null,
    role_name: null,
    typeCompte: null,
    profile_type: 1,
    entity_type: 4,
  },
};

export const DEFAULT_SIGNATORY: IAccountSignatory = {
  id: 0,
  entity_id: null,
  civility: null,
  firstname: null,
  lastname: null,
  phone_number: null,
  function: null,
  email: null,
};

export const CREER = {
  ENTITE: 'creer-entite',
  COMPTE: 'creer-compte',
};
