import { TFunction } from 'i18next';
import {
  HABITATION,
  HEATING_TYPE,
  WORKSITE_AGE,
} from '@models/worksiteCreation/utils/enums';
import {
  blueSecondary,
  darkGreen,
  green,
  orange,
  purple,
  textGrey,
} from '@assets/color';
import { fetchAhGeneralData } from '@models/worksites/apiRequests/worksitesRequests';
import { Dispatch, SetStateAction } from 'react';
import { ENTITY_TYPES } from '@utils/roles';
import {
  IAhExtraDataItem,
  IGlobalEnumType,
  IKeyStringType,
} from '../../../types/globalTypes';
import { WORKSITE_STATUS } from './enums';

export const totalBonus = (details: any): number => {
  return details.reduce((total: number, detail: any) => {
    const cdpAmount = parseFloat(detail.cdp_amount) || 0;
    const ceeAmount = parseFloat(detail.cee_amount) || 0;
    const mprAmount = parseFloat(detail.mpr_amount) || 0;

    const ceeToAdd = cdpAmount > 0 ? cdpAmount : ceeAmount;
    return total + ceeToAdd + mprAmount;
  }, 0);
};

export const getKeyByValue = (
  obj: IKeyStringType,
  value: string,
  t: TFunction
) => {
  const keys = Object.keys(obj);
  const valueToSend =
    value === t('worksites.toprocess') ? 'Tâches à réaliser' : value;
  const result = keys.find((el) => obj[el] === valueToSend);
  if (result) {
    return parseFloat(result);
  }
  return null;
};

export const getWorksiteOperationStatusColor = (step: number) => {
  switch (Number(step)) {
    case 0:
      return textGrey;
    case 1:
      return blueSecondary;
    case 3:
      return purple;
    case 6:
    case 8:
      return green;
    case 7:
      return darkGreen;
    default:
      return orange;
  }
};

export const getAideApiDataFromValue = (
  value: string,
  globalEnum: IGlobalEnumType,
  enumValue: string
): string => {
  switch (enumValue) {
    case 'age_type':
      if (value === globalEnum[enumValue][1]) return WORKSITE_AGE.BETWEEN;
      if (value === globalEnum[enumValue][3]) return WORKSITE_AGE.OLD;
      return WORKSITE_AGE.YOUNG;
    case 'housing_type':
      if (value === globalEnum[enumValue][1]) return HABITATION.HOUSE;
      if (value === globalEnum[enumValue][2]) return HABITATION.APARTMENT;
      return '';
    case 'heating_type':
      if (value === globalEnum[enumValue][1]) return HEATING_TYPE.COMBUSTIBLE;
      if (value === globalEnum[enumValue][2]) return HEATING_TYPE.ELECTRICITY;
      return '';
    default:
      return ''; //
  }
};

// récupère les données générales AH et les formate pour l'affichage
export const getAhGeneralData = async (
  id: number,
  updateAhGeneralData: Dispatch<SetStateAction<IAhExtraDataItem[] | undefined>>
) => {
  const responseFromApi = await fetchAhGeneralData(id);

  if (responseFromApi) {
    // on filtre la réponse en retirant les données qui ont la clé 'notDisplay'
    const filterRespApi: any = Object.values(responseFromApi).filter(
      (obj: any) => {
        return !obj.notDisplay;
      }
    );
    const transformedData: IAhExtraDataItem[] = Object.values(filterRespApi);
    updateAhGeneralData([...transformedData]);
  }
};

export const canEditWorksite = (status: number, userType: number): boolean => {
  return (
    (status <= WORKSITE_STATUS.WORKSITE_IN_PROGRESS &&
      userType === ENTITY_TYPES.INSTALLATEUR) ||
    userType !== ENTITY_TYPES.INSTALLATEUR
  );
};
