import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { ThemeContext } from '@context/ThemeContext';
import { AddIcon } from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import { MAR_ROUTES, WORKSITES_ROUTES } from '@utils/routesUrls';
import { ROLES } from '@utils/roles';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { blueOpx, white } from '@assets/color';

function ButtonNewWorksite() {
  const { sidebarMenuIsOpen, roleUser } = useContext(GlobalContext);
  const { themeData } = useContext(ThemeContext);
  const { t } = useTranslation();

  const menuButtonThemeData = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.MENU_BUTTON
  );

  const menuThemeData = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.MENU
  );

  const textColor = menuButtonThemeData?.text_color || white;
  const backgroundColor = menuButtonThemeData?.background_color || blueOpx;

  return (
    <Link
      to={
        roleUser === ROLES.MAR
          ? MAR_ROUTES.WORKSITES_NEW
          : WORKSITES_ROUTES.WORKSITES_NEW
      }
    >
      <div
        className={`py-[.5rem]  ${
          sidebarMenuIsOpen
            ? 'flex space-x-[1rem] px-[1.5rem] rounded-default'
            : 'items-center flex justify-center w-auto'
        }  mt-[1.5rem] items-center `}
        data-test-id="new_worksite"
        style={{
          color: textColor,
          backgroundColor: sidebarMenuIsOpen ? backgroundColor : 'transparent',
        }}
      >
        <div className={!sidebarMenuIsOpen ? 'w-auto rounded-default p-1' : ''}>
          <AddIcon
            width="1.5rem"
            height="1.5rem"
            fill={
              !sidebarMenuIsOpen
                ? menuThemeData?.text_color
                : menuButtonThemeData?.text_color || white
            }
          />
        </div>
        {sidebarMenuIsOpen && (
          <p className="text-[1rem]">
            {roleUser === ROLES.MAR
              ? t('mar.new_project')
              : t('worksites.new_work_site')}
          </p>
        )}
      </div>
    </Link>
  );
}

export { ButtonNewWorksite };
