/* eslint-disable react/no-array-index-key */
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { InputTypes } from '@utils/utils';
import { ColorCube } from '@components/atomic/ColorCube';
import { CheckIcon } from '@assets/images/svgComponents';
import { borderGrey, green } from '@assets/color';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { useContext, useEffect, useState } from 'react';
import { IDocumentMar } from '@models/mar/utils/marTypes';
import {
  getDocumentsMar,
  getExportDocumentsMar,
  postLinkedFile,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { ILinkedFile } from '@globalTypes/globalTypes';
import ModalAddDocumentDepositOfApplication from '@models/mar/components/worksiteCompletion/stepsCompletion/depositOfApplication/ModalAddDocumentDepositOfApplication';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import LoaderFile from '@models/mar/components/LoaderFile';

function DocumentsDepositOfApplication() {
  const { t } = useTranslation();
  const { worksiteData } = useContext(WorksiteCreationMarContext);
  const [documentList, setDocumentList] = useState<IDocumentMar[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [indexOfDocumentLoading, setIndexOfDocumentLoading] =
    useState<number>();

  const { unregister } = useFormContext();

  const getDocuments = async () => {
    const response = await getDocumentsMar(worksiteData?.id || 0);
    if (response) {
      setDocumentList(response);
    }
  };

  const onUploadDocument = async (
    file: File,
    originalLinkedFile: ILinkedFile,
    indexDocument?: number,
    customName?: string
  ) => {
    setIndexOfDocumentLoading(indexDocument);
    const response = await postLinkedFile(file, originalLinkedFile, customName);
    if (response) {
      setDocumentList((prev) => {
        const newDocumentList = prev.map((document) => {
          if (document.file.file_type === originalLinkedFile.file_type) {
            return {
              ...document,
              file: response,
            };
          }
          return document;
        });
        if (customName) {
          return [...newDocumentList, { label: customName, file: response }];
        }
        return newDocumentList;
      });
      setModalIsOpen(false);
    }
    setIndexOfDocumentLoading(undefined);
  };

  const exportDocuments = async () => {
    setExportLoading(true);
    const response = await getExportDocumentsMar(worksiteData?.id || 0);
    if (response) {
      // Crée un objet Blob à partir de la réponse
      const blob = new Blob([response], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);

      // Fonctions pour normaliser et nettoyer les chaînes
      const normalizeString = (str: string) =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const sanitizeFilename = (str: string) =>
        str.replace(/[^a-zA-Z0-9]/g, '');

      // Récupérer et traiter les informations du client
      const firstname = sanitizeFilename(
        normalizeString(worksiteData?.customer?.firstname || '')
      );
      const lastname = sanitizeFilename(
        normalizeString(worksiteData?.customer?.lastname || '')
      );

      // Obtenir la date et l'heure actuelles
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0 en JS
      const year = now.getFullYear();

      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      // Construire la chaîne de date et heure
      const dateStr = `${day}${month}${year}`;
      const timeStr = `${hours}${minutes}${seconds}`;

      // Construire le nom du fichier
      const filename = `${firstname}${lastname}_${dateStr}_${timeStr}.zip`;

      // Crée un lien invisible pour déclencher le téléchargement
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); // Nom du fichier à télécharger
      link.style.display = 'none';

      // Ajoute le lien au document et déclenche le clic
      document.body.appendChild(link);
      link.click();

      // Nettoie le DOM et révoque l'URL de l'objet
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
    setExportLoading(false);
  };

  const resetFormAddDocument = () => {
    unregister('file_to_upload');
  };

  useEffect(() => {
    if (!modalIsOpen) {
      resetFormAddDocument();
    }
  }, [modalIsOpen]);

  useEffect(() => {
    getDocuments();
  }, []);

  if (documentList.length === 0) {
    return (
      <>
        {[...Array(9)].map((_, index) => (
          <LoaderFile key={`loader-file-${index}`} />
        ))}
      </>
    );
  }

  return (
    <>
      {documentList.map((document, index) => {
        if (!document.file || !document.file.id) {
          if (index === indexOfDocumentLoading) {
            return (
              <div key={document.label} className="flex flex-col space-y-2">
                <div className="text-textGrey text-[.75rem] leading-[.75rem]">
                  {document.label}
                </div>
                <LoaderDivSkeleton height="2rem" backgroundColor={borderGrey} />
              </div>
            );
          }
          return (
            <OneMarForm
              key={document.label}
              dataForm={{
                fields: [
                  {
                    title: document.label,
                    name: `file_${index}`,
                    type: InputTypes.DOCUMENT,
                    fullWidth: true,
                    defaultDocument: document.file,
                    onUploadDocument: (file, originalLinkedFile) =>
                      onUploadDocument(file, originalLinkedFile, index),
                  },
                ],
              }}
            />
          );
        }
        return (
          <div key={document.label} className="flex flex-col space-y-2">
            <div className="text-textGrey text-[.75rem] leading-[.75rem]">
              {document.label}
            </div>
            <div className="w-full flex items-center p-2 border border-borderGrey rounded-default">
              <a
                href={document.file.file_url as string}
                className="w-full h-full flex items-center space-x-2 "
                target="_blank"
                rel="noreferrer"
              >
                <ColorCube
                  size="1.5rem"
                  numberOrIcon={<CheckIcon />}
                  color={green}
                  opacity
                />
                <span className="text-sm text-primaryText">
                  {document.file.file_name}
                </span>
              </a>
            </div>
          </div>
        );
      })}
      <ButtonOpx
        label={`+ ${t(
          'mar.worksite_creation.worksite_completion.folder_details.deposit_of_application.add_document'
        )}`}
        type="tierciary"
        onClick={() => setModalIsOpen(true)}
      />
      <ButtonOpx
        label={`${t(
          'mar.worksite_creation.worksite_completion.folder_details.deposit_of_application.export_folder_button'
        )}`}
        onClick={exportDocuments}
        disabled={exportLoading}
        isLoading={exportLoading}
      />
      {modalIsOpen && (
        <ModalAddDocumentDepositOfApplication
          setModalIsOpen={setModalIsOpen}
          onUploadDocument={onUploadDocument}
        />
      )}
    </>
  );
}

export default DocumentsDepositOfApplication;
