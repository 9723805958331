/* eslint-disable jsx-a11y/anchor-is-valid */
import { cloneElement, useContext, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { ThemeContext } from '@context/ThemeContext';
import { black, textGrey, white } from '@assets/color';
import { HeaderContext } from '@context/headerContext';
import { clearTabStorage } from '@components/subHeader/TabsLeftPart';
import {
  CONTRACTS_ROUTES,
  DASHBOARD_ROUTES,
  SETTINGS_ROUTES,
} from '@utils/routesUrls';
import { convertHexToRGBA } from '@utils/functions';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { INavLinkSidebar } from '../../../types/globalTypes';

interface ILinkButtonProps {
  navLink: INavLinkSidebar;
  isExample?: boolean;
  textColorWhiteLabel?: 'black' | 'white';
}

function LinkButton({
  navLink,
  isExample,
  textColorWhiteLabel,
}: ILinkButtonProps): JSX.Element {
  const location = useLocation();
  const { sidebarMenuIsOpen } = useContext(GlobalContext);
  const { themeData } = useContext(ThemeContext);
  const { refreshHeader } = useContext(HeaderContext);

  const [isShown, setIsShown] = useState(false);

  const activateMenu = useMemo(() => {
    const strNavLink = navLink.link || '';
    if (strNavLink !== CONTRACTS_ROUTES.CONTRACTS) {
      const singularNavLink = strNavLink.substring(0, strNavLink.length - 1);
      return location.pathname.indexOf(singularNavLink) !== -1;
    }
    return [CONTRACTS_ROUTES.CONTRACTS, CONTRACTS_ROUTES.CONVENTION].some(
      (substring) => location.pathname.includes(substring)
    );
  }, [navLink, location]);

  const menuThemeData = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.MENU
  );

  const pageActive =
    navLink.link !== undefined &&
    (location.pathname === navLink.link ||
      (navLink.link !== DASHBOARD_ROUTES.DASHBOARD && activateMenu));

  const inOneOfBottomMenu =
    navLink.link?.includes('support') ||
    navLink.link === SETTINGS_ROUTES.SETTINGS;

  const getColors = () => {
    const textColorToUse = textColorWhiteLabel || menuThemeData?.text_color;
    if (textColorToUse === 'black') {
      return {
        textActiveColor: black,
        textColor: black,
        bgActiveColor: convertHexToRGBA(white, 0.4),
      };
    }
    if (textColorToUse === 'white') {
      return {
        textActiveColor: white,
        textColor: white,
        bgActiveColor: convertHexToRGBA(black, 0.4),
      };
    }
    return {
      textActiveColor: white,
      textColor: textGrey,
      bgActiveColor: convertHexToRGBA(textGrey, 0.2),
    };
  };

  const { textActiveColor, textColor, bgActiveColor } = getColors();

  const linkActive = pageActive || inOneOfBottomMenu;

  const iconWithProps = cloneElement(navLink.icon as JSX.Element, {
    fill: linkActive ? textActiveColor : textColor,
    width: '1.5rem',
    height: '1.5rem',
  });

  const onClickOnLink = () => {
    if (isExample) return;
    if (!navLink.link?.includes('support')) {
      refreshHeader();
      clearTabStorage();
    }
  };

  const buttonComponent = (
    <button
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      type="button"
      className={`overflow-hidden  ${
        !sidebarMenuIsOpen ? 'justify-center w-min px-[.5rem]' : 'px-[1rem]'
      } w-full rounded-lg flex navLinks-center py-[.5rem] rounded-default items-center`}
      style={{
        color: linkActive ? textActiveColor : textColor,
        backgroundColor: pageActive || isShown ? bgActiveColor : 'transparent',
      }}
      data-test-id={navLink.dataTestId}
    >
      {iconWithProps}
      {sidebarMenuIsOpen && (
        <p
          className="ml-[1rem] whitespace-nowrap"
          style={{
            color: linkActive ? textActiveColor : textColor,
          }}
        >
          {navLink.name}
        </p>
      )}
    </button>
  );

  return (
    <div>
      {navLink.link?.includes('support') ? (
        <a
          href={isExample ? '#' : navLink.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClickOnLink}
          className={!sidebarMenuIsOpen ? 'flex justify-center' : ''}
        >
          {buttonComponent}
        </a>
      ) : (
        <Link
          to={isExample ? '#' : navLink.link || '/'}
          onClick={onClickOnLink}
          className={!sidebarMenuIsOpen ? 'flex justify-center' : ''}
        >
          {buttonComponent}
        </Link>
      )}
    </div>
  );
}

export { LinkButton };

LinkButton.defaultProps = {
  isExample: false,
  textColorWhiteLabel: undefined,
};
