import { applyTheme } from '@assets/color';
import { ThemeContext } from '@context/ThemeContext';
import { SiretSection } from '@models/auth/components/registrationSteps/SiretSection';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { useContext, useEffect } from 'react';

interface IRegistrationProps {
  stepSiret?: boolean;
}

function Registration({ stepSiret }: IRegistrationProps) {
  const { themeData } = useContext(ThemeContext);

  useEffect(() => {
    applyTheme(undefined);

    return () => {
      applyTheme(themeData);
    };
  }, []);

  return <AuthLayout>{stepSiret ? <SiretSection /> : <div />}</AuthLayout>;
}

export { Registration };

Registration.defaultProps = {
  stepSiret: true,
};
