/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Tag } from '@components/atomic/Tag';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import { getAllUserBrand } from '@models/materials/apiRequests/materialsRequests';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import { iconBlue } from '@assets/color';
import { useNavigate } from 'react-router-dom';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AddFatIcon } from '@assets/images/svgComponents';
import { ModalAddBrand } from '@models/materials/components/ModalAddBrand';
import { IBrandDetails } from '@models/materials/utils/materialTypes';
import { SETTINGS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { Card } from '@components/Card';

function Brands() {
  const { t } = useTranslation();
  const { refreshHeader } = useContext(HeaderContext);
  const [brands, setBrands] = useState<IBrandDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showNewBrandModal, setShowNewBrandModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const refetchBrands = async () => {
    getAllUserBrand().then((r) => setBrands(r));
    refreshHeader(false);
    setLoading(false);
  };

  useEffect(() => {
    refetchBrands();
  }, []);

  const columnHelper = createColumnHelper<IBrandDetails>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => 'id',
      }),
      columnHelper.accessor('name', {
        header: () => t('brand.own'),
      }),
      columnHelper.accessor('materials', {
        header: () => '',
        cell: (info) => {
          let label = `${info.getValue().length} ${String(
            t('brand.materials')
          ).toLowerCase()}`;

          if (info.getValue().length <= 1) {
            label = `${info.getValue().length} ${String(
              t('brand.material')
            ).toLowerCase()}`;
          }
          return <Tag color={iconBlue} label={label} />;
        },
      }),
    ],
    [brands]
  );

  return (
    <>
      <Card
        title={t('sidebar.materials')}
        actionButtons={
          <ButtonOpx
            label={t('brand.new')}
            icon={<AddFatIcon />}
            onClick={() => setShowNewBrandModal(true)}
            small
          />
        }
        addClass="w-full"
      >
        <ListTable
          loading={loading}
          columns={columns as IColumn[]}
          data={brands}
          callBackOnRowClick={(e: IRow) => {
            const rowData = e.original as IBrandDetails;
            navigate(SETTINGS_ROUTES_WITH_ID(rowData.id).SETTINGS_BRANDS_VIEW);
          }}
          addClass="w-full !border-transparent"
          hiddenColumns={['id']}
        />
      </Card>
      {showNewBrandModal && (
        <ModalAddBrand
          setShowModal={setShowNewBrandModal}
          refetch={refetchBrands}
        />
      )}
    </>
  );
}

export { Brands };
