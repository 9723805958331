import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { AUTH_ROUTES } from '@utils/routesUrls';

interface IRegisteredSiretProps {
  setRegistered: Dispatch<SetStateAction<boolean>>;
}

function RegisteredSiret({ setRegistered }: IRegisteredSiretProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div data-test-id="already_registered" className="lg:w-[35rem]">
      <p className="mb-2 text-[1.6875rem] font-medium leading-[2.5rem]">
        {t('auth.registration.already_registered.got_account')}
      </p>
      <p className="font-normal text-black text-[.875rem]">
        {t('auth.registration.already_registered.message')}
      </p>
      <ButtonOpx
        addClass="w-full my-6"
        label={t('auth.login')}
        type="primary"
        onClick={() => navigate(AUTH_ROUTES.LOGIN)}
      />

      <p className="text-center">
        {t('auth.registration.already_registered.no_account')}{' '}
        <button
          type="button"
          onClick={() => setRegistered(false)}
          className="text-primaryText"
          data-test-id="no_account_link"
        >
          {t('auth.registration.already_registered.link')}
        </button>
      </p>
    </div>
  );
}

export { RegisteredSiret };
