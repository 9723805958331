import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, TrashIcon } from '@assets/images/svgComponents';
import { blueOpx, green, red } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { formQuote } from '@models/mar/utils/datas';
import { InputText } from '@components/atomic/inputs/InputText';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useFormContext } from 'react-hook-form';
import {
  IDebouncedDataOneQuote,
  IScenario,
  IWorksiteMar,
} from '@models/mar/utils/marTypes';
import {
  getScenarioPrime,
  validateOneQuote,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { Tag } from '@components/atomic/Tag';
import { ILabelValue } from '@globalTypes/globalTypes';
import Gestures from '@models/mar/components/worksiteCompletion/stepsCompletion/quotes/Gestures';
import {
  addDayToStringDate,
  appendFormData,
  calculateTotalHTAndTTC,
} from '@models/mar/utils/functions';
import { TextError } from '@components/TextError';
import { InputTypes } from '@utils/utils';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { convertHexToRGBA, numFormatSpace } from '@utils/functions';

interface IOneQuoteProps {
  indexPro: number;
  indexQuote: number;
  nbQuotes: number;
  onDelete: (index: number) => Promise<void>;
  operations: ILabelValue[];
  otherOperations: ILabelValue[];
  setProOperationsSelected: Dispatch<SetStateAction<ILabelValue[]>>;
  setProOtherOperationsSelected: Dispatch<SetStateAction<ILabelValue[]>>;
}

function OneQuote({
  indexPro,
  indexQuote,
  nbQuotes,
  onDelete,
  operations,
  otherOperations,
  setProOperationsSelected,
  setProOtherOperationsSelected,
}: IOneQuoteProps) {
  const { t } = useTranslation();

  const { worksiteData, updateWorksiteData } = useContext(
    WorksiteCreationMarContext
  );

  const [isLoadingValidation, setIsLoadingValidation] =
    useState<boolean>(false);
  const [isLoadingPrime, setIsLoadingPrime] = useState<boolean>(false);
  const [
    buttonScenarioValidationDisabled,
    setButtonScenarioValidationDisabled,
  ] = useState<boolean>(true);
  const [debouncedData, setDebouncedData] = useState<IDebouncedDataOneQuote>();
  const [dataPrimeCalculation, setDataPrimeCalculation] =
    useState<IDebouncedDataOneQuote>();
  const [resetSignal, setResetSignal] = useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false); // État pour gérer l'ouverture/fermeture
  const [autoCheckPro, setAutoCheckPro] = useState<boolean>(false);

  const { watch, setValue } = useFormContext();

  const selectedScenario = (worksiteData as IWorksiteMar).scenarios?.find(
    (scenario) => scenario.selected
  ) as IScenario;

  const prefixPro = `pros.${indexPro}.`;
  const prefix = `${prefixPro}quotes.${indexQuote}.`;

  const quoteSavedData = worksiteData?.pros?.[indexPro]?.devis?.find(
    (quote) => quote.id === watch(`${prefix}id`)
  );

  const selectedPro = watch(`${prefixPro}selected_pro`);
  const quoteGestures = watch(`${prefix}gestes`);
  const quoteOperations = quoteGestures
    ? (quoteGestures.map((gesture: any) => gesture.operation) as ILabelValue[])
    : [];

  const quoteOtherGestures = watch(`${prefix}gestes_autre`);
  const quoteOtherOperations = quoteOtherGestures
    ? (quoteOtherGestures.map(
        (gesture: any) => gesture.operation
      ) as ILabelValue[])
    : [];

  const totalHTFieldName = `${prefix}total_cost_ht`;
  const totalHT = watch(totalHTFieldName);
  const totalTTCFieldName = `${prefix}total_cost_ttc`;
  const totalTTC = watch(totalTTCFieldName);

  const mprAmount = watch(`${prefix}mpr_amount`);
  const localPrimes = watch(`${prefix}local_primes`);

  const quoteId = watch(`${prefix}id`);
  const quoteSelected = watch(`${prefix}selected_devis`);

  const quoteSelectedNotPro = !selectedPro && quoteSelected;

  const validateQuote = async () => {
    setIsLoadingValidation(true);

    const dataToSend = {
      selected_pro: selectedPro,
      selected_devis: quoteSelected,
      date: watch(`${prefix}date`),
      entity_id: watch(`${prefixPro}entity_id`),
      mar_devis_id: quoteId || undefined,
      mpr_amount: Number(watch(`${prefix}mpr_amount`)),
      local_primes: localPrimes || 0,
      file: watch(`${prefix}file`),
      gestes: [...quoteGestures, ...quoteOtherGestures].map((gesture: any) => ({
        geste_id: Number(gesture.operation.value),
        cost_ht: Number(gesture.cost_ht),
        cost_ttc: Number(gesture.cost_ttc),
        description: gesture.description,
        exemption_message: gesture.exemption_message
          ? gesture.exemption_message
          : undefined,
      })),
    };

    const formData = new FormData();

    appendFormData(dataToSend, formData);

    const response = await validateOneQuote(worksiteData?.id || 0, formData);
    if (response) {
      updateWorksiteData((prev) => {
        const prevWorksiteData = { ...prev } as IWorksiteMar;
        const updatedPros = prevWorksiteData.pros
          ? [...prevWorksiteData.pros]
          : [];
        updatedPros[indexPro] = {
          ...updatedPros[indexPro],
          ...response.data,
        };

        return {
          ...prevWorksiteData,
          pros: updatedPros,
        };
      });
      setValue(`${prefixPro}id`, response.data.id);
      setValue(`${prefix}id`, response.data.devis[indexQuote].id);
      setIsCollapsed(true);
    }
    setIsLoadingValidation(false);
  };

  const readyForPrimeCalculation = selectedScenario && totalHT && totalTTC;

  const readyForValidation =
    !quoteSelectedNotPro &&
    watch(`${prefixPro}entity_id`) &&
    watch(`${prefix}file`) &&
    watch(`${prefix}date`) &&
    localPrimes < totalTTC &&
    quoteGestures &&
    (quoteGestures.length > 0 || quoteOtherGestures.length > 0) &&
    quoteGestures.every(
      (gesture: any) =>
        gesture.operation &&
        gesture.cost_ht &&
        gesture.cost_ttc &&
        gesture.description
    ) &&
    quoteOtherGestures.every(
      (gesture: any) =>
        gesture.operation &&
        gesture.cost_ht &&
        gesture.cost_ttc &&
        gesture.description
    ) &&
    mprAmount;

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Bascule l'état ouvert/fermé
  };

  const calculateProPrime = async () => {
    if (readyForPrimeCalculation) {
      if (debouncedData !== dataPrimeCalculation) {
        const dataToSend = {
          initial_class: (worksiteData as IWorksiteMar).energy_class,
          final_class: selectedScenario.energy_class,
          precarity_type: (worksiteData as IWorksiteMar).precarity_type,
          local_primes: localPrimes ? Number(localPrimes) : 0,
          cost_ht: totalHT,
          cost_ttc: totalTTC,
        };

        setIsLoadingPrime(true);
        const response = await getScenarioPrime(dataToSend);
        if (response !== undefined) {
          setDataPrimeCalculation(debouncedData);
          setValue(`${prefix}mpr_amount`, response.prime);
        }
        setIsLoadingPrime(false);
      }
    } else if (
      mprAmount !== undefined &&
      mprAmount !== quoteSavedData?.mpr_amount
    ) {
      setValue(`${prefix}mpr_amount`, undefined);
    }
  };

  const deleteAllOperations = () => {
    setProOperationsSelected((prev) =>
      prev.filter(
        (operation) =>
          !quoteOperations.some((op) => op.value === operation.value)
      )
    );
    setProOtherOperationsSelected((prev) =>
      prev.filter(
        (operation) =>
          !quoteOtherOperations.some((op) => op.value === operation.value)
      )
    );
  };

  useEffect(() => {
    if (quoteSelectedNotPro && !autoCheckPro) {
      setValue(`${prefixPro}selected_pro`, true);
      setAutoCheckPro(true);
    }
  }, [quoteSelectedNotPro]);

  useEffect(() => {
    calculateTotalHTAndTTC(
      quoteGestures,
      quoteOtherGestures,
      totalHT,
      totalTTC,
      totalHTFieldName,
      totalTTCFieldName,
      setValue,
      setResetSignal
    );
    setButtonScenarioValidationDisabled(!readyForValidation);
  }, [watch()]);

  useEffect(() => {
    if (!localPrimes || Number(localPrimes) < totalTTC) {
      calculateProPrime();
    }
  }, [debouncedData]);

  // Debounce the relevant values
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedData({
        localPrimes: localPrimes ? Number(localPrimes) : 0,
        costHT: totalHT,
        costTTC: totalTTC,
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [totalHT, totalTTC, localPrimes]);

  return (
    <div className="flex flex-col space-y-4 w-full  p-4 border border-borderGrey rounded-default">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggleCollapse}
      >
        <div className="flex space-x-4 items-center min-h-8">
          <p className="font-medium text-[1.125rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.quote'
            )}{' '}
            {indexQuote + 1}
          </p>
          {mprAmount !== undefined && (
            <Tag
              color={green}
              label={`${numFormatSpace(mprAmount)} €`}
              addClass="!ml-4"
            />
          )}
          {nbQuotes > 1 && (
            <ColorCube
              size="2rem"
              numberOrIcon={<TrashIcon />}
              color={red}
              opacity
              onClick={() => {
                deleteAllOperations();
                onDelete(indexQuote);
              }}
            />
          )}
        </div>
        <ColorCube
          numberOrIcon={
            <ChevronLeftIcon
              className={`${
                !isCollapsed ? 'rotate-[90deg]' : 'rotate-[-90deg]'
              } transform duration-200 m-auto`}
            />
          }
          size="1.5rem"
          color={blueOpx}
          opacity
        />
      </div>
      {!isCollapsed && (
        <>
          <OneMarForm
            dataForm={formQuote(
              t,
              indexPro,
              indexQuote,
              quoteSavedData,
              watch(`${prefix}date`),
              // La date minimum du devis doit être postérieure à la date de l'audit
              addDayToStringDate(worksiteData?.audit?.report_date as string)
            )}
          />
          <Gestures
            operations={operations}
            otherOperations={otherOperations}
            setProOperationsSelected={setProOperationsSelected}
            setProOtherOperationsSelected={setProOtherOperationsSelected}
            prefixForm={prefix}
            indexPro={indexPro}
            indexQuote={indexQuote}
          />
          <p className="font-medium text-[1rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.total_costs'
            )}
          </p>
          <div className="w-full grid grid-cols-4 gap-2 text-[.75rem] items-center">
            <InputText
              id={totalHTFieldName}
              type="text"
              name={totalHTFieldName}
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.cost_ht'
              )}`}
              placeholder=""
              resetSignal={resetSignal}
              disabled
              required
            />
            <InputText
              id={totalTTCFieldName}
              type="text"
              name={totalTTCFieldName}
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.cost_ttc'
              )}`}
              placeholder=""
              resetSignal={resetSignal}
              disabled
              required
            />
            <DynamicInputEdit
              isEditMode
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.total_local_aid'
              )}`}
              name={`${prefix}local_primes`}
              inputType={InputTypes.NUMBER}
              placeholder=""
              required
              noUnregister
            />
            <div className="flex flex-col space-y-2">
              <div className="text-textGrey text-[.75rem] leading-[.75rem]">
                {`${t(
                  'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.mpr_bonus'
                )}`}
              </div>
              <div
                className="w-full border rounded-default focus:outline-none p-[.7rem] text-[.875rem]"
                style={{
                  backgroundColor: convertHexToRGBA(green, 0.1),
                  borderColor: convertHexToRGBA(green, 0.1),
                  color: green,
                }}
              >
                {mprAmount !== undefined
                  ? `${numFormatSpace(mprAmount)}`
                  : '\u00A0'}
              </div>
            </div>
            {quoteSelectedNotPro && (
              <div className="col-span-4">
                <TextError
                  errorMessage={t(
                    'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.quote_selected_not_pro'
                  )}
                  addClass="!mt-0 text-[.875rem]"
                />
              </div>
            )}
            {totalTTC !== 0 && localPrimes >= totalTTC && (
              <div className="col-span-4">
                <TextError
                  errorMessage={t(
                    'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.aid_exceed'
                  )}
                  addClass="!mt-0 text-[.875rem]"
                />
              </div>
            )}
            <div className="col-span-4">
              <ButtonOpx
                label={
                  !quoteId
                    ? `${t(
                        'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.validate_quote'
                      )}`
                    : `${t(
                        'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.edit_quote'
                      )}`
                }
                onClick={validateQuote}
                disabled={
                  buttonScenarioValidationDisabled ||
                  isLoadingPrime ||
                  isLoadingValidation
                }
                isLoading={isLoadingValidation}
                type="tab"
                addClass="w-full text-[1rem]"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OneQuote;
