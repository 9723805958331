import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendEmailPasswordForgotten } from '@models/auth/apiRequests/authRequests';
import { InputText } from '@components/atomic/inputs/InputText';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import { getEmailSchema } from '@utils/validationSchemas';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AUTH_ROUTES } from '@utils/routesUrls';

function ForgotPassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm({
    resolver: yupResolver(getEmailSchema(t)),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formData: FieldValues) => {
    setLoading(true);
    await sendEmailPasswordForgotten(formData.email);
    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className="w-full">
        <p className="mb-2 text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('auth.forget_pwd.tooltip')}
        </p>
        <p className="mb-6 text-sm font-normal leading-[1.25rem]">
          {t('auth.forget_pwd.details')}
        </p>
        <FormProvider {...methods}>
          <form id="form-forget-pwd" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <div className="space-y-4">
                <p className="text-base font-medium leading-5">
                  {t('auth.registration.forms.email')}
                </p>
                <InputText
                  id="email"
                  name="email"
                  hideLabel
                  placeholder={`${t('global.example')}@opx.co`}
                  required
                />
              </div>
              <Link
                to={AUTH_ROUTES.LOGIN}
                className="text-primaryText block w-full text-sm text-right"
              >
                {t('auth.back_to_login')}
              </Link>
              <ButtonOpx
                label={t('auth.forget_pwd.submit')}
                formId="form-forget-pwd"
                isSubmitBtn
                disabled={loading}
                dataTestId="forget_pwd_button"
                addClass="w-full"
                isLoading={loading}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </AuthLayout>
  );
}

export { ForgotPassword };
