/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { black, blueOpx, green, textGrey } from '@assets/color';
import { CheckIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { ThemeContext } from '@context/ThemeContext';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { convertHexToRGBA } from '@utils/functions';
import { useContext } from 'react';

interface OneStepProps {
  nameStep: string;
  numberStep: number;
  stepIsActive?: boolean;
  stepIsDone?: boolean;
  onClick?: () => void;
  lastStep?: boolean;
}

function OneStep({
  nameStep,
  numberStep,
  stepIsActive,
  stepIsDone,
  onClick,
  lastStep,
}: OneStepProps) {
  const { themeData } = useContext(ThemeContext);
  const secondaryElement = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );

  const textColor = secondaryElement?.text_color || blueOpx;
  const backgroundColor = secondaryElement?.background_color;

  let isDone = stepIsDone;
  if (lastStep) isDone = true;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      style={{
        backgroundColor: stepIsActive ? convertHexToRGBA(blueOpx, 0.1) : '',
      }}
      className={`flex space-x-[1rem] items-center w-full p-[.5rem] rounded-default ${
        !onClick ? 'cursor-default' : ''
      }`}
    >
      <ColorCube
        numberOrIcon={!isDone ? numberStep : <CheckIcon />}
        size="1.5rem"
        color={!isDone || stepIsActive ? blueOpx : green}
        opacity={!stepIsActive && !backgroundColor}
        backgroundColor={
          !stepIsActive && backgroundColor
            ? convertHexToRGBA(backgroundColor, 0.4)
            : undefined
        }
      />
      <p
        style={{
          color: stepIsActive ? textColor : stepIsDone ? black : textGrey,
        }}
      >
        {nameStep}
      </p>
    </div>
  );
}

export { OneStep };

OneStep.defaultProps = {
  stepIsActive: false,
  stepIsDone: false,
  onClick: undefined,
  lastStep: false,
};
