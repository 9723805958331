import { Radio } from './Radio';
// import { useState, useEffect } from 'react';

interface ITextWithRadioProps {
  label: string;
  value: string;
  setSelectedValue: (val: string) => void;
  addClass?: string;
  addClassButton?: string;
  selectValue?: string;
  withoutName?: boolean;
  isChecked?: boolean;
  disabled?: boolean;
  subLabel?: string;
  labelInBold?: boolean;
  colorRadio?:
    | 'blueOpx'
    | 'red'
    | 'green'
    | 'blue'
    | 'purple'
    | 'textGrey'
    | 'orange';
  textColorClass?: string;
  dataTestId?: string;
}

// EXEMPLE D'UTILISATION DANS LE COMPOSANT PARENT:
//   const [selectedValue, setSelectedValue] = useState<string>('');
//   const onSubmit = () => {
//     functionOnSubmit(selectedValue);
//   };
//
// <form
//   onSubmit={handleSubmit(onSubmit)}
//   action="test/inputs"
//   className="px-5"
// >
//   <TextWithRadio
//     label="Option 1"
//     value="Option 1"
//     setSelectedValue={setSelectedValue}
//     isChecked={selectedValue === 'Option 1'}
//     selectValue={selectedValue}
//   />
//   <TextWithRadio
//     label="Option 2"
//     value="Option 2"
//     disabled
//     setSelectedValue={setSelectedValue}
//     isChecked={selectedValue === 'Option 2'}
//     selectValue={selectedValue}
//   />
//   <TextWithRadio
//     label="Option 3"
//     value="Option 3"
//     setSelectedValue={setSelectedValue}
//     isChecked={selectedValue === 'Option 3'}
//     selectValue={selectedValue}
//   />
// </form>

function TextWithRadio({
  label,
  addClass,
  addClassButton,
  value,
  setSelectedValue,
  withoutName,
  isChecked,
  disabled,
  selectValue,
  colorRadio,
  subLabel,
  labelInBold,
  textColorClass,
  dataTestId,
}: ITextWithRadioProps): JSX.Element {
  const handleSelection = (val: any) => {
    setSelectedValue(val);
  };

  return (
    <div
      className={[
        'border border-borderGrey rounded-default text-[.875rem]',
        addClass,
        disabled ? 'bg-backgroundBody' : '',
      ].join(' ')}
    >
      <button
        data-test-id={dataTestId}
        type="button"
        disabled={disabled}
        onClick={() => {
          setSelectedValue(disabled && selectValue ? selectValue : value);
        }}
        className={['w-full px-[.5rem] py-[.75rem]', addClassButton].join(' ')}
      >
        <div
          className={`flex space-x-[.625rem] ${
            subLabel ? 'items-start' : 'items-center'
          }`}
        >
          <Radio
            name={withoutName ? undefined : value}
            value={value}
            onSelect={(val: string) =>
              disabled ? handleSelection(selectValue) : setSelectedValue(val)
            }
            isChecked={isChecked}
            disabled={disabled || false}
            colorRadio={colorRadio}
            dataTestId={`radio_${dataTestId}`}
          />
          <div className="flex flex-col">
            <p
              className={`${
                disabled ? 'text-textGrey' : `${textColorClass} text-start`
              } ${labelInBold ? 'font-medium' : ''}`}
            >
              {label}
            </p>
            {subLabel && (
              <p
                className={[
                  disabled ? 'text-textGrey' : textColorClass,
                  'text-start mt-[.5rem]',
                ].join(' ')}
              >
                {subLabel}
              </p>
            )}
          </div>
        </div>
      </button>
    </div>
  );
}

export { TextWithRadio };

TextWithRadio.defaultProps = {
  addClass: '',
  addClassButton: '',
  withoutName: false,
  isChecked: false,
  selectValue: undefined,
  disabled: false,
  colorRadio: 'blueOpx',
  subLabel: '',
  labelInBold: false,
  textColorClass: 'text-black',
  dataTestId: undefined,
};
