// eslint-disable-next-line no-shadow
import { TFunction } from 'i18next';
import { ITabData } from './settingsTypes';

export const CustomFieldTypes = {
  TEXT: 1,
  NUMBER: 2,
  BOOL: 3,
  DATE: 4,
  FILE: 9999,
} as const;

export const renderCustomFieldTypeLabel = (type: number, t: TFunction) => {
  let label = '';
  switch (type) {
    case CustomFieldTypes.NUMBER:
      label = t('settings.type.number');
      break;
    case CustomFieldTypes.DATE:
      label = t('settings.type.date');
      break;
    case CustomFieldTypes.BOOL:
      label = t('settings.type.boolean');
      break;
    case CustomFieldTypes.FILE:
      label = t('settings.type.file');
      break;
    case CustomFieldTypes.TEXT:
    default:
      label = t('settings.type.text');
      break;
  }
  return label;
};

export const getCustomFieldTypeValue = (
  label: string,
  t: TFunction
): number => {
  const typeLabels: Record<string, number> = {
    [t('settings.type.number')]: CustomFieldTypes.NUMBER,
    [t('settings.type.date')]: CustomFieldTypes.DATE,
    [t('settings.type.boolean')]: CustomFieldTypes.BOOL,
    [t('settings.type.text')]: CustomFieldTypes.TEXT,
    [t('settings.type.file')]: CustomFieldTypes.FILE,
  };

  return typeLabels[label] || CustomFieldTypes.TEXT; // Retourne TEXT par défaut si le label n'est pas trouvé
};

export const SETTINGS_TABS = {
  SUBSCRIPTION_AND_PAYMENT: 'subscription_and_payment',
  CUSTOM_FIELDS: 'custom_fields',
  BRANDS: 'brands',
  WHITE_LABEL: 'white_label',
};

export const SETTINGS_TABS_DATA: ITabData[] = [
  {
    key: SETTINGS_TABS.CUSTOM_FIELDS,
    label: 'settings.custom_fields',
  },
  {
    key: SETTINGS_TABS.BRANDS,
    label: 'settings.brands',
  },
  {
    key: SETTINGS_TABS.SUBSCRIPTION_AND_PAYMENT,
    label: 'settings.subscriptions_and_payments',
  },
  {
    key: SETTINGS_TABS.WHITE_LABEL,
    label: 'settings.white_label.title',
  },
];
