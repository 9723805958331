import { Area } from 'react-easy-crop/types';
/**
 * Génère une image recadrée à partir des pixels de recadrage.
 * @param {string} imageSrc - La source de l'image à recadrer.
 * @param {Area} pixelCrop - Les dimensions du recadrage en pixels.
 * @returns {Promise<string>} L'URL de l'image recadrée.
 */
export const getCroppedImg = (
  imageSrc: string,
  pixelCrop: Area
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
        resolve(canvas.toDataURL('image/png'));
      } else {
        reject(new Error("Impossible d'obtenir le contexte du canvas"));
      }
    };
    image.onerror = () => {
      reject(new Error("Erreur de chargement de l'image"));
    };
  });
};

/**
 * Filtre de netteté personnalisé pour Konva
 * @param imageData Les données de l'image à filtrer
 */
export function SharpenFilter(imageData: ImageData) {
  const { data, width, height } = imageData;
  // Récupérer la valeur de netteté depuis les attributs de l'image
  const sharpness = (imageData as unknown as { sharpen: number }).sharpen || 1;

  // Adapter les poids en fonction de la netteté
  const centerWeight = 5 * sharpness;
  const weights = [0, -1, 0, -1, centerWeight, -1, 0, -1, 0];
  const kernelSize = 3;
  const half = 1;

  const copy = new Uint8ClampedArray(data);

  for (let y = 0; y < height; y += 1) {
    for (let x = 0; x < width; x += 1) {
      let r = 0;
      let g = 0;
      let b = 0;

      for (let ky = 0; ky < kernelSize; ky += 1) {
        for (let kx = 0; kx < kernelSize; kx += 1) {
          const posX = x + kx - half;
          const posY = y + ky - half;

          if (posX >= 0 && posX < width && posY >= 0 && posY < height) {
            const idx = (posY * width + posX) * 4;
            const weight = weights[ky * kernelSize + kx];
            r += copy[idx] * weight;
            g += copy[idx + 1] * weight;
            b += copy[idx + 2] * weight;
          }
        }
      }

      const idx = (y * width + x) * 4;
      data[idx] = Math.min(Math.max(r, 0), 255);
      data[idx + 1] = Math.min(Math.max(g, 0), 255);
      data[idx + 2] = Math.min(Math.max(b, 0), 255);
      // L'alpha reste inchangé
    }
  }
}

export /**
 * Fonction pour convertir une Data URL en Blob
 * @param dataurl La Data URL à convertir
 * @returns Un objet Blob
 */
const dataURLtoBlob = (dataurl: string): Blob => {
  const arr = dataurl.split(',');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

/**
 * Fonction pour récupérer une image depuis une URL et la convertir en Blob
 * @param url L'URL de l'image à récupérer
 * @returns Une promesse résolvant en un objet Blob
 */
export function fetchImageAsBlob(url: string): Promise<Blob> {
  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `Erreur lors du téléchargement de l'image: ${response.statusText}`
        );
      }
      return response.blob();
    })
    .catch((error) => {
      console.error('Erreur fetchImageAsBlob:', error);
      throw error;
    });
}
