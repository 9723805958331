import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { GlobalContext } from '@context/globalContext';
import { WORK_SITE_DETAILS_TABS } from '@models/worksites/utils/tab';
import { Tag } from '@components/atomic/Tag';
import { textGrey } from '@assets/color';
import { useNavigate } from 'react-router-dom';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import TagIncentiveType from '@models/worksites/components/TagIncentiveType';
import { ADMIN_ROUTES, WORKSITES_ROUTES } from '@utils/routesUrls';
import { DIMENSIONS } from '@utils/utils';
import { SubHeader } from '@components/subHeader/SubHeader';
import { RequestsAndChangesCard } from '@components/requestsAndChanges/card/RequestsAndChangesCard';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ContactPageIcon } from '@assets/images/svgComponents';
import { ROLES } from '@utils/roles';
import { OperationTypeEnum } from '@utils/enums';
import WorksiteDetailsInfos from './worksiteDetails/WorksiteDetailsInfos';
import { WorksiteDetailsOperations } from './worksiteDetails/WorksiteDetailsOperations';
import { WorksiteDetailsDocuments } from './worksiteDetails/WorksiteDetailsDocuments';
import { getWorksiteDetails } from '../apiRequests/worksitesRequests';

interface IWorsiteDetailsProps {
  adminMode?: boolean;
}

function WorksiteDetails({ adminMode }: IWorsiteDetailsProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    updateTitleHeader,
    updateDisplayBackButtonHeader,
    updateAdditionnalInfosHeader,
    updateTagHeader,
    updateRouteFrom,
  } = useContext(HeaderContext);
  const { roleUser, updateRoute, route, updateAddDocumentModalIsActive } =
    useContext(GlobalContext);
  const { updateNumberPageRequestsList } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );
  const {
    worksiteId,
    updateWorksiteDetails,
    worksiteDetails,
    forceOpenDocumentsTab,
    updateForceOpenDocumentsTab,
  } = useContext(WorksitesContext);
  const { refreshRequestsAndChanges } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabActive, setTabActive] = useState<string>(
    WORK_SITE_DETAILS_TABS(t).OP
  );

  const routeFrom = useMemo(
    (): string =>
      adminMode ? ADMIN_ROUTES.ADMIN_WORKSITES : WORKSITES_ROUTES.WORKSITES,
    [adminMode]
  );

  const getWorksiteData = async () => {
    setIsLoading(true);
    const res = await getWorksiteDetails(
      updateWorksiteDetails,
      worksiteId,
      setIsLoading,
      updateTitleHeader
    );

    // display the list of worksites
    if (!res) {
      navigate(routeFrom);
    }

    if (res === 'duplicate') {
      return;
    }
    setIsLoading(false);
    updateDisplayBackButtonHeader(true);
  };

  const handleRefresh = () => {
    setRefreshFlag((prev) => !prev); // Toggle the flag
  };

  const renderElement = useMemo(() => {
    switch (tabActive) {
      case WORK_SITE_DETAILS_TABS(t).OP:
        return (
          <WorksiteDetailsOperations
            isLoading={isLoading}
            refresh={getWorksiteData}
          />
        );
      case WORK_SITE_DETAILS_TABS(t).DOCS:
        return (
          <WorksiteDetailsDocuments
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onValidateProcessing={handleRefresh}
          />
        );
      case WORK_SITE_DETAILS_TABS(t).DETAILS:
        return (
          <WorksiteDetailsInfos
            isLoading={isLoading}
            refresh={getWorksiteData}
          />
        );
      default:
        return <div />;
    }
  }, [tabActive, isLoading]);

  useEffect(() => {
    updateRouteFrom(routeFrom);
    return () => updateRouteFrom('');
  }, []);

  useEffect(() => {
    if (worksiteId !== '') {
      getWorksiteData();
      updateNumberPageRequestsList(1);
      refreshRequestsAndChanges();
    }
    updateRoute({
      to: route.to,
      from: routeFrom,
    });
  }, [worksiteId]);

  useEffect(() => {
    if (worksiteDetails.address)
      updateAdditionnalInfosHeader([
        `${worksiteDetails.address.address} ${worksiteDetails.address.postal_code} ${worksiteDetails.address.city}`,
      ]);

    updateTagHeader(
      <div className="flex space-x-4">
        <Tag
          label={`#${worksiteDetails.reference}`}
          color={textGrey}
          dataTestId="worksite_ref"
        />
        <TagIncentiveType incentiveType={worksiteDetails.incentive_type} />
      </div>
    );
  }, [worksiteDetails]);

  useEffect(() => {
    if (forceOpenDocumentsTab) {
      setTabActive(WORK_SITE_DETAILS_TABS(t).DOCS);
      updateForceOpenDocumentsTab(false);
    }
  }, [forceOpenDocumentsTab]);

  const leftPartSubHeader = useMemo(() => {
    const tab = Object.values(WORK_SITE_DETAILS_TABS(t));
    tab.splice(0, 1);
    tab.reverse();
    return (
      <div className="flex justify-between items-center">
        <TabsLeftPart
          titlesList={Object.values(WORK_SITE_DETAILS_TABS(t))}
          onClick={(title: string) => setTabActive(title)}
          activeButton={tabActive}
        />
      </div>
    );
  }, [WORK_SITE_DETAILS_TABS, worksiteDetails.uuid, tabActive]);

  const rightPartSubHeader: JSX.Element = (
    <div className="flex gap-3 items-center">
      {roleUser === ROLES.GESTION &&
        tabActive === WORK_SITE_DETAILS_TABS(t).DOCS && (
          <ButtonOpx
            label={t('global.document_import')}
            type="secondary"
            onClick={() => updateAddDocumentModalIsActive(true)}
          />
        )}
      {worksiteDetails.status > 1 && worksiteDetails.uuid && (
        <ButtonOpx
          icon={<ContactPageIcon />}
          iconSize="1rem"
          label={t('buttons.beneficiary_view')}
          onClick={() => {
            const baseUrl = window.location.origin;
            const operationType =
              worksiteDetails.operation_type === OperationTypeEnum.B2C
                ? 'b2c'
                : 'b2b';
            const link = `${baseUrl}/beneficiary/worksite/${worksiteDetails.uuid}/${operationType}`;
            window.open(link, '_blank');
          }}
          type="secondary"
        />
      )}
    </div>
  );

  return (
    <div className="flex">
      <div
        className="w-full"
        style={{
          paddingRight: DIMENSIONS.requests_and_changes_width,
        }}
        data-test-id="worksites"
      >
        <SubHeader
          leftPart={leftPartSubHeader}
          rightPart={rightPartSubHeader}
        />
        <div className="w-full">{renderElement}</div>
      </div>

      <RequestsAndChangesCard refreshFlag={refreshFlag} />
    </div>
  );
}

export { WorksiteDetails };

WorksiteDetails.defaultProps = {
  adminMode: false,
};
