import { useContext, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';
import { ThemeContext } from '@context/ThemeContext';
import {
  LogoMinimalistOpxIcon,
  LogoOpxIcon,
  ChevronLeftIcon,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { black, textGrey, white } from '@assets/color';
import { Link } from 'react-router-dom';
import { DIMENSIONS } from '@utils/utils';
import { DASHBOARD_ROUTES } from '@utils/routesUrls';
import { convertHexToRGBA } from '@utils/functions';
import {
  THEME_ELEMENTS_TYPES,
  THEME_IMAGE_TYPES,
} from '@models/settings/utils/enums';
import { ModalChangeTheme } from './ModalChangeTheme';

/**
 * Composant HeaderSidebar
 * Affiche le header de la sidebar avec la gestion des thèmes.
 */
function HeaderSidebar() {
  const { t } = useTranslation();
  const { sidebarMenuIsOpen, updateSidebarMenuIsOpen } =
    useContext(GlobalContext);
  const { themeData, themesList } = useContext(ThemeContext);

  const [modalThemeIsOpen, setModalThemeIsOpen] = useState(false);

  /**
   * Récupère les données des éléments de menu du thème actuel.
   */
  const menuThemeElementsData = useMemo(() => {
    return themeData?.elements?.find(
      (element) => element.element === THEME_ELEMENTS_TYPES.MENU
    );
  }, [themeData]);

  /**
   * Récupère les données du logo de menu du thème actuel.
   */
  const menuThemeLogoData = useMemo(() => {
    return themeData?.images?.find(
      (image) => image.image === THEME_IMAGE_TYPES.LOGO_MENU
    );
  }, [themeData]);

  const loginThemeLogoData = useMemo(() => {
    return themeData?.images?.find(
      (image) => image.image === THEME_IMAGE_TYPES.LOGO_AUTH
    );
  }, [themeData]);

  /**
   * Obtient les couleurs pour l'icône en fonction des données du thème.
   */
  const getColorsIcon = useCallback(() => {
    if (menuThemeElementsData) {
      return {
        color: menuThemeElementsData.text_color,
        backgroundColor: convertHexToRGBA(
          menuThemeElementsData.text_color === 'white' ? black : white,
          0.4
        ),
      };
    }
    return {
      color: textGrey,
      backgroundColor: convertHexToRGBA(textGrey, 0.1),
    };
  }, [menuThemeElementsData]);

  const { color, backgroundColor } = getColorsIcon();

  /**
   * Détermine quelle image utiliser pour le logo.
   */
  const imageToUse = useCallback(() => {
    if (!menuThemeLogoData) {
      if (themesList.length > 1) {
        return (
          <p
            className="px-4 py-2 rounded-default"
            style={{
              backgroundColor: convertHexToRGBA(white, 0.1),
              color: white,
            }}
          >
            {t('settings.white_label.consolidated_view_short')}
          </p>
        );
      }
      return <LogoOpxIcon width="6.625rem" height="2rem" />;
    }
    return (
      <img
        src={menuThemeLogoData.custom}
        alt="logo"
        className="w-[6.625rem] h-[2rem]"
      />
    );
  }, [menuThemeLogoData, themesList]);

  const minimalistLogo = useMemo(() => {
    if (!loginThemeLogoData) {
      return <LogoMinimalistOpxIcon width="2rem" height="2rem" />;
    }
    return (
      <img
        src={loginThemeLogoData.custom}
        alt="logo"
        className="w-[2rem] h-[2rem]"
      />
    );
  }, [loginThemeLogoData]);

  /**
   * Gère l'ouverture et la fermeture de la modal de changement de thème.
   */
  const toggleModalTheme = useCallback((isOpen: boolean) => {
    setModalThemeIsOpen(isOpen);
  }, []);

  const linkOrMenu = () => {
    if (themesList.length > 1) {
      return (
        <div className="relative">
          <button
            type="button"
            onClick={() => setModalThemeIsOpen(!modalThemeIsOpen)}
          >
            {imageToUse()}
          </button>
          <ModalChangeTheme
            isOpen={modalThemeIsOpen}
            toggleModal={toggleModalTheme}
          />
        </div>
      );
    }
    return <Link to={DASHBOARD_ROUTES.DASHBOARD}>{imageToUse()}</Link>;
  };

  return (
    <div className="border-b border-b-[#EEEEEE10] m-auto">
      {sidebarMenuIsOpen ? (
        <div
          className="flex justify-between items-center px-[1.5rem]"
          style={{ height: DIMENSIONS.header_height }}
        >
          {linkOrMenu()}
          <ColorCube
            numberOrIcon={<ChevronLeftIcon />}
            size="1.5rem"
            color={color}
            backgroundColor={backgroundColor}
            onClick={() => updateSidebarMenuIsOpen(!sidebarMenuIsOpen)}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center py-[1.5rem]">
          <Link to={DASHBOARD_ROUTES.DASHBOARD}>{minimalistLogo}</Link>
          <ColorCube
            numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
            size="1.5rem"
            color={color}
            backgroundColor={backgroundColor}
            onClick={() => updateSidebarMenuIsOpen(!sidebarMenuIsOpen)}
            addClass="mt-[1.5rem]"
          />
        </div>
      )}
    </div>
  );
}

export { HeaderSidebar };
