import { ButtonOpx } from '@components/atomic/ButtonOpx';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { handleUploadClick } from '@utils/functions';
import { toast } from 'react-toastify';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { red } from '@assets/color';
import { TrashIcon } from '@assets/images/svgComponents';
import { fileTypeEnum } from '@utils/enums';
import { ILinkedFile } from '../../types/globalTypes';

interface IUploadButtonProps {
  name: string;
  onUpload: (file: File, originalLinkedFile: ILinkedFile) => void;
  fileType: number;
  label?: string;
  placeholder?: string;
  withDisplayFileUpload?: boolean;
  onDelete?: () => void;
  document?: ILinkedFile;
  color?: 'blue' | 'grey';
  icon?: JSX.Element;
  addClass?: string;
  disabled?: boolean;
  defaultFileUploaded?: File;
  dataTestId?: string;
}

function UploadButton({
  name,
  color,
  onUpload,
  fileType,
  label,
  placeholder,
  withDisplayFileUpload,
  onDelete,
  document,
  icon,
  addClass,
  disabled,
  defaultFileUploaded,
  dataTestId,
}: IUploadButtonProps) {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<File | undefined>(
    defaultFileUploaded
  );

  const defaultFile: ILinkedFile = {
    id: null,
    relation_ids: [1],
    relation_type: 'worksite_operation',
    file_type: 4,
    status: 1,
    isolate_file: false,
    file_url: null,
    file_name: null,
    linked_entity_id: null,
    commentary: null,
    file: null,
    created_at: null,
    uploaded_at: null,
    mandatory: true,
    is_deletable: null,
    file_hash: null,
    custom_field_id: null,
  };

  const acceptFileType = useMemo(() => {
    if (fileType === fileTypeEnum.VIDEO_CONTROLE) {
      return '.mp4,.avi,.mov,.mkv';
    }
    if (
      fileType === fileTypeEnum.PHOTOSAV ||
      fileType === fileTypeEnum.PHOTO_AVANT_CHANTIER ||
      fileType === fileTypeEnum.PHOTO_CONTROLE
    ) {
      return '.jpg,.jpeg,.png';
    }
    return '.pdf';
  }, [fileType]);

  const resetFileInput = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
  };

  const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      if (files[0].size > MAX_FILE_SIZE) {
        toast.error(
          t('global.file_too_large', { maxFileSize: MAX_FILE_SIZE_MB })
        );
        resetFileInput();
        return;
      }
      setIsLoading(true);
      await onUpload(files[0], document || defaultFile);
      setFileUploaded(files[0]);
      setIsLoading(false);
      resetFileInput(); // Réinitialiser après le téléchargement
    }
  };

  useEffect(() => {
    if (defaultFileUploaded && !fileUploaded) {
      setFileUploaded(defaultFileUploaded);
    }
  }, [defaultFileUploaded]);

  return (
    <div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onUploadFile}
        style={{ display: 'none' }}
        accept={acceptFileType}
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-test-id={dataTestId}
      />
      <div className="flex flex-col space-y-2">
        {label && (
          <div className="text-textGrey text-[.75rem] leading-[.75rem]">
            {label}
          </div>
        )}
        <div className={withDisplayFileUpload ? 'w-full flex space-x-2' : ''}>
          {withDisplayFileUpload && (
            <div className="w-full flex items-center px-2 border border-borderGrey rounded-default">
              {fileUploaded ? (
                <a
                  href={URL.createObjectURL(fileUploaded)}
                  className="w-full h-full flex items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-sm text-primaryText">
                    {fileUploaded.name}
                  </span>
                </a>
              ) : (
                <button
                  type="button"
                  className="w-full h-full flex items-center text-sm text-textGrey"
                  onClick={() =>
                    !disabled && handleUploadClick(hiddenFileInput)
                  }
                >
                  {placeholder || t('global.choose')}
                </button>
              )}
            </div>
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {icon ? (
            <button
              type="button"
              onClick={() => !disabled && handleUploadClick(hiddenFileInput)}
              className="w-full"
            >
              {icon}
            </button>
          ) : !withDisplayFileUpload ||
            (withDisplayFileUpload && !fileUploaded) ? (
            <ButtonOpx
              label={name}
              onClick={(e) => {
                e.stopPropagation();
                if (!disabled) handleUploadClick(hiddenFileInput);
              }}
              type={color === 'grey' ? 'secondary' : 'primary'}
              small
              addClass={addClass}
              isLoading={isLoading}
              disabled={disabled}
            />
          ) : (
            <ColorCube
              color={red}
              numberOrIcon={<TrashIcon />}
              size="2rem"
              opacity
              onClick={() => {
                setFileUploaded(undefined);
                resetFileInput(); // Réinitialiser lors de la suppression
                if (onDelete) onDelete();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { UploadButton };

UploadButton.defaultProps = {
  withDisplayFileUpload: false,
  placeholder: undefined,
  label: undefined,
  onDelete: undefined,
  document: undefined,
  color: 'blue',
  icon: undefined,
  addClass: '',
  disabled: false,
  defaultFileUploaded: undefined,
  dataTestId: '',
};
