import { useTranslation } from 'react-i18next';
import { ReactElement, useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { ButtonOpx, IButtonOpxProps } from '@components/atomic/ButtonOpx';
import { DefaultTFuncReturn } from 'i18next';

interface ILayoutMarDetailsStepProps {
  children: JSX.Element;
  onValidate?: () => void;
  onNext?: () => void;
  canIgnoreValidation?: boolean;
  buttonNextLoading?: boolean;
  buttonAdditionnal?: ReactElement<IButtonOpxProps>;
  customTitle?: string | DefaultTFuncReturn;
}

function LayoutMarDetailsStep({
  children,
  onValidate,
  onNext,
  canIgnoreValidation,
  buttonNextLoading,
  buttonAdditionnal,
  customTitle,
}: ILayoutMarDetailsStepProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const { stepCompletion, buttonNextDisabled } = useContext(
    WorksiteCreationMarContext
  );

  return (
    <div className="w-full bg-white rounded-default2 pb-[1rem] border border-borderGrey">
      <div className="p-[1.5rem] flex justify-between w-full items-center border-b border-b-borderGrey">
        <p className="text-[1.5rem] font-medium">
          {customTitle || globalEnum.mar_worksite_steps[stepCompletion] || ''}
        </p>
        <div className="flex space-x-4 items-center">
          {buttonAdditionnal || null}
          <ButtonOpx
            label={t('buttons.validate')}
            disabled={buttonNextDisabled}
            onClick={onValidate}
            isLoading={buttonNextLoading}
            small
          />
          {canIgnoreValidation && (
            <ButtonOpx label={t('buttons.next')} onClick={onNext} small />
          )}
        </div>
      </div>
      <div className="min-h-[70vh] py-6 px-4 flex flex-col space-y-6">
        {children}
      </div>
    </div>
  );
}

export default LayoutMarDetailsStep;

LayoutMarDetailsStep.defaultProps = {
  onValidate: undefined,
  onNext: undefined,
  canIgnoreValidation: false,
  buttonNextLoading: false,
  buttonAdditionnal: undefined,
  customTitle: undefined,
};
