import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  add,
  endOfMonth,
  format,
  getDate,
  getDaysInMonth,
  isDate,
  isEqual,
  parse,
  setDate,
  setMonth,
  startOfMonth,
  sub,
} from 'date-fns';
import { chunk } from 'lodash';

import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { blueOpx, white, textGrey, black } from '@assets/color';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { convertHexToRGBA } from '@utils/functions';
import { ThemeContext } from '@context/ThemeContext';

interface ICalendarProps {
  date: string;
  handleSelectDate: (date: string) => void;
  closeCalendar: () => void;
  minDate?: string | Date;
  maxDate?: string | Date;
  addClass?: string;
}

function Calendar({
  date,
  handleSelectDate,
  closeCalendar,
  minDate,
  maxDate,
  addClass,
}: ICalendarProps) {
  const { themeData } = useContext(ThemeContext);
  const secondaryElement = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );

  const tableRef = useRef<any>(null);
  const selectedDate = new Date(date);
  const [activeDate, setActiveDate] = useState(new Date(date));
  const { t, i18n } = useTranslation();

  const parseDateString = (dateString: Date | string | undefined) => {
    if (!dateString) {
      return null;
    }

    // Si dateString est déjà une instance de Date, retournez-la directement
    if (isDate(dateString)) {
      return dateString;
    }

    // Sinon, supposez que c'est une chaîne et parsez-la
    return parse(dateString as string, 'dd/MM/yyyy', new Date());
  };

  const minDateObj = parseDateString(minDate);
  const maxDateObj = parseDateString(maxDate);

  useEffect(() => {
    if (tableRef.current !== null) {
      tableRef.current.focus();
    }
  }, []);

  const weekArray = [
    { name: 'Monday', value: t('calendar.shorts.monday') },
    { name: 'Tuesday', value: t('calendar.shorts.tuesday') },
    { name: 'Wednesday', value: t('calendar.shorts.wednesday') },
    { name: 'Thursday', value: t('calendar.shorts.thursday') },
    { name: 'Friday', value: t('calendar.shorts.friday') },
    { name: 'Saturday', value: t('calendar.shorts.saturday') },
    { name: 'Sunday', value: t('calendar.shorts.sunday') },
  ];

  const goToNext = (duration: string) => {
    const nextDate = add(activeDate, { [duration]: 1 });
    setActiveDate(nextDate);
  };

  const goToPrev = (duration: string) => {
    const prevDate = sub(activeDate, { [duration]: 1 });
    setActiveDate(prevDate);
  };

  const getLocalDay = (dateProp: Date) => {
    let day = dateProp.getDay();
    if (day === 0) {
      // day is 0 when it's Sunday
      day = 7;
    }
    return day - 1; // Adjust day number to array index (0-6)
  };

  const generateMonth = () => {
    const daysInMonth = getDaysInMonth(activeDate);
    const startWeekday = getLocalDay(startOfMonth(activeDate));
    const endWeekday = getLocalDay(endOfMonth(activeDate));
    const prevMonthDays = getDaysInMonth(sub(activeDate, { months: 1 }));
    const nextMonth = add(activeDate, { months: 1 });

    return chunk(
      [
        ...Array.from({ length: startWeekday }, (_, i) =>
          setDate(
            sub(activeDate, { months: 1 }),
            prevMonthDays - startWeekday + i + 1
          )
        ),
        ...Array.from({ length: daysInMonth }, (_, i) =>
          setDate(activeDate, i + 1)
        ),
        ...Array.from({ length: 6 - endWeekday }, (_, i) =>
          setDate(setMonth(nextMonth, nextMonth.getMonth()), i + 1)
        ),
      ],
      7
    ).map((week) => {
      return week.map((day) => {
        const isDisabled =
          (minDateObj && day < minDateObj) || (maxDateObj && day > maxDateObj);
        return { day, isDisabled };
      });
    });
  };

  const handleTableKeyPress = (e: React.KeyboardEvent) => {
    const keyCode = e.key;
    // Check if control key was pressed
    // const control = e.ctrlKey;
    switch (keyCode) {
      case 'Escape':
        closeCalendar();
        return;
      case 'ArrowLeft':
        goToPrev('months');
        return;
      case 'ArrowRight':
        goToNext('months');
        break;
      default:
    }
  };

  const handleDateSelection = (d: number | Date) => {
    const dateString = format(d, 'dd/MM/yyyy');
    handleSelectDate(dateString);
  };

  return (
    <div
      className={`w-full text-[.625rem] !font-normal flex flex-col pt-[.25rem] pb-4 overflow-hidden absolute border border-borderGrey bg-white right-0 rounded-b-default ${addClass}`}
      style={{
        zIndex: 99999999,
        top: '3.8rem',
      }}
    >
      <div className="flex flex-row justify-between items-center px-[1rem]">
        <div className=" py-[.625rem] flex flex-row justify-between items-center">
          <ColorCube
            numberOrIcon={<ChevronLeftIcon />}
            size="1.5rem"
            color={blueOpx}
            opacity
            onClick={() => goToPrev('months')}
          />
        </div>
        <div className="px-[.875rem] text-[.875rem]">
          <p>
            {new Intl.DateTimeFormat(i18n.language, {
              month: 'long',
              year: 'numeric',
            })
              .format(activeDate)
              .charAt(0)
              .toUpperCase() +
              new Intl.DateTimeFormat(i18n.language, {
                month: 'long',
                year: 'numeric',
              })
                .format(activeDate)
                .slice(1)}
          </p>
        </div>
        <div>
          <ColorCube
            numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
            size="1.5rem"
            color={blueOpx}
            opacity
            onClick={() => goToNext('months')}
          />
        </div>
      </div>
      <table
        ref={tableRef}
        onKeyUp={(e) => handleTableKeyPress(e)}
        style={{ outline: 'none' }}
        id="grid"
        tabIndex={0}
        role="grid"
        aria-label="Month"
        className="mx-4"
      >
        <thead>
          <tr role="row">
            {weekArray.map((day) => (
              <th
                key={v4()}
                className="pb-[.5rem] !font-normal text-[.875rem]"
                role="columnheader"
                aria-label={day.name}
              >
                <span title={day.name}>{day.value}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {generateMonth().map((week) => {
            return (
              <tr className="week" key={v4()} role="row">
                {week.map(({ day, isDisabled }) => {
                  let dayColor;
                  let dayBackgroundColor;

                  if (isDisabled) {
                    // Si la date est désactivée, utiliser la couleur grise
                    dayColor = 'grey';
                  }
                  // Sinon, appliquer la logique de coloration habituelle
                  else if (isEqual(selectedDate, day)) {
                    dayColor = secondaryElement?.text_color || white;
                    dayBackgroundColor = secondaryElement?.background_color
                      ? convertHexToRGBA(secondaryElement.background_color, 0.4)
                      : blueOpx;
                  } else if (day.getMonth() !== activeDate.getMonth()) {
                    dayColor = textGrey;
                    dayBackgroundColor = 'transparent';
                  } else {
                    dayColor = secondaryElement?.background_color
                      ? black
                      : blueOpx;
                    dayBackgroundColor = white;
                  }

                  return (
                    <td
                      className={`text-center ${
                        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                      }`}
                      key={v4()}
                      onClick={
                        !isDisabled ? () => handleDateSelection(day) : undefined
                      }
                      role="gridcell"
                      aria-selected={isEqual(selectedDate, day)}
                    >
                      <p
                        className="rounded-default py-[.5rem] px-[.3rem] flex justify-center items-cente text-base hover:!bg-blueOpxOpacity10"
                        style={{
                          color: dayColor,
                          backgroundColor: dayBackgroundColor,
                        }}
                      >
                        {getDate(day)}
                      </p>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export { Calendar };

Calendar.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  addClass: '',
};
