import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';

import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { Card } from '@components/Card';

import { AUTH_ROUTES } from '@utils/routesUrls';
import { getPaymentConditions } from '../apiRequests/conventionRequests';
import { IPaymentCondition } from '../utils/conventionTypes';

interface ITabPaymentModalityProps {
  conventionId: number;
}

function TabPaymentModality(props: ITabPaymentModalityProps) {
  const { conventionId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const { globalEnum } = useContext(GlobalContext);

  const [data, setData] = useState<IPaymentCondition[]>();

  const renderPaymentCondition = (
    item: IPaymentCondition,
    idx: number,
    numberItems: number
  ) => (
    <div
      className={
        idx === 0 && idx !== numberItems - 1
          ? 'border border-solid border-transparent border-b-borderGrey'
          : ''
      }
    >
      <div
        className={`flex ${
          idx === 0 ? 'pb-[1rem]' : 'pt-[1rem]'
        } justify-between w-full md:w-[80%]`}
      >
        <div>
          <InfosWithIcon
            icon={idx + 1}
            infos={[
              {
                title: t('convention.tab_payment_modality.payment_percent'),
                subtitle: `${item.trigger_percent} %`,
              },
            ]}
          />
        </div>
        <div>
          <InfosWithIcon
            infos={[
              {
                title: t('convention.tab_payment_modality.delay'),
                subtitle: `${item.delay_days}`,
              },
            ]}
          />
        </div>
        <div>
          <InfosWithIcon
            infos={[
              {
                title: t('convention.tab_payment_modality.trigger'),
                subtitle: `${
                  (globalEnum as any).trigger_type[item.trigger_type]
                }`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );

  const getData = async () => {
    const response = await getPaymentConditions(conventionId);
    setData(response);
  };

  useEffect(() => {
    if (token && Number(conventionId) > 0) {
      getData();
    } else {
      navigate(AUTH_ROUTES.LOGIN);
    }
  }, [conventionId]);

  return (
    <Card title={`${t('convention.tab_payment_modality.payment_condition')}`}>
      <div
        className="w-full flex flex-wrap"
        data-test-id="payment_modality_card"
      >
        <div className="w-full">
          {data && data.length > 0 ? (
            data.map((item, idx) => (
              <div key={v4()} className="w-full">
                {renderPaymentCondition(item, idx, data.length)}
              </div>
            ))
          ) : (
            <p>{t('convention.tab_payment_modality.no_items')}</p>
          )}
        </div>
      </div>
    </Card>
  );
}

export { TabPaymentModality };
