/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { Card } from '@components/Card';
import {
  editAhGeneralData,
  editAhOperationData,
  fetchAhOperationData,
  postWorksiteUpdateAmounts,
  storeProjectedEnd,
} from '@models/worksites/apiRequests/worksitesRequests';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { dateToDDMMYYY } from '@utils/format';
import { findKeyByValue, numFormatSpace } from '@utils/functions';
import {
  canEditWorksite,
  getAhGeneralData,
  getAideApiDataFromValue,
  totalBonus,
} from '@models/worksites/utils/utils';
import {
  IAidesData,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { InputTypes } from '@utils/utils';
import ActionButtons from '@models/worksites/components/worksiteDetails/worksiteInformations/ActionButtons';
import { UpdateWorksitePrimesModal } from '@models/worksites/components/worksiteDetails/worksiteInformations/UpdateWorksitePrimesModal';
import { GlobalContext } from '@context/globalContext';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AddIcon } from '@assets/images/svgComponents';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { getSubcontractorsList } from '@models/partners/apiRequests/partnersRequest';
import { AddSubcontractorForm } from '@models/partners/components/subcontractors/AddSubcontractorForm';
import { ISubcontractors } from '@models/worksiteCreation/utils/types/worksitesType';
import { getActivityLabel } from '@models/worksiteCreation/utils/functions';
import { AuthContext } from '@context/authContext';
import { IInputType } from '../../../../../types/globalTypes';

interface ICardInformationProps {
  loading: boolean;
  refresh: () => Promise<void>;
  iconsList: Record<string, JSX.Element>;
}

interface IAhExtraDataItem {
  label: string;
  type: IInputType;
  value: any;
  name: string;
  required: boolean;
  options?: string[] | undefined;
  readOnly?: boolean;
}
function CardWorksiteInformations({
  iconsList,
  refresh,
}: ICardInformationProps) {
  const { t } = useTranslation();
  const { worksiteDetails, ahGeneralData, updateAhGeneralData } =
    useContext(WorksitesContext);
  const { globalEnum } = useContext(GlobalContext);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editButtonDisabled, setEditButtonDisabled] = useState<boolean>(true);
  const [ahOperationData, setAhOperationData] = useState<
    Record<number, IAhExtraDataItem[]>
  >({});
  const [subcontractorList, setSubcontractorList] = useState<ISubcontractors[]>(
    []
  );
  const [newPayload, setNewPayload] = useState<{
    [x: string]: string | number;
  }>();
  const [isModalNewSubcontractor, setIsModalNewSubcontractor] = useState(false);
  // const [subcontractorActif, setSubcontractorActif] =
  //   useState<ISubcontractors>();
  const [isNew, setIsNew] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState<number>(0);

  const [subcontractorSelections, setSubcontractorSelections] = useState<
    Record<number, ISubcontractors | null>
  >({});

  const [activityLabel, setActivityLabel] = useState<string>('');
  const { user } = useContext(AuthContext);
  const methods = useForm();
  const { handleSubmit, getValues, setValue, reset, clearErrors } = methods;

  const prevListRef = useRef<ISubcontractors[]>();

  const borderBottomSeparatorClass =
    'border border-solid border-transparent border-b-borderGrey';

  // Fonction pour transformer ahExtraData en tableau d'objets pouvant être passés au DynamicInputEdit
  const transformAhExtraDataToArray = (
    data: Record<
      string,
      {
        label: string;
        type: IInputType;
        value: any;
        name: string;
        required: boolean;
      }
    >
  ): IAhExtraDataItem[] => {
    return Object.values(data);
  };

  const transformFormDataOperationForApi = (
    data: Record<string, any>
  ): Record<string, any> => {
    const transformedData: Record<string, Record<string, any>> = {};

    Object.keys(data).forEach((key) => {
      // Ignorer les clés qui ne sont pas concernées par la transformation
      if (key.includes('projected_end_date') || !/^\d/.test(key)) {
        return;
      }

      const parts = key.split('_');
      // L'ID est le premier élément après le split sur '_'
      const id = parts.shift(); // Retire et obtient le premier élément comme ID

      if (!id) {
        // Si par erreur on n'a pas d'ID, on ignore cette itération
        return;
      }

      // Le nom de la propriété est tout sauf le premier élément, rejoint par '_'
      const property = parts.join('_');

      // Il n'est pas clair comment ahExtraData est structuré par rapport à ce code,
      // mais supposons que vous avez une logique pour trouver l'objet initial basé sur l'ID et le nom de la propriété
      const initialObj = ahOperationData[Number(id)]?.find(
        (item) => item.name === property
      );

      // Vérifier si la valeur a changé par rapport à l'initial et n'est pas une chaîne vide
      if (
        data[key] !== undefined &&
        data[key] !== '' &&
        data[key].length !== 0 &&
        (!initialObj || data[key] !== initialObj?.value)
      ) {
        // Vérifier si l'ID existe déjà dans l'objet transformé
        if (!transformedData[id]) {
          transformedData[id] = {};
        }

        // Ajouter la propriété à l'objet correspondant à l'ID
        transformedData[id][property] = data[key];
      }
    });

    return transformedData;
  };

  const transformFormDataGeneralForApi = (
    data: Record<string, any>
  ): Record<string, any> => {
    const transformedData: Record<string, any> = {};

    Object.keys(data).forEach((key) => {
      if (key.startsWith('general_')) {
        const newKey = key.replace('general_', '');
        const initialObj = ahGeneralData?.find((item) => item.name === newKey);
        if (
          data[key] !== undefined &&
          data[key] !== '' &&
          data[key].length !== 0 &&
          (!initialObj || data[key] !== initialObj?.value)
        )
          transformedData[newKey] = data[key];
      }
    });

    return transformedData;
  };

  // récupère les données d'opérations AH et les formate pour l'affichage
  const getAhOperationData = async (id: number, codeOperation: string) => {
    const responseFromApi: Record<
      string,
      { label: string; type: IInputType; value: any; name: string }
    > = await fetchAhOperationData(id, codeOperation);

    if (responseFromApi) {
      // on filtre la réponse en retirant les données qui ont la clé 'notDisplay'
      const filterRespApi: any = Object.values(responseFromApi).filter(
        (obj: any) => {
          return !obj.notDisplay;
        }
      );
      const transformedData: IAhExtraDataItem[] =
        transformAhExtraDataToArray(filterRespApi);
      setAhOperationData((prevData) => ({
        ...prevData,
        [id]: [...transformedData],
      }));
    }
  };

  const handleChangeProjectedEnd = async (formValues: FieldValues) => {
    await worksiteDetails.worksites_operations.reduce(
      async (previousPromise, operation) => {
        await previousPromise;
        const newDate = formValues[`projected_end_date_${operation.id}`];
        if (newDate !== operation.projected_end) {
          return storeProjectedEnd(Number(worksiteDetails.id), [
            { id: operation.id, projected_end: newDate },
          ]);
        }
        return Promise.resolve();
      },
      Promise.resolve()
    );
  };

  const getAidesData = (formValues: FieldValues) => {
    const values: IAidesData = {};
    values.cadastral_parcel = formValues.general_cadastre;
    values.general_address = {
      city: formValues.general_city,
      address: formValues.general_street,
    };
    values.general_energy = getAideApiDataFromValue(
      formValues.general_energy,
      globalEnum,
      'heating_type'
    );

    values.general_habitation = getAideApiDataFromValue(
      formValues.general_habitation,
      globalEnum,
      'housing_type'
    );

    values.general_zipcode = formValues.general_zipcode;
    values.general_income_precarityType = findKeyByValue(
      globalEnum.precarity_type,
      formValues.general_menageType
    );

    values.general_installationByThirdParty =
      formValues.general_installationByThirdParty;
    values.general_technicalServicesInvolved =
      formValues.general_technicalServicesInvolved;
    values.general_materialInstallationNotRequired =
      formValues.general_materialInstallationNotRequired;

    values.operations = worksiteDetails.worksites_operations.map((op) => {
      let valuesOperations = {};
      Object.entries(formValues).forEach(([key, value]) => {
        const idKey = `${op.id}_`;
        if (key.includes(idKey)) {
          const newKey = `operation_${key.split(idKey)[1]}`;
          valuesOperations = {
            ...valuesOperations,
            [newKey]: value,
            operation_id: op.operation.code.toLowerCase(), //
          };
        }
      });
      return valuesOperations;
    });

    Object.entries(values).forEach(([key, value]) => {
      let emptyObj = false;
      if (typeof value === 'object' && value !== null) {
        emptyObj = Object.values(value).every((val) => !val || val === '');

        Object.entries(value).forEach(([objKey, objValue]) => {
          if (!objValue || objValue === '') delete values[objKey];
        });
      }

      if (!value || value === '' || emptyObj) delete values[key];
    });

    return values;
  };

  const updateData = async (aidesData: any) => {
    const formValues = getValues();
    const datasOperationToUpdate = transformFormDataOperationForApi(
      getValues()
    );
    const datasGeneralToUpdate = transformFormDataGeneralForApi(getValues());
    if (worksiteDetails.status === WORKSITE_STATUS.WORKSITE_IN_PROGRESS) {
      await handleChangeProjectedEnd(formValues);
    }

    if (Object.keys(datasOperationToUpdate).length > 0) {
      await Object.keys(datasOperationToUpdate).reduce(
        // 1 appel à la fois
        async (previousPromise, id) => {
          // Attendre que la promesse précédente se termine
          await previousPromise;

          const operation = worksiteDetails.worksites_operations.find(
            (op) => op.id === Number(id)
          );

          if (operation) {
            const resFromApi = await editAhOperationData(
              Number(id),
              operation.operation.code,
              datasOperationToUpdate[id],
              aidesData
            );

            if (resFromApi) {
              await getAhOperationData(operation.id, operation.operation.code);
            }
          }
        },
        Promise.resolve()
      );
    }

    if (Object.keys(datasGeneralToUpdate).length > 0) {
      const resFromApi = await editAhGeneralData(
        Number(worksiteDetails.id),
        datasGeneralToUpdate,
        aidesData
      );
      if (resFromApi) {
        await getAhGeneralData(worksiteDetails.id, updateAhGeneralData);
      }
    }

    setNewPayload(undefined);
    setIsLoading(false);
    setIsEditMode(false);
    reset();

    if (refresh) {
      refresh();
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const formValues = getValues();

    const resAmounts = await postWorksiteUpdateAmounts(
      String(worksiteDetails.id),
      getAidesData(formValues)
    );

    const totalPrimes = totalBonus(worksiteDetails.worksites_operations);

    if (Number(resAmounts.totalPrimesEuros) !== totalPrimes)
      setNewPayload(resAmounts);
    else if (resAmounts) await updateData(resAmounts);
    else await updateData([]);
  };

  const totalVolume = worksiteDetails.worksites_operations.reduce(
    (sum, obj) =>
      sum + Number(obj.kwhc_classique || 0) + Number(obj.kwhc_precaire || 0),
    0
  );
  const getDatas = async () => {
    const promesses = [];

    // Ajouter les promesses des opérations de chantier au tableau, si elles existent
    if (worksiteDetails.worksites_operations.length > 0) {
      worksiteDetails.worksites_operations.forEach((operation) => {
        // Assurez-vous que getAhOperationData retourne une promesse
        promesses.push(
          getAhOperationData(operation.id, operation.operation.code)
        );
      });
    }

    // Ajouter la promesse pour les données générales du chantier, si applicable
    if (worksiteDetails.id) {
      // Assurez-vous que getAhGeneralData retourne une promesse
      promesses.push(getAhGeneralData(worksiteDetails.id, updateAhGeneralData));
    }

    await Promise.all(promesses);
    setEditButtonDisabled(false);
  };

  const subcontractors = async () => {
    const page = 1;

    const resList = await getSubcontractorsList({
      page,
      perPage: 300,
      worksiteId: Number(worksiteDetails.id),
    });

    if (resList) {
      // Add the select none subcontractor entry
      const selectNoneSubcontractorEntry = {
        id_partenaire: -1,
        company_name: t('worksites.subcontractor.select_none'),
        siret: '',
        status: '',
        entity_type: '',
      };

      const updatedList = [selectNoneSubcontractorEntry, ...resList.data];

      setSubcontractorList(updatedList);
    }
  };

  const getCompanyName = (sub: ISubcontractors) => `${sub.company_name}`;

  const getDefaultSelectedSubcontractor = (selectedSubcontractor: any) => {
    return selectedSubcontractor
      ? selectedSubcontractor.company_name
      : t('worksites.subcontractor.select_none');
  };

  const subcontractorArrayList = useMemo(() => {
    return subcontractorList.map((elt) => getCompanyName(elt));
  }, [subcontractors]);

  // sélectionne automatiquement le sous-traitant qui vient d'être créé
  useEffect(() => {
    if (!prevListRef.current) {
      prevListRef.current = subcontractorList;
      return;
    }

    const currentList = prevListRef.current;

    if (subcontractorList.length > currentList.length) {
      const newSubcontractors = subcontractorList.filter(
        (subcontractor) =>
          !currentList.some(
            (item) => item.id_partenaire === subcontractor.id_partenaire
          )
      );

      if (newSubcontractors.length > 0 && isNew) {
        setSubcontractorSelections((prev) => ({
          ...prev,
          [selectedOperation]: newSubcontractors[0],
        }));
        setValue(
          `${selectedOperation}_hasSubcontractor`,
          newSubcontractors[0].id_partenaire
        );
        setIsNew(false);
      }
    }
    // reinitialize
    prevListRef.current = subcontractorList;
  }, [subcontractorList, isNew, selectedOperation]);

  useEffect(() => {
    getDatas();
    subcontractors();
  }, []);

  useEffect(() => {
    if (worksiteDetails.payload) {
      const payload = JSON.parse(worksiteDetails.payload);
      const withBat = worksiteDetails.worksites_operations.some((wo) =>
        wo.operation.code.includes('BAT-')
      );
      if (payload['general.activity'] && withBat) {
        setActivityLabel(getActivityLabel(payload['general.activity'], t));
      }
    }
  }, [worksiteDetails.payload]);
  const blockEditableFieldsOperation = (operation: IWorksiteOperation) => {
    return (
      <React.Fragment key={`editable_fields_${operation.id}`}>
        {worksiteDetails.status === WORKSITE_STATUS.WORKSITE_IN_PROGRESS && (
          <>
            <DynamicInputEdit
              label={t('worksites.projected_end_date')}
              name={`projected_end_date_${operation.id}`}
              inputType={InputTypes.DATE}
              initialValue={operation.projected_end}
              isEditMode={isEditMode}
              addClass="mt-4"
            />
            <div />
          </>
        )}
      </React.Fragment>
    );
  };

  const generalInfos = [
    {
      title: t('worksites.worksite_reference'),
      subtitle: worksiteDetails.reference,
    },
    {
      title: t('worksites.total_volume'),
      subtitle: `${numFormatSpace(totalVolume)} kWhc`,
    },
    {
      title: 'RAI',
      subtitle: worksiteDetails.convention.rai.company_name || '',
    },
  ];

  const rightPartInfo = [
    {
      title: t('worksite_creation.steps.worksite_creation'),
      subtitle: dateToDDMMYYY(worksiteDetails.dates?.creation),
    },
    {
      title: t('worksites.total_bonus'),
      subtitle: `${numFormatSpace(
        totalBonus(worksiteDetails.worksites_operations)
      )} €`,
    },
  ];

  if (activityLabel !== '') {
    rightPartInfo.push({
      title: t('worksite_creation.simulation.activity_sector'),
      subtitle: activityLabel,
    });
  }

  const blockGeneralInfos = (
    <div
      className={`grid gap-2 grid-cols-2 pb-4 ${borderBottomSeparatorClass}`}
    >
      <InfosWithIcon
        spaceY="space-y-6"
        icon={iconsList.info}
        infos={generalInfos}
        spaceLeft
      />
      <InfosWithIcon spaceY="space-y-6" infos={rightPartInfo} spaceLeft />
      {ahGeneralData &&
        ahGeneralData
          .filter((input) => input.name !== 'reference')
          .map((input) => (
            <DynamicInputEdit
              key={`${input.label}_${input.type}`}
              inputType={input.type}
              name={`general_${input.name}`}
              label={input.label}
              initialValue={input.value}
              isEditMode={isEditMode}
              required={input.required}
              addClass="mt-4"
              options={input.options || undefined}
            />
          ))}
    </div>
  );

  const blockSubcontractor = (operation: IWorksiteOperation) => {
    const selectedSubcontractor =
      subcontractorSelections[operation.id] ||
      subcontractorList.find(
        (elt) => elt.id_partenaire === operation.subcontractor?.id
      ) ||
      null;

    return (
      <div className="w-full grid gap-2 grid-cols-2">
        {isEditMode ? (
          <>
            <p className="w-full text-[.75rem] leading-[.75rem]">
              <InputSelect
                label={String(t('partners.subcontractors.name'))}
                placeholder={t('worksites.subcontractor.select_none')}
                addClass="!w-full"
                valueInput={selectedSubcontractor?.company_name || ''}
                defaultSelected={getDefaultSelectedSubcontractor(
                  selectedSubcontractor
                )}
                dataTestIdSelect="input_select_subcontractor"
                onSelect={(e) => {
                  const selectedSub = subcontractorList.find(
                    (elt) => `${elt.company_name}` === e
                  );

                  if (selectedSub) {
                    setSubcontractorSelections((prev) => ({
                      ...prev,
                      [operation.id]: selectedSub,
                    }));
                    setValue(
                      `${operation.id}_hasSubcontractor`,
                      selectedSub.id_partenaire
                    );
                  }
                }}
                dataTestIdOptions="hasSubcontractor"
                dataArrayString={subcontractorArrayList}
              />
            </p>

            <ButtonOpx
              onClick={() => {
                setIsModalNewSubcontractor(true);
                setIsNew(true);
                setSelectedOperation(operation.id);
              }}
              label={t('buttons.new_subcontractor')}
              type="tab"
              addClass="mt-[1.25rem] w-min"
              icon={<AddIcon />}
              dataTestId="button_add_new_subcontractor"
            />
            {isModalNewSubcontractor && (
              <AddSubcontractorForm
                setModal={setIsModalNewSubcontractor}
                refreshList={subcontractors}
                backgroundTransparent={false}
                sidebarVisible={false}
              />
            )}
          </>
        ) : (
          <div className="flex-col items-center w-full space-y-[.5rem] ml-[3.0rem]">
            <InfosWithIcon
              spaceY="space-y-6"
              infos={[
                {
                  title: t('partners.subcontractors.name'),
                  subtitle:
                    operation.subcontractor?.company_name ||
                    String(t('worksites.subcontractor.select_none')),
                },
              ]}
              addClass="flex items-start space-x-[1rem] mt-4"
            />
          </div>
        )}
      </div>
    );
  };

  const blockOperationInfos = ({
    operation,
    index,
    withSeparator = true,
  }: {
    operation: IWorksiteOperation;
    index: number;
    withSeparator?: boolean;
  }) => {
    return (
      <div
        className={`flex flex-col items-start space-y-4 pt-4 ${
          withSeparator ? `pb-4 ${borderBottomSeparatorClass}` : ''
        }`}
        key={operation.id}
      >
        <div className="w-full grid gap-3 grid-cols-2">
          <InfosWithIcon
            spaceY="space-y-6"
            icon={index + 1}
            infos={[
              {
                title: `${t('worksites.operation.title')} ${index + 1}`,
                subtitle: `${operation.operation.code} : ${operation.operation.description}`,
              },
            ]}
          />
          <InfosWithIcon
            spaceY="space-y-6"
            infos={[
              {
                title: t('worksites.operation.total_cost'),
                subtitle: `${numFormatSpace(operation.total_cost)} €`,
              },
            ]}
            spaceLeft
          />
          {operation.material?.caracteristics && (
            <InfosWithIcon
              infos={[
                {
                  title: t('worksite_creation.material.mark'),
                  subtitle: JSON.parse(operation.material.caracteristics)?.mark
                    ?.name,
                },
              ]}
              spaceLeft
              addClass="space-y-4"
            />
          )}
          {operation.material?.caracteristics && (
            <InfosWithIcon
              infos={[
                {
                  title: t('worksite_creation.material.material_reference'),
                  subtitle: JSON.parse(operation.material.caracteristics)
                    ?.reference?.name,
                },
              ]}
              spaceLeft
              addClass="space-y-4"
            />
          )}
          {blockEditableFieldsOperation(operation)}
          {ahOperationData[operation.id] &&
            ahOperationData[operation.id]
              .filter(
                (input) =>
                  input.name !== 'reference' &&
                  input.name !== 'worksiteName' &&
                  input.name !== 'menageType' &&
                  input.label !== 'Marque'
              )
              .map((input) => (
                <DynamicInputEdit
                  key={`${input.label}_${input.type}`}
                  inputType={input.type}
                  name={`${operation.id}_${input.name}`}
                  label={input.label}
                  initialValue={input.value}
                  isEditMode={isEditMode && !input.readOnly}
                  options={input.options || undefined}
                  required={input.required}
                  addClass="mt-4"
                />
              ))}
        </div>
        {blockSubcontractor(operation)}
      </div>
    );
  };

  const handleCancel = () => {
    setIsEditMode(false);
    clearErrors();
  };

  return (
    <div className="w-full">
      <Card
        title={t('worksites.worksite_information')}
        addClass="h-[max-content] w-full"
        actionButtons={
          user && canEditWorksite(worksiteDetails.status, user.entity_type) ? (
            <ActionButtons
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              onSubmit={handleSubmit(onSubmit)}
              isLoading={isLoading}
              disabled={editButtonDisabled}
              onCancel={handleCancel}
            />
          ) : undefined
        }
        dataTestId="worksite_details_information"
      >
        <FormProvider {...methods}>
          <form id="form-card-infos" onSubmit={handleSubmit(onSubmit)}>
            {blockGeneralInfos}
            {worksiteDetails.worksites_operations.map((operation, index) =>
              blockOperationInfos({
                operation,
                index,
                withSeparator:
                  index !== worksiteDetails.worksites_operations.length - 1,
              })
            )}
          </form>
        </FormProvider>
      </Card>{' '}
      {newPayload && (
        <UpdateWorksitePrimesModal
          onError={() => setNewPayload(undefined)}
          onClickCancel={() => {
            setIsLoading(false);
            setNewPayload(undefined);
            setIsEditMode(false);
          }}
          newPayload={newPayload}
          setNewPayload={setNewPayload}
          onClickConfirm={() => updateData(newPayload)}
        />
      )}
    </div>
  );
}

export { CardWorksiteInformations };
