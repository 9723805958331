/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import {
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  SetStateAction,
  Dispatch,
} from 'react';
import Slider from '@mui/material/Slider';
import Modal from '@mui/material/Modal';
import {
  BrightnessIcon,
  ContrastIcon,
  CropIcon,
  SaturationIcon,
  DownloadIcon,
  RestoreIcon,
} from '@assets/images/svgComponents';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import Cropper from 'react-easy-crop';
import {
  dataURLtoBlob,
  fetchImageAsBlob,
  getCroppedImg,
} from '@models/settings/utils/customizeImage'; // Utilitaire pour le recadrage
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import {
  IImageData,
  IInitialDataImageType,
} from '@models/settings/utils/settingsTypes';
import { useTranslation } from 'react-i18next';

interface ImageCustomizerProps {
  title: string;
  name: string;
  aspectRatio: number;
  initialData?: IInitialDataImageType;
  onUpdateImage: Dispatch<SetStateAction<IImageData[]>>;
}

function ImageCustomizer({
  title,
  name,
  aspectRatio,
  initialData,
  onUpdateImage,
}: ImageCustomizerProps) {
  const { t } = useTranslation();
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [initialImageSrc, setInitialImageSrc] = useState<string | undefined>(
    initialData?.src
  );
  const [imageSrc, setImageSrc] = useState<string | undefined>(
    initialData?.src
  );
  const [tempImageSrc, setTempImageSrc] = useState<string | undefined>(
    initialData?.src || undefined
  );

  const [isInitialDataPreSet, setIsInitialDataPreSet] = useState(false);

  // États pour le recadrage
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crop, setCrop] = useState(initialData?.crop || { x: 0, y: 0 });
  const [zoom, setZoom] = useState(initialData?.zoom || 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(
    initialData?.croppedAreaPixels || null
  );

  // État pour les dimensions ajustées
  const [scaledWidth, setScaledWidth] = useState<number>(0);
  const [scaledHeight, setScaledHeight] = useState<number>(0);

  const [imageFile, setImageFile] = useState<File | null>(null);

  const [image] = useImage(imageSrc || '', 'anonymous');
  const imageRef = useRef<Konva.Image>(null);

  // Référence pour l'input de fichier
  const fileInputRef = useRef<HTMLInputElement>(null);

  const filters: any[] = [];

  if (brightness !== 0) {
    filters.push(Konva.Filters.Brighten);
  }

  if (contrast !== 0) {
    filters.push(Konva.Filters.Contrast);
  }

  if (saturation !== 0) {
    filters.push(Konva.Filters.HSL);
  }

  const blackSliderStyles = {
    color: 'black',
    '& .MuiSlider-thumb': {
      backgroundColor: 'black',
      border: '2px solid black',
    },
    '& .MuiSlider-rail': {
      color: 'black',
    },
    '& .MuiSlider-track': {
      color: 'black',
    },
    '& .MuiSlider-mark': {
      backgroundColor: 'black',
      height: 8,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'black',
      },
    },
  };

  // Dimensions maximales en pixels (30rem = 480px, 26rem = 416px)
  const MAX_HEIGHT = 480;
  const MAX_WIDTH = 300;

  const canvasRef = useRef<HTMLCanvasElement>(document.createElement('canvas'));

  const stageRef = useRef<Konva.Stage>(null);

  // Variable pour déterminer si l'image est affichée
  const isImageDisplayed = !!(
    image &&
    imageSrc &&
    scaledWidth > 0 &&
    scaledHeight > 0 &&
    imageRef
  );

  /**
   * Gestionnaire de changement de fichier
   * @param {ChangeEvent<HTMLInputElement>} event - L'événement de changement de fichier.
   */
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setTempImageSrc(reader.result as string);
        setIsModalOpen(true);
        // Réinitialiser la valeur de l'input pour permettre la sélection du même fichier
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      });
      reader.readAsDataURL(file);
    }
  };

  /**
   * Gestionnaire de la complétion du recadrage
   * @param {any} croppedArea - La zone recadrée.
   * @param {any} croppedAreaPixelsProp - La zone recadrée en pixels.
   */
  const onCropCompleteHandler = (
    croppedArea: any,
    croppedAreaPixelsProp: any
  ) => {
    if (croppedArea && croppedAreaPixelsProp) {
      setCroppedAreaPixels(croppedAreaPixelsProp);
    }
  };

  /**
   * Génère l'image recadrée et la définit comme source d'image.
   */
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        tempImageSrc || '',
        croppedAreaPixels
      );
      if (tempImageSrc !== initialImageSrc) {
        setBrightness(0);
        setContrast(0);
        setSaturation(0);
        setInitialImageSrc(tempImageSrc);
      }
      setImageSrc(croppedImage as string);
      setTempImageSrc(undefined);
      setIsModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Fonction pour exporter le canvas en tant que fichier
   */
  const exportCanvasAsFile = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });
      const blob = dataURLtoBlob(dataURL);
      const file = new File([blob], `${name}-custom.png`, {
        type: 'image/png',
      });
      setImageFile(file);
    }
  };

  /**
   * Déclenche le clic sur l'input de fichier via la référence.
   */
  const triggerFileSelect = () => {
    fileInputRef.current?.click();
  };

  const createImageData = async (): Promise<IImageData> => {
    // eslint-disable-next-line no-nested-ternary
    const initialImageBlob = initialImageSrc
      ? initialImageSrc.includes('.s3')
        ? await fetchImageAsBlob(initialImageSrc)
        : dataURLtoBlob(initialImageSrc)
      : null;
    const imageData: IImageData = {
      image: name,
      initial: initialImageBlob
        ? new File([await initialImageBlob], `${name}-initial.png`, {
            type: 'image/png',
          })
        : null,
      custom: imageFile,
      crop:
        initialData?.crop && initialData?.zoom
          ? {
              x: initialData.crop.x,
              y: initialData.crop.y,
              zoom: initialData.zoom,
            }
          : { x: 0, y: 0, zoom: 1 },
      brightness,
      contrast,
      saturation,
      croppedAreaPixels,
    };
    onUpdateImage((prevImages: IImageData[]) => {
      const otherImages = prevImages.filter((img) => img.image !== name);
      return [...otherImages, imageData];
    });
    return imageData;
  };

  useEffect(() => {
    if (image) {
      const { width, height } = image;
      const onValueIsBiggerThanMax = width > MAX_WIDTH || height > MAX_HEIGHT;
      const widthRatio = MAX_WIDTH / width;
      const heightRatio = MAX_HEIGHT / height;
      const minRatio = Math.min(widthRatio, heightRatio, 1); // Ne pas agrandir l'image

      setScaledWidth(onValueIsBiggerThanMax ? width * minRatio : width);
      setScaledHeight(onValueIsBiggerThanMax ? height * minRatio : height);
    }
  }, [imageSrc, image]);

  useEffect(() => {
    if (image) {
      imageRef.current?.cache();
    }
  }, [brightness, contrast, saturation]);

  useEffect(() => {
    exportCanvasAsFile();
  }, [imageSrc, image, brightness, contrast, saturation, scaledWidth]);

  useEffect(() => {
    createImageData();
  }, [imageFile, initialImageSrc]);

  useEffect(() => {
    if (image && !isInitialDataPreSet) {
      if (initialData) {
        if (initialData.brightness) {
          setBrightness(initialData.brightness);
        }

        if (initialData.contrast) {
          setContrast(initialData.contrast);
        }

        if (initialData.saturation) {
          setSaturation(initialData.saturation);
        }
        setIsInitialDataPreSet(true);
      }
    }
  }, [image, initialData, isInitialDataPreSet]);

  useEffect(() => {
    if (initialData) {
      if (initialData.src && initialData.croppedAreaPixels) {
        showCroppedImage();
      }
    }
  }, []);

  return (
    <div className="flex space-x-[8.75rem]">
      <div>
        <div className="space-y-2 mb-[1.12rem]">
          <p className="font-medium">{title}</p>
          <p className="text-[0.875rem] text-textGrey">
            {imageSrc
              ? t('settings.white_label.click_to_modify_image')
              : t('settings.white_label.click_to_upload_logo')}
          </p>
        </div>

        <div
          id="image-customizer-sliders"
          className={`bg-backgroundBody p-6 space-y-[1.12rem] w-[33.8125rem] flex flex-col items-center ${
            !isImageDisplayed ? 'opacity-50' : ''
          }`}
        >
          <div className="space-y-2 w-[28.8125rem]">
            <label className="text-[0.875rem] text-textGrey">
              {t('settings.white_label.brightness')}
            </label>
            <div className="flex items-center space-x-2">
              <BrightnessIcon />
              <Slider
                value={brightness}
                onChange={(e, val) => setBrightness(val as number)}
                min={-1}
                max={1}
                step={0.1}
                size="small"
                sx={blackSliderStyles}
                disabled={!isImageDisplayed}
              />
            </div>
          </div>

          <div className="space-y-2 w-[28.8125rem]">
            <label className="text-[0.875rem] text-textGrey">
              {t('settings.white_label.contrast')}
            </label>
            <div className="flex items-center space-x-2">
              <ContrastIcon />
              <Slider
                value={contrast}
                onChange={(e, val) => setContrast(val as number)}
                min={-100}
                max={100}
                step={1}
                size="small"
                sx={blackSliderStyles}
                disabled={!isImageDisplayed}
              />
            </div>
          </div>

          <div className="space-y-2 w-[28.8125rem]">
            <label className="text-[0.875rem] text-textGrey">
              {t('settings.white_label.saturation')}
            </label>
            <div className="flex items-center space-x-2">
              <SaturationIcon />
              <Slider
                value={saturation}
                onChange={(e, val) => setSaturation(val as number)}
                min={-1}
                max={1}
                step={0.1}
                size="small"
                sx={blackSliderStyles}
                disabled={!isImageDisplayed}
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <ButtonOpx
              icon={<RestoreIcon />}
              label={t('settings.white_label.restore')}
              type="tierciary"
              color="black"
              onClick={() => {
                setBrightness(0);
                setContrast(0);
                setSaturation(0);
              }}
              disabled={!isImageDisplayed}
            />
            {imageSrc && (
              <ButtonOpx
                icon={<CropIcon />}
                label={t('settings.white_label.resize')}
                type="tierciary"
                color="black"
                onClick={() => {
                  setTempImageSrc(initialImageSrc);
                  setIsModalOpen(true);
                }}
                disabled={!isImageDisplayed}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-grow justify-center items-center space-y-[2.5rem]">
        <div className="relative" onClick={triggerFileSelect}>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            hidden
            onChange={handleFileChange}
          />
          {image &&
          imageSrc &&
          scaledWidth > 0 &&
          scaledHeight > 0 &&
          imageRef ? (
            <div className="border border-borderGrey rounded-default cursor-pointer">
              <Stage ref={stageRef} width={scaledWidth} height={scaledHeight}>
                <Layer>
                  <KonvaImage
                    ref={imageRef}
                    image={image}
                    filters={filters}
                    brightness={brightness}
                    contrast={contrast}
                    saturation={saturation}
                    cornerRadius={4}
                    width={scaledWidth}
                    height={scaledHeight}
                  />
                </Layer>
              </Stage>
            </div>
          ) : (
            <div className="w-64 h-64 bg-backgroundBody border border-dashed border-textGrey flex flex-col items-center justify-center text-center cursor-pointer rounded-default p-4 space-y-4">
              <ColorCube
                numberOrIcon={<DownloadIcon />}
                size="2.5rem"
                color={blueOpx}
                opacity
              />
              <p>{t('settings.white_label.click_to_upload_image')}</p>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setTempImageSrc(undefined);
          setCrop(initialData?.crop || { x: 0, y: 0 });
          setZoom(initialData?.zoom || 1);
        }}
        aria-labelledby="crop-modal-title"
        aria-describedby="crop-modal-description"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-default">
          <h2 id="crop-modal-title" className="text-lg font-medium mb-4">
            {t('settings.white_label.crop_image')}
          </h2>
          <div className="relative w-[30rem] h-[30rem] bg-textGrey rounded-default">
            <Cropper
              image={tempImageSrc || ''}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropCompleteHandler}
            />
          </div>
          <div className="space-y-2 mt-4">
            <label className="text-[0.875rem] text-textGrey">
              {t('settings.white_label.zoom')}
            </label>
            <div className="flex items-center space-x-2">
              <CropIcon />
              <Slider
                value={zoom}
                onChange={(e, val) => setZoom(val as number)}
                min={1}
                max={2}
                step={0.1}
                size="small"
                sx={blackSliderStyles}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mt-4">
            <ButtonOpx
              label={t('settings.white_label.cancel')}
              type="secondary"
              onClick={() => {
                setIsModalOpen(false);
                setTempImageSrc(undefined);
              }}
            />
            <ButtonOpx
              label={t('settings.white_label.apply')}
              type="primary"
              onClick={showCroppedImage}
            />
          </div>
        </div>
      </Modal>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
}

export default ImageCustomizer;

ImageCustomizer.defaultProps = {
  initialData: {
    brightness: 0,
    contrast: 0,
    saturation: 0,
    src: undefined,
    customizedSrc: undefined,
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: undefined,
  },
};
