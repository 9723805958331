/* eslint-disable import/no-cycle */
import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { format, parse } from 'date-fns';

import { ROLES } from '@utils/roles';
import {
  IContractInfosGeneralType,
  IContractType,
  ITransferType,
} from '@models/contracts/utils/contractTypes';
import { IUserType } from '@models/auth/utils/types';

import { numberWithSeparator } from '@utils/format';

import {
  CalendarIcon,
  EuroIcon,
  InfoCircleIcon,
  VolumeGraphIcon,
} from '@assets/images/svgComponents';
import {
  convertKiloToGiga,
  convertKiloToMega,
  isBeforeToday,
} from '@utils/functions';
import { URL_TYPES } from '@models/contracts/utils/contractConstants';
import { IWorksiteOperation } from '@models/worksites/utils/worksitesTypes';
import { Tag } from '@components/atomic/Tag';
import { lightBlue, purple, red } from '@assets/color';
import React, { useMemo } from 'react';
import { IGlobalEnumType } from '../../../types/globalTypes';

const columnHelper = createColumnHelper<IContractType>();

export const getColumns = (
  t: TFunction,
  roleUser: string,
  enumContractTypes: { [key: number]: string },
  globalEnum: IGlobalEnumType
) => {
  const columnsInstaller = [
    columnHelper.accessor('id', {
      header: () => 'id',
    }),
    columnHelper.accessor('company_name', {
      header: () => t('contract.partner'),
      cell: (info) => {
        return <span>{info.getValue() ? info.getValue() : '-'}</span>;
      },
    }),
    columnHelper.accessor('convention_model_status', {
      header: () => t('contract.status'),
      cell: (info) => {
        const isExpired = useMemo(() => {
          return isBeforeToday(info.row.original.end_date);
        }, [info.row.original]);

        const conventionModelStatus = isExpired
          ? t('convention.model.expired')
          : globalEnum.convention_model_status[
              Number(info.row.original.convention_model_status)
            ];

        return (
          <span style={{ color: isExpired ? red : lightBlue }}>
            {conventionModelStatus}
          </span>
        );
      },
    }),
    columnHelper.accessor('contract_type', {
      header: () => t('contract.contract_type'),
      cell: (info) => {
        const contractType = info.row.original.beneficiary
          ? 5
          : info.getValue();

        const strContractType = enumContractTypes
          ? enumContractTypes[`${contractType}`]
          : '';
        return <span>{strContractType}</span>;
      },
    }),
    columnHelper.accessor('internal_reference', {
      header: () => t('contract.internal_reference'),
      cell: (info) => {
        return <span>{info.getValue() ? info.getValue() : '-'}</span>;
      },
    }),
    columnHelper.accessor('reference', {
      header: () => t('contract.reference_opx'),
      cell: (info) => (
        <span data-test-id={info.getValue()}> {info.getValue()}</span>
      ),
    }),
    columnHelper.accessor('oblige', {
      header: () => t('partners.obliged.name'),
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor('related_contract', {
      header: () => t('contract.related_contract'),
      cell: (info) => {
        return <span>{info.getValue() ?? '-'}</span>;
      },
    }),
    columnHelper.accessor('start_date', {
      header: () => t('contract.contract_start_date'),
      cell: (info) => {
        const dateString = info.getValue();
        let formattedDate = null;

        if (dateString) {
          const dateParts = dateString.split(' ')[0];
          formattedDate = parse(dateParts, 'yyyy-MM-dd', new Date());
        }

        return (
          <span>
            {formattedDate ? format(formattedDate, 'dd/MM/yyyy') : '-'}
          </span>
        );
      },
    }),
    columnHelper.accessor('end_date', {
      header: () => t('contract.contract_end_date'),
      cell: (info) => {
        const dateString = parse(info.getValue(), 'yyyy-MM-dd', new Date());
        return (
          <span>
            {info.getValue() ? format(dateString, 'dd/MM/yyyy') : '-'}
          </span>
        );
      },
    }),
    columnHelper.accessor('active_installer_count', {
      header: () => t('contract.active_installer_count'),
      cell: (info) => (
        <span data-test-id={info.getValue()}>{info.getValue()}</span>
      ),
    }),
  ];

  // const columnsNotInstaller = [
  //   columnHelper.accessor('volume_classique_kwhc', {
  //     header: () => t('contract.volume_classique_kwhc'),
  //     cell: (info) => {
  //       const sum =
  //         Number(info.row.original.sum_volume_ops_classique_kwhc || 0) / 1000;
  //       const volume_contrat = Number(info.getValue() || 0) / 1000;

  //       return (
  //         <p>
  //           <span className="font-[500] text-[.9rem]">
  //             {`${numFormatSpace(sum)} Mwhc /`}
  //           </span>
  //           <br />
  //           <span className="text-[.8rem]">{` ${numFormatSpace(
  //             volume_contrat
  //           )} Mwhc`}</span>
  //         </p>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor('volume_precaire_kwhc', {
  //     header: () => t('contract.volume_precaire_kwhc'),
  //     cell: (info) => {
  //       const sum =
  //         Number(info.row.original.sum_volume_ops_precaire_kwhc || 0) / 1000;
  //       const volume_contrat = Number(info.getValue() || 0) / 1000;

  //       return (
  //         <p>
  //           <span className="font-[500] text-[.9rem]">
  //             {`${numFormatSpace(sum)} Mwhc /`}
  //           </span>
  //           <br />
  //           <span className="text-[.8rem]">{` ${numFormatSpace(
  //             volume_contrat
  //           )} Mwhc`}</span>
  //         </p>
  //       );
  //     },
  //   }),
  // ];

  return roleUser === ROLES.PRODUCTION
    ? columnsInstaller
    : [
        ...columnsInstaller,
        // ...columnsNotInstaller
      ];
};

export const verifyPermission = (
  user: IUserType | null,
  permissionList: string[],
  isComparisonStrict = false
) => {
  if (!user) return false;

  const checks = permissionList.map((permissionName) => {
    return user.permissions_names.includes(permissionName);
  });
  return isComparisonStrict ? !checks.includes(false) : checks.includes(true);
};

export const noPermissions = (t: TFunction) => (
  <div className="w-full items-center justify-center  mt-5">
    <p>{t('global.no_permission')}</p>
  </div>
);

export const getCardInfosGeneralContract = (
  t: TFunction,
  contractType: string,
  data: IContractInfosGeneralType,
  globalEnum: any
) => {
  const startDate = parse(data.start_date || '', 'yyyy-MM-dd', new Date());
  const endDate = parse(data.end_date || '', 'yyyy-MM-dd', new Date());

  const basicInfos = [
    {
      name: 'reference',
      title: t('contract.reference_opx'),
      value: data.reference,
      icon: <InfoCircleIcon />,
    },
    {
      name: 'internal_reference',
      title: t('contract.internal_reference'),
      value: data.internal_reference,
    },
  ];

  const basicMandant = [
    {
      name: 'rai',
      title: t('convention.tab_info_general.rai'),
      value: data.rai.company_name,
    },
    {
      name: 'mandat_type',
      title: t('contractMandant.mandat_type'),
      value:
        contractType === URL_TYPES.MANDANT_NON_DEPOSANT
          ? 'Non déposant'
          : 'Déposant',
    },
    {
      name: 'incentive_type',
      title: t(
        'worksite_creation.form_modal_before_simulation.title_incentive_type'
      ),
      value: data.incentive_type
        ? globalEnum.incentive_type[Number(data.incentive_type)]
        : '-',
    },
    {
      name: 'can_manage_worksite_documents',
      title: t('contract.can_manage_worksite_documents'),
      value: data.can_manage_worksite_documents
        ? t('global.yes')
        : t('global.no'),
    },
  ];

  const datesInfos = [
    {
      name: 'start_date',
      title: t('convention.tab_info_general.start_date'),
      value: startDate ? format(startDate, 'dd/MM/yyyy') : null,
      icon: <CalendarIcon />,
    },
    {
      name: 'end_date',
      title: t('convention.tab_info_general.end_date'),
      value: endDate ? format(endDate, 'dd/MM/yyyy') : null,
    },
  ];

  const getPercent = (price: number | null, type: 'max' | 'min') => {
    if (!price) return '-';

    const volP =
      type === 'max'
        ? data.volume_precaire_kwhc
        : data.minimum_volume_precaire_kwhc;
    const volC =
      type === 'max'
        ? data.volume_classique_kwhc
        : data.minimum_volume_classique_kwhc;

    const total = (volP || 0) + (volC || 0);

    return `${numberWithSeparator(String((price * 100) / total))}%`;
  };

  const volumesMax = [
    {
      name: 'volume_classique_kwhc_max',
      title: `${t('convention.tab_info_general.volume_classique_max')} (GWhc)`,
      value: `${convertKiloToGiga(data.volume_classique_kwhc)} GWhc`,
      icon: <VolumeGraphIcon />,
    },
    {
      name: 'percent_classique_max',
      title: t('convention.tab_info_general.percent_gisement'),
      value: getPercent(data.volume_classique_kwhc, 'max'),
    },
    {
      name: 'volume_precaire_kwhc_max',
      title: `${t('convention.tab_info_general.volume_precaire_max')} (GWhc)`,
      value: `${convertKiloToGiga(data.volume_precaire_kwhc)} GWhc`,
    },
    {
      name: 'percent_precaire_max',
      title: t('convention.tab_info_general.percent_gisement'),
      value: getPercent(data.volume_precaire_kwhc, 'max'),
    },
  ];

  const volumesMin = [
    {
      name: 'volume_classique_kwhc_min',
      title: `${t('convention.tab_info_general.volume_classique_min')} (GWhc)`,
      value: `${convertKiloToGiga(data.minimum_volume_classique_kwhc)} GWhc`,
      icon: <VolumeGraphIcon />,
    },
    {
      name: 'percent_classique_min',
      title: t('convention.tab_info_general.percent_gisement'),
      value: getPercent(data.minimum_volume_classique_kwhc, 'min'),
    },
    {
      name: 'volume_precaire_kwhc_min',
      title: `${t('convention.tab_info_general.volume_precaire_min')} (GWhc)`,
      value: `${convertKiloToGiga(data.minimum_volume_precaire_kwhc)} GWhc`,
    },
    {
      name: 'percent_precaire_max',
      title: t('convention.tab_info_general.percent_gisement'),
      value: getPercent(data.minimum_volume_precaire_kwhc, 'min'),
    },
  ];

  const salePrices = [
    {
      name: 'volume_classique_kwhc_max',
      title: `${t('convention.tab_info_general.volume_classique_max')} (GWhc)`,
      value: `${convertKiloToGiga(data.volume_classique_kwhc)} GWhc`,
      icon: <VolumeGraphIcon />,
    },
    {
      name: 'pu_classique',
      title: t('contract.tab_info_general.pu_classique'),
      value: data.pu_classique
        ? `${convertKiloToMega(data.pu_classique)} €`
        : null,
    },
    {
      name: 'volume_precaire_kwhc_max',
      title: `${t('convention.tab_info_general.volume_precaire_max')} (GWhc)`,
      value: `${convertKiloToGiga(data.volume_precaire_kwhc)} GWhc`,
      icon: <VolumeGraphIcon />,
    },
    {
      name: 'pu_precaire',
      title: t('contract.tab_info_general.pu_precaire'),
      value: data.pu_precaire
        ? `${convertKiloToMega(data.pu_precaire)} €`
        : null,
    },
  ];

  const amountPenalty = [
    {
      name: 'amount_penalty_classique',
      title: t('contract.tab_info_general.amount_penalty_classique'),
      value: data.amount_penalty_classique
        ? `${convertKiloToMega(data.amount_penalty_classique)} €`
        : null,

      icon: <EuroIcon />,
    },
    {
      name: 'amount_penalty_precaire',
      title: t('contract.tab_info_general.amount_penalty_precaire'),
      value: data.amount_penalty_precaire
        ? `${convertKiloToMega(data.amount_penalty_precaire)} €`
        : // ? `${data.amount_penalty_precaire?.toLocaleString('fr')} €`
          null,
    },
  ];

  const minimumVolumeLot = [
    {
      name: 'lot_minimum_volume_kwhc',
      title: t('contract.tab_info_general.lot_minimum_volume_kwhc'),
      value: data.lot_minimum_volume_kwhc
        ? `${convertKiloToMega(data.lot_minimum_volume_kwhc)} GWhc`
        : null,

      icon: contractType.includes('mandant') ? <VolumeGraphIcon /> : undefined,
    },
  ];

  const cdpCharterInfos = [
    {
      name: 'has_cdp_charter_signatory',
      title: t('contract.cdp_charter_signed'),
      value: data.has_cdp_charter_signatory ? 'Oui' : 'Non',
    },
  ];

  const mandant: any[] =
    contractType === URL_TYPES.MANDANT_NON_DEPOSANT
      ? [
          [...basicInfos],
          [...cdpCharterInfos],
          [...basicMandant],
          [...datesInfos],
          [...volumesMax],
          [...volumesMin],
          [...minimumVolumeLot],
        ]
      : [
          [...basicInfos],
          [...cdpCharterInfos],
          [...basicMandant],
          [...datesInfos],
          [...volumesMax],
          [...volumesMin],
          [...amountPenalty],
        ];

  return contractType.includes('mandant')
    ? mandant
    : [
        [...basicInfos],
        [...cdpCharterInfos],
        [...datesInfos],
        [...salePrices],
        [...amountPenalty],
      ];
};

export const getSumVolumeOperationsContract = (
  contractsTransfers: ITransferType[]
) => {
  return {
    classique: contractsTransfers.reduce(
      (sum, cur) => sum + Number(cur?.gisement.classique || 0),
      0
    ),
    precaire: contractsTransfers.reduce(
      (sum, cur) => sum + Number(cur?.gisement.precaire || 0),
      0
    ),
  };
};

export const getVolumesRender = (
  wo: IWorksiteOperation,
  globalEnum: IGlobalEnumType
) => {
  const volumePrecaire = !wo.kwhc_precaire
    ? null
    : convertKiloToMega(wo.kwhc_precaire);
  const volumeClassique = !wo.kwhc_classique
    ? null
    : convertKiloToMega(wo.kwhc_classique);

  return (
    <div className="flex items-center">
      <div>
        {volumePrecaire && (
          <Tag
            color={purple}
            label={globalEnum.precarity[1]}
            addClass="h-fit px-[0.26rem] mb-1"
          />
        )}
        {volumeClassique && (
          <Tag
            color={lightBlue}
            label={globalEnum.precarity[2]}
            addClass="h-fit mr-[1rem]"
          />
        )}
      </div>
      <div className="flex flex-col">
        {volumePrecaire && (
          <span className="text-[1rem] font-medium min-w-max">
            {`${volumePrecaire} MWhc`}
          </span>
        )}
        {volumeClassique && (
          <span className="text-[1rem] font-medium min-w-max">
            {`${volumeClassique} MWhc`}
          </span>
        )}
      </div>
    </div>
  );
};
