import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  deleteOneLinkedFile,
  postLinkedFile,
  validateAfterWork,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import {
  defaultDocumentWithFileType,
  formAfterWork,
} from '@models/mar/utils/datas';
import { fileTypeEnum } from '@utils/enums';
import { setFileFromWorksiteData } from '@models/mar/utils/functions';
import { InputTypes } from '@utils/utils';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { borderGrey } from '@assets/color';
import { ILinkedFile } from '@globalTypes/globalTypes';
import LoaderFile from '@models/mar/components/LoaderFile';

function AfterWork() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  // Séparation des états de chargement pour les vidéos et les photos
  const [indexOfVideoLoading, setIndexOfVideoLoading] = useState<number>();
  const [indexOfPhotoLoading, setIndexOfPhotoLoading] = useState<number>();

  const { watch, setValue, control } = useFormContext();

  const { fields: videoFields, append: appendVideo } = useFieldArray({
    control,
    name: 'video_files',
  });

  const { fields: photoFields, append: appendPhoto } = useFieldArray({
    control,
    name: 'photo_files',
  });

  const finalVisitDate = watch('final_visit_date');
  const reportFile = watch('report_file');
  const reportDate = watch('report_date');
  const pvFile = watch('pv_file');
  const pvDate = watch('pv_date');

  const checkIfInfosAreFilled = finalVisitDate && reportFile && reportDate;

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      finalVisitDate,
      reportFile,
      reportDate,
      pvFile,
      pvDate,
    };
    const response = await validateAfterWork(worksiteData?.id || 0, dataToSend);

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.PAYMENT_LETTER);
    }
    setButtonNextLoading(false);
  };

  const preFillAfterWork = async () => {
    if (worksiteData) {
      if (
        worksiteData.linked_files &&
        worksiteData.linked_files.length > 0 &&
        videoFields &&
        videoFields.length === 0 &&
        photoFields &&
        photoFields.length === 0
      ) {
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.RAPPORT,
          'report_file',
          setValue
        );
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.PV_RECEPTION,
          'pv_file',
          setValue
        );
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.VIDEO_CONTROLE,
          'video_files',
          setValue,
          true,
          appendVideo
        );
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.PHOTO_CONTROLE,
          'photo_files',
          setValue,
          true,
          appendPhoto
        );
      }
    }
  };

  // Fonction generic d'upload de document
  const onUploadDocument = async (
    file: File,
    originalLinkedFile: ILinkedFile,
    index: number,
    type: 'video' | 'photo'
  ) => {
    if (type === 'video') {
      setIndexOfVideoLoading(index);
    } else {
      setIndexOfPhotoLoading(index);
    }
    const response = await postLinkedFile(
      file,
      originalLinkedFile,
      undefined,
      true
    );
    if (response) {
      setValue(`${type}_files[${index}].linkedFileId`, response.id);
    }
    if (type === 'video') {
      setIndexOfVideoLoading(undefined);
    } else {
      setIndexOfPhotoLoading(undefined);
    }
  };

  // Fonction generic de suppression de document
  const onDeleteDocument = async (
    linkedFileId: number,
    index: number,
    type: 'video' | 'photo'
  ) => {
    if (type === 'video') {
      setIndexOfVideoLoading(index);
    } else {
      setIndexOfPhotoLoading(index);
    }
    const response = await deleteOneLinkedFile(linkedFileId);
    if (response) {
      setValue(`${type}_files[${index}]`, {
        file: undefined,
        linkedFileId: undefined,
      });
    }
    if (type === 'video') {
      setIndexOfVideoLoading(undefined);
    } else {
      setIndexOfPhotoLoading(undefined);
    }
  };

  const defaultVideoControlDocument: ILinkedFile = defaultDocumentWithFileType(
    fileTypeEnum.VIDEO_CONTROLE,
    worksiteData?.id || 0
  );

  const defaultPhotoControlDocument: ILinkedFile = defaultDocumentWithFileType(
    fileTypeEnum.PHOTO_CONTROLE,
    worksiteData?.id || 0
  );

  useEffect(() => {
    updateButtonNextDisabled(!checkIfInfosAreFilled);
  }, [watch()]);

  useEffect(() => {
    preFillAfterWork();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {formAfterWork(t, worksiteData as IWorksiteMar).map((form) => (
          <OneMarForm key={form.title} dataForm={form} />
        ))}

        {/* Section Contrôle Vidéo */}
        <div className="flex flex-col space-y-4">
          <p className="font-medium text-[1.125rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.after_work.add_control_video.title'
            )}
          </p>
          {videoFields && videoFields.length > 0 ? (
            videoFields.map((field, index) => {
              if (indexOfVideoLoading === index) {
                return (
                  <LoaderFile key={`loader-file-${field.id}`} withoutLabel />
                );
              }
              return (
                <OneMarForm
                  key={field.id}
                  dataForm={{
                    fields: [
                      {
                        name: `video_files[${index}].file`,
                        type: InputTypes.DOCUMENT,
                        fileType: fileTypeEnum.VIDEO_CONTROLE,
                        fullWidth: true,
                        defaultDocument: defaultVideoControlDocument,
                        linkedFileId: watch(
                          `video_files[${index}].linkedFileId`
                        ),
                        onUploadDocument: (
                          file: File,
                          originalLinkedFile: ILinkedFile
                        ) =>
                          onUploadDocument(
                            file,
                            originalLinkedFile,
                            index,
                            'video'
                          ),
                        onDeleteDocument: (linkedFileId: number) =>
                          onDeleteDocument(linkedFileId, index, 'video'),
                      },
                    ],
                  }}
                />
              );
            })
          ) : (
            <div className="flex flex-col space-y-4">
              <LoaderDivSkeleton
                height="2rem"
                width="100%"
                backgroundColor={borderGrey}
              />
              <LoaderDivSkeleton
                height="2.5rem"
                width="100%"
                backgroundColor={borderGrey}
              />
            </div>
          )}
        </div>
        {/* Bouton Ajouter Vidéo */}
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.after_work.add_control_video.add_video'
          )}`}
          onClick={() =>
            appendVideo({
              file: undefined,
              linkedFileId: undefined,
            })
          }
          type="tierciary"
          disabled={indexOfVideoLoading !== undefined}
        />

        {/* Section Contrôle Photo */}
        <div className="flex flex-col space-y-4">
          <p className="font-medium text-[1.125rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.after_work.add_control_photo.title'
            )}
          </p>
          {photoFields && photoFields.length > 0 ? (
            photoFields.map((field, index) => {
              if (indexOfPhotoLoading === index) {
                return (
                  <LoaderFile key={`loader-photo-${field.id}`} withoutLabel />
                );
              }
              return (
                <OneMarForm
                  key={field.id}
                  dataForm={{
                    fields: [
                      {
                        name: `photo_files[${index}].file`,
                        type: InputTypes.DOCUMENT,
                        fileType: fileTypeEnum.PHOTO_CONTROLE,
                        fullWidth: true,
                        defaultDocument: defaultPhotoControlDocument,
                        linkedFileId: watch(
                          `photo_files[${index}].linkedFileId`
                        ),
                        onUploadDocument: (
                          file: File,
                          originalLinkedFile: ILinkedFile
                        ) =>
                          onUploadDocument(
                            file,
                            originalLinkedFile,
                            index,
                            'photo'
                          ),
                        onDeleteDocument: (linkedFileId: number) =>
                          onDeleteDocument(linkedFileId, index, 'photo'),
                      },
                    ],
                  }}
                />
              );
            })
          ) : (
            <div className="flex flex-col space-y-4">
              <LoaderDivSkeleton
                height="2rem"
                width="100%"
                backgroundColor={borderGrey}
              />
              <LoaderDivSkeleton
                height="2.5rem"
                width="100%"
                backgroundColor={borderGrey}
              />
            </div>
          )}
        </div>

        {/* Bouton Ajouter Photo */}
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.after_work.add_control_photo.add_photo'
          )}`}
          onClick={() =>
            appendPhoto({
              file: undefined,
              linkedFileId: undefined,
            })
          }
          type="tierciary"
          disabled={indexOfPhotoLoading !== undefined}
        />
      </div>
    </LayoutMarDetailsStep>
  );
}

export default AfterWork;
