import { cloneElement, useContext, useState } from 'react';
import { getIconAndCharacSize } from '@utils/utils';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { blueOpx, white } from '@assets/color';
import { convertHexToRGBA } from '@utils/functions';
import { ThemeContext } from '@context/ThemeContext';

type IColorCubeProps = {
  size: '1rem' | '1.5rem' | '2rem' | '2.5rem';
  numberOrIcon: number | JSX.Element;
  color: string;
  backgroundColor?: string;
  onClick?: () => void;
  opacity?: boolean;
  borderRadius?: string;
  addClass?: string;
  dataTestId?: string;
  hover?: boolean;
  counter?: number;
};

// opacity est à true si on souhaite une couleur de background qui correspond à la couleur de l'élément central avec une opacité de 10%
// Si opacity est à false, la couleur de l'élément central sera toujours blanche et le background de la couleur passée en props
function ColorCube({
  numberOrIcon,
  color,
  size,
  backgroundColor,
  onClick,
  opacity,
  borderRadius,
  addClass,
  dataTestId,
  hover,
  counter,
}: IColorCubeProps): JSX.Element {
  const { themeData } = useContext(ThemeContext);

  const secondaryThemeData = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );

  const iconAndCharacSize = getIconAndCharacSize(size);
  const iconSize = iconAndCharacSize.icon;
  const characSize = iconAndCharacSize.charac;
  const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);

  const secondaryTextColor = secondaryThemeData?.text_color || white;
  const secondaryBackgroundColor =
    color || secondaryThemeData?.background_color;

  const opacityColor = secondaryThemeData
    ? convertHexToRGBA(secondaryThemeData.background_color, 0.4)
    : convertHexToRGBA(color, 0.1);

  const colorIsBlueOpx = color === blueOpx;
  const fillColor = colorIsBlueOpx
    ? secondaryThemeData?.text_color || color
    : color;

  const iconWithProps =
    typeof numberOrIcon !== 'number'
      ? cloneElement(numberOrIcon as JSX.Element, {
          fill:
            (opacity && !mouseIsOver) || backgroundColor
              ? fillColor
              : secondaryTextColor,
          width: iconSize,
          height: iconSize,
        })
      : '';

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onMouseOver={() => hover && setMouseIsOver(true)}
      onFocus={() => hover && setMouseIsOver(true)}
      onMouseLeave={() => hover && setMouseIsOver(false)}
      style={{
        width: size,
        minWidth: size,
        height: size,
        minHeight: size,
        backgroundColor:
          backgroundColor ||
          (opacity && !mouseIsOver ? opacityColor : secondaryBackgroundColor),
        color:
          (opacity && !mouseIsOver) || backgroundColor
            ? fillColor
            : secondaryTextColor,
        borderRadius,
      }}
      className={[
        `relative flex items-center justify-center ${
          onClick ? 'cursor-pointer' : ''
        } ${characSize}`,
        addClass,
      ].join(' ')}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      data-test-id={dataTestId}
    >
      {typeof numberOrIcon === 'number' ? numberOrIcon : iconWithProps}
      {counter && (
        <div
          className="absolute top-0 right-0 flex items-center justify-center text-[0.75rem] bg-[#FF0000] rounded-default p-[.125rem] min-w-[1.125rem]"
          style={{ transform: 'translate(50%,-50%)' }}
        >
          {counter}
        </div>
      )}
    </div>
  );
}

export { ColorCube };

ColorCube.defaultProps = {
  onClick: null,
  backgroundColor: null,
  opacity: false,
  borderRadius: '4px',
  addClass: '',
  dataTestId: '',
  hover: false,
  counter: undefined,
};
