import { useContext } from 'react';
import { IUserAccessItem, IUserRight } from '@models/users/utils/userTypes';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { Toggle } from '@components/atomic/inputs/controls/Toggle';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';
import { v4 } from 'uuid';
import { ACCESS_TYPE_ENUM } from '../utils/userConstants';

interface IAccessSectionProps {
  loadingAccess: boolean;
  access?: IUserRight;
  selectedAccessList: IUserAccessItem[];
  setSelectedAccessList: React.Dispatch<
    React.SetStateAction<IUserAccessItem[]>
  >;
  dataTestId: string;
}

function AccessSection({
  loadingAccess,
  access,
  selectedAccessList,
  setSelectedAccessList,
  dataTestId,
}: IAccessSectionProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);

  const isAccessOneDisabled = selectedAccessList.some(
    (itemAccess: IUserAccessItem) =>
      itemAccess.access === ACCESS_TYPE_ENUM.WORKSITES_MANAGEMENT &&
      itemAccess.toggle === false
  );

  return (
    <div className="mb-8 border border-borderGrey rounded-default p-6">
      <p className="font-[500] text-[1.250rem] leading-[2rem] pb-[.25rem]">
        <span>{t('my_account.access_management')}</span>
      </p>
      <p className="text-[.875rem] leading-[1.250rem] mb-8">
        {t('my_account.add_access_description')}
      </p>

      {loadingAccess ? (
        <div className="flex flex-wrap items-center my-3 py-3">
          <LoaderSkeleton
            height="2.5rem"
            addClass="w-full md:w-[25%] md:mr-[0.5rem]"
          />
        </div>
      ) : (
        (access ? access.access_list : []).map(
          (accessItem: IUserAccessItem, index) => {
            const isToggleOn = Boolean(
              selectedAccessList.find(
                (itemAccess: IUserAccessItem) =>
                  itemAccess.access === accessItem.access
              )?.toggle
            );

            const isDisabled =
              accessItem.access ===
                ACCESS_TYPE_ENUM.WORKSITE_REFERENT_MANAGEMENT &&
              isAccessOneDisabled;

            return (
              <div key={v4()} className="relative">
                {isDisabled && (
                  <div className="absolute inset-0 bg-white opacity-50 z-[100] cursor-not-allowed" />
                )}
                <button
                  type="button"
                  data-test-id={`${dataTestId}_${index}`}
                  className="w-full flex justify-between items-center border border-borderGrey rounded-default p-2 mb-2"
                  onClick={() => {
                    if (isDisabled) return;

                    const selectedAccessIndex = selectedAccessList.findIndex(
                      (itemAccess: IUserAccessItem) =>
                        itemAccess.access === accessItem.access
                    );
                    const tmpAccesses = [...selectedAccessList];
                    tmpAccesses[selectedAccessIndex] = {
                      ...tmpAccesses[selectedAccessIndex],
                      toggle: !isToggleOn,
                    };

                    if (
                      accessItem.access === 1 &&
                      !tmpAccesses[selectedAccessIndex].toggle
                    ) {
                      const access6Index = tmpAccesses.findIndex(
                        (itemAccess: IUserAccessItem) => itemAccess.access === 6
                      );
                      if (access6Index !== -1) {
                        tmpAccesses[access6Index] = {
                          ...tmpAccesses[access6Index],
                          toggle: false,
                        };
                      }
                    }

                    setSelectedAccessList(tmpAccesses);
                  }}
                >
                  <p>{globalEnum.access_type[accessItem.access]}</p>
                  <Toggle
                    label={globalEnum.access_type[accessItem.access]}
                    isToggleOn={isToggleOn}
                    onToggle={() => null}
                  />
                </button>
              </div>
            );
          }
        )
      )}
    </div>
  );
}

AccessSection.defaultProps = {
  access: undefined,
};

export default AccessSection;
