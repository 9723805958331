import { black, white } from '@assets/color';
import { AddFatIcon, ChevronLeftIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ColorCube } from '@components/atomic/ColorCube';
import {
  navLinksSidebarBottom,
  navLinksSidebar,
} from '@components/navigation/NavLinks';
import { LinkButton } from '@components/navigation/sidebar/LinkButton';
import { SwitchRoleButton } from '@components/navigation/sidebar/SwitchRoleAndEntity/SwitchRoleButton';
import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';
import { convertHexToRGBA } from '@utils/functions';
import { ROLES } from '@utils/roles';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

interface SidebarExampleProps {
  mainMenuColor: string;
  mainMenuTextColor: 'white' | 'black';
  newWorksiteButtonColor: string;
  newWorksiteButtonTextColor: 'white' | 'black';
}

function SidebarExample({
  mainMenuColor,
  mainMenuTextColor,
  newWorksiteButtonColor,
  newWorksiteButtonTextColor,
}: SidebarExampleProps): JSX.Element {
  const { roleUser, userView } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <div
      className="w-min pb-6"
      style={{ backgroundColor: mainMenuColor, color: mainMenuTextColor }}
    >
      <div className="flex justify-between items-center p-6 border-b border-b-[#EEEEEE10]">
        <div
          className="w-20 h-8 flex items-center justify-center"
          style={{
            backgroundColor:
              mainMenuTextColor === 'white'
                ? convertHexToRGBA(black, 0.4)
                : convertHexToRGBA(white, 0.4),
            color: mainMenuTextColor,
          }}
        >
          Logo
        </div>
        <ColorCube
          size="1.5rem"
          numberOrIcon={<ChevronLeftIcon />}
          color={mainMenuTextColor}
          backgroundColor={convertHexToRGBA(
            mainMenuTextColor === 'white' ? black : white,
            0.4
          )}
        />
      </div>
      <div className="space-y-6">
        <div className="px-6 space-y-6">
          <SwitchRoleButton isExample textColorWhiteLabel={mainMenuTextColor} />
          <ButtonOpx
            icon={<AddFatIcon />}
            label={t('worksites.new_work_site')}
            color={newWorksiteButtonTextColor}
            backgroundColor={newWorksiteButtonColor}
            addClass="w-full"
          />
        </div>
        <div className="px-6">
          <div className="w-full flex flex-col space-y-[1rem] border-b border-b-[#EEEEEE40] pb-[1.5rem]">
            {navLinksSidebar(t)
              .filter((navLinkFiltered) =>
                navLinkFiltered.roles
                  ? navLinkFiltered.roles.includes(roleUser)
                  : true
              )
              .filter((navLinkFilteredByEntity) =>
                navLinkFilteredByEntity.allowed_entities
                  ? navLinkFilteredByEntity.allowed_entities.includes(
                      userView?.entity_type || 0
                    )
                  : true
              )
              .filter((navLink) => {
                if (navLink.roles?.includes(ROLES.MAR)) {
                  return true;
                }
                return !(
                  navLink.name?.includes(t('sidebar.worksites')) &&
                  !user?.permissions_names.includes('gerer-chantier')
                );
              })
              .map((navLink) => (
                <div key={v4()}>
                  <LinkButton
                    navLink={navLink}
                    isExample
                    textColorWhiteLabel={mainMenuTextColor}
                  />
                </div>
              ))}
          </div>
          <div className="w-full pt-[2rem] flex flex-col space-y-[1rem]">
            {navLinksSidebarBottom(t).map((navLink) => (
              <div key={v4()}>
                <LinkButton
                  navLink={navLink}
                  isExample
                  textColorWhiteLabel={mainMenuTextColor}
                />
              </div>
            ))}
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarExample;
