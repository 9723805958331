import { useState, Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { blueOpx, darkBlue } from '@assets/color';
import { IElementData, IImageData } from '@models/settings/utils/settingsTypes';
import { ThemeContext } from '@context/ThemeContext';
import {
  THEME_ELEMENTS_TYPES,
  THEME_IMAGE_TYPES,
} from '@models/settings/utils/enums';
import ColorPicker from './ColorPicker';
import SidebarExample from './SidebarExample';
import ImageCustomizer from './ImageCustomizer';

interface MainMenuElementsProps {
  onUpdateElement: Dispatch<SetStateAction<IElementData[]>>;
  onUpdateImage: Dispatch<SetStateAction<IImageData[]>>;
}

function MainMenuElements({
  onUpdateElement,
  onUpdateImage,
}: MainMenuElementsProps) {
  const { t } = useTranslation();
  const { themeData } = useContext(ThemeContext);

  const logoMenu = themeData?.images.find(
    (image) => image.image === THEME_IMAGE_TYPES.LOGO_MENU
  );

  const mainThemeMenu = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.MENU
  );

  const newWorksiteButtonTheme = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.MENU_BUTTON
  );

  // États locaux pour gérer les couleurs du menu principal et des boutons
  const [mainMenuColor, setMainMenuColor] = useState(
    mainThemeMenu?.background_color || darkBlue
  );
  const [mainMenuTextColor, setMainMenuTextColor] = useState<'white' | 'black'>(
    mainThemeMenu?.text_color || 'white'
  );

  const [newWorksiteButtonColor, setNewWorksiteButtonColor] = useState(
    newWorksiteButtonTheme?.background_color || blueOpx
  );
  const [newWorksiteButtonTextColor, setNewWorksiteButtonTextColor] = useState<
    'white' | 'black'
  >(newWorksiteButtonTheme?.text_color || 'white');

  return (
    <div className="p-6 space-y-8">
      <div className="flex justify-between">
        <p className="font-medium">
          {t('settings.white_label.main_menu_elements')}
        </p>
      </div>
      <ImageCustomizer
        name="logo_menu"
        title={t('settings.white_label.logo')}
        aspectRatio={106 / 32}
        initialData={
          logoMenu
            ? {
                src: logoMenu.initial,
                customizedSrc: logoMenu.custom,
                brightness: logoMenu.brightness,
                contrast: logoMenu.contrast,
                saturation: logoMenu.saturation,
                crop: {
                  x: logoMenu.crop.x,
                  y: logoMenu.crop.y,
                },
                zoom: logoMenu.crop.zoom,
                croppedAreaPixels: logoMenu.croppedAreaPixels,
              }
            : undefined
        }
        onUpdateImage={onUpdateImage}
      />
      <div className="flex space-x-[8.75rem]">
        <div className="space-y-8">
          <ColorPicker
            name="menu"
            label={t('settings.white_label.background')}
            initialColor={mainMenuColor}
            initialTextColor={mainMenuTextColor}
            setParentColor={setMainMenuColor}
            setParentTextColor={setMainMenuTextColor}
            onUpdateElement={onUpdateElement}
          />
          <ColorPicker
            name="menu_button"
            label={t('settings.white_label.new_worksite_button')}
            initialColor={newWorksiteButtonColor}
            initialTextColor={newWorksiteButtonTextColor}
            setParentColor={setNewWorksiteButtonColor}
            setParentTextColor={setNewWorksiteButtonTextColor}
            onUpdateElement={onUpdateElement}
          />
        </div>
        <div className="flex flex-col flex-grow space-y-6">
          <SidebarExample
            mainMenuColor={mainMenuColor}
            mainMenuTextColor={mainMenuTextColor}
            newWorksiteButtonColor={newWorksiteButtonColor}
            newWorksiteButtonTextColor={newWorksiteButtonTextColor}
          />
        </div>
      </div>
    </div>
  );
}

export default MainMenuElements;
