import { InputText } from '@components/atomic/inputs/InputText';
import { AuthLayout } from '@pages/auth/AuthLayout';

import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { getCollabRegistrationInfoSchema } from '@utils/validationSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  completeCollabDetails,
  invitationInfo,
} from '@models/auth/apiRequests/registrationRequests';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { INVITATION_STATE } from '@utils/utils';
import { AccountCompleted } from '@models/auth/components/registrationSteps/AccountCompleted';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ILogin } from '@models/auth/utils/types';
import { AUTH_ROUTES } from '@utils/routesUrls';

// via invitation de l'écran Ajouter utilisateur
function InternalRegistration() {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const inviteId = query.get('invite-id');
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNotFound, setPageNotFound] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [loginInfo, setLoginInfo] = useState<ILogin>();
  const [descLoading, setDescLoading] = useState<boolean>(!!inviteId);
  const methods = useForm({
    resolver: yupResolver(getCollabRegistrationInfoSchema(t)),
  });
  const { handleSubmit, setValue } = methods;
  const [companyName, setCompanyName] = useState<string>('');
  const invitationStatus = INVITATION_STATE(t);

  const onSubmit = async (formDatas: FieldValues) => {
    setLoading(true);

    await completeCollabDetails({
      uuid: inviteId,
      lastname: formDatas.lastname,
      firstname: formDatas.firstname,
      phone_number: formDatas.phone_number,
      function: formDatas.function,
      password: formDatas.password,
    });

    setLoginInfo({
      email: userEmail,
      password: formDatas.password,
    });
    setIsCompleted(true);
    setLoading(false);
  };

  useEffect(() => {
    if (inviteId) {
      const getInfo = async () => {
        const res = await invitationInfo(inviteId || '');

        if (res) {
          setUserEmail(res.recipient);
          setUsername(res.recipient_fullname);
          if (res.status === invitationStatus.DONE) {
            setIsCompleted(true);
          }
          setCompanyName(res.company_name);
        } else setPageNotFound(true);

        setDescLoading(false);
        setValue('phone_number', '');
      };

      getInfo();
    } else setPageNotFound(true);
  }, [inviteId, username]);

  const detailsToComplete = () => (
    <div className="lg:w-[35rem]">
      <FormProvider {...methods}>
        <form
          id="form-personal-infos"
          className="flex flex-col gap-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="mb-2 text-[1.6875rem] font-medium leading-[2.5rem]">
            {t('auth.registration.welcome_message_internal')}
          </p>
          <p className="font-normal text-black text-[.875rem]">
            {t('auth.registration.create_account_message_via_link_one')}{' '}
            <span style={{ fontWeight: 'bold' }}>{companyName}</span>
            {t('auth.registration.create_account_message_via_link_two')}
          </p>
          <div className="flex flex-col items-start space-y-4 w-full mb-4 mt-4">
            <div className="flex gap-4 self-stretch">
              <InputText
                label={t('auth.registration.forms.firstname') || ''}
                id="firstname"
                name="firstname"
                placeholder={t('auth.registration.personal_info.placeholder')}
                required
                data-test-id="firstname"
              />
              <InputText
                label={t('auth.registration.forms.lastname') || ''}
                id="lastname"
                name="lastname"
                placeholder={t('auth.registration.personal_info.placeholder')}
                required
                data-test-id="lastname"
              />
            </div>
            <div className="w-full">
              <InputText
                label={t('auth.registration.forms.function') || ''}
                id="function"
                name="function"
                placeholder={t('auth.registration.personal_info.placeholder')}
                required
                data-test-id="function"
              />
            </div>
            <div className="flex gap-4 self-stretch">
              <InputText
                label={t('auth.registration.forms.phone_number') || ''}
                id="phone_number"
                name="phone_number"
                placeholder={t('auth.registration.personal_info.placeholder')}
                required
                dataTestId="phone_number"
                type="tel"
                typeNumber
              />
            </div>
            <div className="flex flex-col items-start space-y-4 w-full">
              <p className="text-[1rem] font-medium text-black">
                {t('auth.registration.forms.header_password')}
              </p>
              <div className="flex gap-4 self-stretch">
                <div className="flex flex-col flex-1">
                  <InputText
                    label={t('auth.registration.forms.password') || ''}
                    id="password"
                    name="password"
                    placeholder={t('forms.password.new_pwd')}
                    required
                    type="password"
                    dataTestId="new_password"
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <InputText
                    label={t('auth.registration.forms.confirm_password') || ''}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder={t('forms.password.confirm_pwd')}
                    required
                    type="password"
                    dataTestId="confirm_password"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-[1.5rem] mt-3">
            <ButtonOpx
              label={t('auth.registration.start')}
              isLoading={loading}
              disabled={loading}
              formId="form-personal-infos"
              isSubmitBtn
              dataTestId="registration_button"
              addClass="w-full"
              onClick={() => onSubmit}
            />
          </div>

          <p className="text-center">
            {t('auth.registration.already_registered.got_account')}{' '}
            <Link to={AUTH_ROUTES.LOGIN} className="text-primaryText">
              {t('auth.registration.tooltip')}
            </Link>
          </p>
        </form>
      </FormProvider>
    </div>
  );

  const checkIsCompleted = () => {
    if (!descLoading && isCompleted) {
      return <AccountCompleted loginInfo={loginInfo} username={username} />;
    }

    return (
      <div className="w-[30rem]">
        <LoaderSkeleton height="5rem" addClass="w-full" />
      </div>
    );
  };

  return (
    <AuthLayout>
      {pageNotFound ? (
        <div>
          <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
            {t('global.page_not_found.header')}
            <br />
            {t('global.contact_support_team')}{' '}
          </p>
        </div>
      ) : (
        <div className="w-full">
          {!descLoading && !isCompleted
            ? detailsToComplete()
            : checkIsCompleted()}
        </div>
      )}
    </AuthLayout>
  );
}

export { InternalRegistration };
