import { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '@context/authContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HeaderContext } from '@context/headerContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { WorksitesListMarContext } from '@models/mar/utils/worksitesListMarContext';
import { getWorksitesListMar } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { SubHeader } from '@components/subHeader/SubHeader';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AddFatIcon } from '@assets/images/svgComponents';
import { MAR_ROUTES } from '@utils/routesUrls';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { blueOpx } from '@assets/color';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import WorksiteMarCardInList from '@models/mar/components/list/WorksiteMarCardInList';
import { v4 } from 'uuid';
import { LoaderWorksitesList } from '@components/loaders/worksites/LoaderWorksitesList';

function WorksitesListMar(): JSX.Element {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { refreshHeader, updateTitleHeader } = useContext(HeaderContext);

  const [worksitesList, setWorksitesList] = useState<IWorksiteMar[]>();
  const [isLoadingList, setIsLoadingList] = useState<boolean>(true);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [resultsPerPage, setResultsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);
  /* const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>({ filters: [] }); */

  const sortAndFilterData = { filters: [] };

  const getList = async (page: number) => {
    setIsLoadingList(true);
    const payload = {
      pagination: {
        page,
        perPage: resultsPerPage,
      },
      sort_and_filter: sortAndFilterData,
    };
    const response = await getWorksitesListMar(payload);
    if (response) {
      setWorksitesList(response.data);
      setPaginationData({
        current_page: response.meta.current_page,
        last_page: response.meta.last_page,
        total: response.meta.total,
      });
      if (response.meta.current_page > pagesDatas.length) {
        setPagesDatas([
          ...pagesDatas,
          {
            data: response.data,
            meta: {
              current_page: response.meta.current_page,
              last_page: response.meta.last_page,
              total: response.meta.total,
            },
          },
        ]);
      }
      setIsLoadingList(false);
    }
  };

  const pageAlreadyLoad = (page: number) => {
    if (pagesDatas[page - 1]) {
      setWorksitesList(pagesDatas[page - 1].data);
      setPaginationData(pagesDatas[page - 1].meta);
    } else {
      getList(page);
    }
  };

  const worksitesListMarContextValue = useMemo(
    () => ({
      worksitesList,
    }),
    [worksitesList]
  );

  const cantAccessWorksitesMar = useMemo(
    () => !user?.permissions_names.includes('creer-chantier-mar'),
    [user]
  );

  const rightPartSubHeader: JSX.Element = (
    <>
      {/* <SortAndFilter
        page={adminMode ? 'ADMIN_CHANTIERS_MAR' :  'CHANTIERS_MAR'}
        onSort={(column, direction) =>
          sortList(column, direction, setSortAndFilterData)
        }
        onFilter={(filters) => filterList(filters, setSortAndFilterData)}
      /> */}
      {user?.permissions_names.includes('creer-chantier-mar') && (
        <ButtonOpx
          label={t('mar.new_project')}
          icon={<AddFatIcon />}
          type="primary"
          onClick={() => {
            navigate(MAR_ROUTES.WORKSITES_NEW);
          }}
          dataTestId="new_worksite"
        />
      )}
    </>
  );

  useEffect(() => {
    if (resultsPerPage) {
      setPagesDatas([]);
      setCurrentPage(1);
      getList(1);
    }
  }, [resultsPerPage]);

  useEffect(() => {
    if (user && cantAccessWorksitesMar) {
      toast(t('global.no_permission'), {
        type: 'error',
      });
      navigate('/', { replace: true });
    }
    updateTitleHeader(`${t('sidebar.projects')}`);
    refreshHeader();

    getList(1);
  }, []);

  return (
    <WorksitesListMarContext.Provider value={worksitesListMarContextValue}>
      <div>
        <SubHeader leftPart={<div />} rightPart={rightPartSubHeader} />
        <div className="flex justify-between">
          <div className="mt-4">
            {paginationData?.total !== undefined && (
              <div>
                {t('pagination.total_results')} : {paginationData.total}
              </div>
            )}
          </div>
          <div className="mb-4">
            <ResultsPerPageButton
              resultsPerPage={resultsPerPage}
              onChange={(value) => {
                setResultsPerPage(value);
              }}
              options={[5, 10, 20, 50, 100, 250]}
              colorPagination={blueOpx}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          {isLoadingList ? (
            <div className="w-full overflow-y-scroll flex-col flex space-y-[1.5rem]">
              {[...Array(4)].map(() => (
                <div key={v4()}>
                  <LoaderWorksitesList />
                </div>
              ))}
            </div>
          ) : (
            worksitesList?.map((worksite) => (
              <WorksiteMarCardInList
                key={`worksite-card-${worksite.id}`}
                worksiteData={worksite}
              />
            ))
          )}
        </div>
        <ListPagination
          getData={(page) => {
            setCurrentPage(page);
            if (page > pagesDatas.length) {
              getList(page);
            } else {
              pageAlreadyLoad(page);
            }
          }}
          paginationData={paginationData}
          resultsPerPage={resultsPerPage}
          currentPage={currentPage}
        />
      </div>
    </WorksitesListMarContext.Provider>
  );
}

export default WorksitesListMar;
