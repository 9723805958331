import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { axiosClient } from '@api/apiClient';
import {
  IAidesData,
  IProjectedEnd,
  IWorksiteDetails,
  IWorksiteReferentType,
  IWorksiteReferentUpdate,
  SetOperationAccessibleType,
} from '@models/worksites/utils/worksitesTypes';
import {
  urlDownloadWorksiteDocuments,
  urlDownloadWorksitesExportVue,
  urlGetWorksiteDetails,
  urlGetWorksitesList,
  urlPostProjectedEnd,
  urlPostSubcontractorObjectionNotification,
  urlPutOperationAccessible,
  urlUpdateWorksiteReferent,
  urlPostAhOperationData,
  urlSaveWorksiteCustomFields,
  urlGetAhGeneralData,
  urlEditAhOperationData,
  urlEditAhGeneralData,
  urlUpdateBeneficiaryAmount,
  urlPostWorksiteUpdatePrimes,
  urlUpdateWorksitePrimes,
  urlAdminGetWorksitesList,
} from '@api/apiUrls';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { displayError, formatWord } from '@utils/format';
import { t } from 'i18next';
import { saveAs } from 'file-saver';
import { getDate } from '@utils/functions';

// RECUPERE LA LISTE DES CHANTIERS
export const getWorksitesList = async (
  updateWorksitesListDatas: Dispatch<SetStateAction<IWorksiteDetails[]>>,
  setIsLoadingList: Dispatch<SetStateAction<boolean>>,
  numberPageList: number,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  worksitesListDatas?: IWorksiteDetails[],
  status?: number | null,
  sortAndFilter?: ISortAndFilterType,
  adminMode?: boolean,
  createdBy?: number | null,
  resultsPerPage = 5
) => {
  try {
    setIsLoadingList(numberPageList === 1);
    const data = {
      pagination: {
        page: numberPageList,
        perPage: resultsPerPage,
      },
      status,
      sort_and_filter: sortAndFilter,
      created_by: createdBy,
    };
    const response = await axiosClient.post(
      adminMode ? urlAdminGetWorksitesList : urlGetWorksitesList,
      data
    );
    updateWorksitesListDatas(response.data.data);
    setIsLoadingList(false);
    if (setIsLoading) {
      setIsLoading(false);
    }
    return response.data;
  } catch (error) {
    displayError(error.response);
    return null;
  }
};

// RECUPERE LE DETAIL D'UN CHANTIER
export const getWorksiteDetails = async (
  updateWorksiteDetails: Dispatch<SetStateAction<IWorksiteDetails>>,
  worksiteId: string,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  updateTitleHeader?: Dispatch<SetStateAction<string>>
) => {
  try {
    const response = await axiosClient.get(urlGetWorksiteDetails(worksiteId));
    const data = response.data.data;

    const worksiteName = data.site_name ? data.site_name : undefined;
    const lastName = data.beneficiary.lastname;
    const firstName = data.beneficiary.firstname;
    const companyName = data.beneficiary.company_name;
    updateWorksiteDetails(data);
    if (updateTitleHeader) {
      if (worksiteName) {
        updateTitleHeader(worksiteName); // Mise à jour avec le nom du chantier si disponible
      } else if (companyName) {
        updateTitleHeader(`${companyName}`); // Sinon, utilisation de companyName si disponible
      } else {
        updateTitleHeader(`${firstName} ${lastName}`); // Fallback sur le nom et prénom
      }
    }
    if (setIsLoading) {
      setIsLoading(false);
    }
    return response;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
      return false;
    }
    return 'duplicate';
  }
};

// Change opération status to CORRIGEE
export const setOperationAccessible = async (
  data: SetOperationAccessibleType
) => {
  let key:
    | 'cofrac_lots_operations.id'
    | 'deposits_operations.id'
    | 'contracts_lots_operations.id' = 'cofrac_lots_operations.id';

  if (data['deposits_operations.id']) key = 'deposits_operations.id';
  if (data['contracts_lots_operations.id'])
    key = 'contracts_lots_operations.id';

  try {
    const response = await axiosClient.put(urlPutOperationAccessible, {
      [key]: data[key],
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeProjectedEnd = async (
  worksiteId: number,
  datas: IProjectedEnd[]
) => {
  try {
    const response = await axiosClient.post(urlPostProjectedEnd(worksiteId), {
      worksite_operations: datas,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const sendSubcontractorObjectionNotification = async (
  worksiteId: number,
  subcontractorId: number
) => {
  try {
    const response = await axiosClient.post(
      urlPostSubcontractorObjectionNotification(worksiteId, subcontractorId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const downloadWorksiteDocuments = async (
  worksiteId: number,
  fileName: string,
  setIsDownloaded: Dispatch<SetStateAction<boolean>>,
  setDescLoading: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const response = await axiosClient.get(
      urlDownloadWorksiteDocuments(worksiteId, fileName),
      {
        responseType: 'arraybuffer',
      }
    );

    if (response.data !== undefined) {
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      setIsDownloaded(true);

      saveAs(blob, fileName);
      setDescLoading(false);
    }
  } catch (error) {
    setIsDownloaded(false);
    setDescLoading(false);
    displayError(error.response);
  }
};

export const exportVue = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  companyName: string,
  tabActive: string,
  status?: number | null,
  sortAndFilter?: ISortAndFilterType
) => {
  setLoading(true);
  try {
    const data = {
      status,
      sort_and_filter: sortAndFilter,
      pagination: { page: 1, perPage: 100 },
    };

    const response = await axiosClient.post(
      urlDownloadWorksitesExportVue,
      data,
      {
        responseType: 'arraybuffer',
      }
    );

    if (response.data) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const statusName = status
        ? formatWord(tabActive).replace(' ', '_')
        : 'tous';

      const date = getDate().replace('/', '_');

      saveAs(blob, `${companyName}_${statusName}_${date}.xlsx`);
    }

    setLoading(false);

    return response.data;
  } catch (error) {
    setLoading(false);
    displayError(error.response);
    return false;
  }
};

export const updateWorksiteReferent = async (
  data: IWorksiteReferentUpdate,
  setShowContactForm: Dispatch<SetStateAction<boolean>>,
  setReferent: Dispatch<SetStateAction<IWorksiteReferentType | null>>
) => {
  try {
    const response = await axiosClient.post(urlUpdateWorksiteReferent, data);
    toast.success(t('worksites.referent_update_success'));
    setShowContactForm(false);
    setReferent(response.data.data);
  } catch (error) {
    displayError(error.response);
  }
};

// Données AH
export const fetchAhGeneralData = async (worksiteId: number) => {
  try {
    const response = await axiosClient.get(urlGetAhGeneralData(worksiteId));
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const fetchAhOperationData = async (
  worksiteOperationId: number,
  codeOperation: string
) => {
  try {
    const response = await axiosClient.post(
      urlPostAhOperationData(worksiteOperationId),
      { codeOperation }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

// Données AH à éditer
export const editAhGeneralData = async (
  worksiteId: number,
  toUpdate: Record<string, any>,
  aidesData: Record<string, any>
) => {
  try {
    const response = await axiosClient.post(urlEditAhGeneralData(worksiteId), {
      toUpdate,
      aidesData,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const editAhOperationData = async (
  worksiteOperationId: number,
  codeOperation: string,
  toUpdate: Record<string, any>[],
  aidesData: Record<string, any>
) => {
  try {
    const response = await axiosClient.post(
      urlEditAhOperationData(worksiteOperationId),
      { codeOperation, toUpdate, aidesData }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeCustomFields = async (
  worksiteId: number,
  customFields: any
) => {
  try {
    const response = await axiosClient.post(
      urlSaveWorksiteCustomFields(String(worksiteId)),
      customFields
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const postWorksiteUpdateAmounts = async (
  worksiteId: string,
  data: IAidesData
) => {
  try {
    const response = await axiosClient.post(
      urlPostWorksiteUpdatePrimes(worksiteId),
      data
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const updateWorksitePrimes = async (
  worksiteId: string,
  aidesData: { [x: string]: string | number },
  beneficiaryData?: { [x: string]: string | number } | null
) => {
  try {
    const response = await axiosClient.put(
      urlUpdateWorksitePrimes(worksiteId),
      { aidesData, beneficiaryData }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const updateBeneficiaryAmount = async (
  worksiteId: number,
  amount: number
) => {
  try {
    const response = await axiosClient.put(
      urlUpdateBeneficiaryAmount(worksiteId),
      { amount }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};
