import { t } from 'i18next';

function MultipleEntityProfilsSelected() {
  return (
    <div className="max-w-96 text-2xl leading-10">
      {t('auth.registration.entity_profil.exceptional_situation_1')} <br />
      {t('auth.registration.entity_profil.exceptional_situation_2')}{' '}
      <a href="mailto:contact@opx.co" className="text-primaryText">
        {t('auth.registration.entity_profil.exceptional_situation_link')}
      </a>
    </div>
  );
}

export default MultipleEntityProfilsSelected;
