/* eslint-disable no-nested-ternary */
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';
import { ThemeContext } from '@context/ThemeContext';

import { ROLES } from '@utils/roles';

import { ChevronDownIcon, LoadingIcon } from '@assets/images/svgComponents';
import ImageContainer from '@components/atomic/ImageContainer';
import { ColorCube } from '@components/atomic/ColorCube';
import { black, textGrey, white } from '@assets/color';
import SwitchEntityModal from '@components/navigation/sidebar/SwitchRoleAndEntity/SwitchEntityModal';
import { convertHexToRGBA } from '@utils/functions';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';

interface SwitchRoleButtonProps {
  isExample?: boolean;
  textColorWhiteLabel?: 'white' | 'black';
}

function SwitchRoleButton({
  isExample,
  textColorWhiteLabel,
}: SwitchRoleButtonProps) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { roleUser, sidebarMenuIsOpen, userView, globalEnum } =
    useContext(GlobalContext);
  const { themeData } = useContext(ThemeContext);
  const [isChangeEntityType, setIsChangeEntityType] = useState(false);
  const [switchEntityModalOpen, setSwitchEntityModalOpen] = useState(false);

  const openableMenu = user?.entity_types && user?.entity_types.length > 1;

  const entityTypeName = useMemo(() => {
    if (
      userView &&
      userView.entity_type &&
      globalEnum &&
      globalEnum.entity_type
    ) {
      return globalEnum.entity_type[userView.entity_type];
    }
    return undefined;
  }, [userView]);

  const menuThemeData = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.MENU
  );

  const getTextColor = () => {
    if (isExample && textColorWhiteLabel) {
      return textColorWhiteLabel;
    }
    return menuThemeData?.text_color;
  };

  const textColor = getTextColor();

  const bgColor =
    textColor === 'black'
      ? convertHexToRGBA(white, 0.4)
      : convertHexToRGBA(black, 0.4);

  return (
    <div
      className={`p-[.25rem] ${
        !sidebarMenuIsOpen ? ' w-[2.5rem] h-[2.5rem] m-auto' : ''
      } mt-[1.5rem] rounded-default`}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <button
        type="button"
        className={` ${
          sidebarMenuIsOpen
            ? `flex justify-between items-center w-full ${
                openableMenu ? '' : 'cursor-default'
              }`
            : ''
        } max-w-full overflow-hidden`}
        onClick={() => {
          if (openableMenu && !isExample) {
            setIsChangeEntityType(!isChangeEntityType);
          }
        }}
        data-test-id="switch_role_button"
      >
        <div
          className={` ${
            sidebarMenuIsOpen
              ? 'flex items-center space-x-[.5rem] max-w-full'
              : ''
          } `}
        >
          <ImageContainer imageUrl={userView?.logo} />
          {sidebarMenuIsOpen && (
            <div className="overflow-hidden">
              <p
                className="text-[.875rem] font-medium leading-5 text-left"
                style={{
                  color: textColor || white,
                }}
                data-test-id="role_user"
              >
                {entityTypeName ||
                  (roleUser === ROLES.PRODUCTION
                    ? t('roles.production')
                    : roleUser === ROLES.GESTION
                    ? t('roles.gestion')
                    : t('roles.pilot'))}
              </p>
              <p
                className="text-[.625rem] leading-3 text-left whitespace-nowrap truncate"
                style={{ color: textColor || textGrey }}
              >
                {userView?.company_name || 'Entreprise'}
              </p>
            </div>
          )}
        </div>
        {sidebarMenuIsOpen && openableMenu && (
          <ChevronDownIcon
            width="1rem"
            height="1rem"
            fill={textColor || white}
            className={[
              isChangeEntityType ? 'rotate-[180deg]' : '',
              'transition-all mr-[.25rem]',
            ].join(' ')}
          />
        )}
      </button>
      {isChangeEntityType && sidebarMenuIsOpen && (
        <div className="pt-[.625rem] pb-[.25rem] flex flex-col space-y-[.5rem]">
          {openableMenu &&
            user?.entity_types &&
            user?.entity_types.length > 1 && (
              <div className="flex justify-between items-center px-2">
                <p
                  className="text-[.75rem] text-left"
                  style={{ color: textColor || white }}
                >
                  {globalEnum?.entity_type[userView?.entity_type || 0]} -{' '}
                  {userView?.company_name}
                </p>
                <ColorCube
                  size="1.5rem"
                  numberOrIcon={<LoadingIcon />}
                  color={textColor || white}
                  backgroundColor="#90909020"
                  onClick={() => setSwitchEntityModalOpen(true)}
                />
              </div>
            )}
        </div>
      )}
      {switchEntityModalOpen && (
        <SwitchEntityModal
          setSwitchEntityModalOpen={setSwitchEntityModalOpen}
        />
      )}
    </div>
  );
}

export { SwitchRoleButton };

SwitchRoleButton.defaultProps = {
  isExample: false,
  textColorWhiteLabel: undefined,
};
