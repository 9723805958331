import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';

import { borderGrey, iconBlue, purple } from '@assets/color';

import {
  deleteCofrac,
  downloadCofracSheet,
} from '@models/cofrac/apiRequests/cofracRequests';
import { numberWithSeparator } from '@utils/format';
import { MultipleProgressBar } from '@models/partners/components/MultiProgressBar';
import { BriefcaseIcon, VolumeGraphIcon } from '@assets/images/svgComponents';
import { convertKiloToMega } from '@utils/functions';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { DATE_MODAL } from '@models/cofrac/utils/cofracConstants';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { Modal } from '@components/Modal';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';
import { PARTNERS_ROUTES } from '@utils/routesUrls';

interface ICofracPageHeaderProps {
  data: ICofracType | undefined;
  setModal: Dispatch<SetStateAction<string>>;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
  exportCofracButtonEnabled: boolean;
}

function CofracPageHeader(props: ICofracPageHeaderProps) {
  const { updateDisplayModalUpgradeSubscription } = useContext(GlobalContext);

  const { user } = useContext(AuthContext);

  const { data, setModal, tab, setTab, loading, exportCofracButtonEnabled } =
    props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const elements = useMemo(() => {
    const arrInfos = [
      {
        title: t('cofrac.reference'),
        value: data?.name || '',
        icon: <BriefcaseIcon />,
      },
      {
        title: t('contract.tab_volume_detail.volume_classic'),
        value: `${data ? convertKiloToMega(data?.volume.classique) : '-'} MWhc`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('contract.tab_volume_detail.volume_precaire'),
        value: `${data ? convertKiloToMega(data.volume.precaire) : '-'} MWhc`,
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('partners.deposits.total_prime'),
        value: `${
          numberWithSeparator(String(data?.prime_total || '')) || '-'
        } €`,
        icon: <VolumeGraphIcon />,
      },
    ];

    return arrInfos.map((val) => {
      return {
        infos: [
          {
            title: val.title,
            subtitle: val.value,
          },
        ],
        icon: val.icon,
      };
    });
  }, [data]);

  const exportCofrac = async () => {
    if (data) {
      setIsLoading(true);
      await downloadCofracSheet(data);
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (deleteId) {
      setIsDeleting(true);

      const res = await deleteCofrac(Number(deleteId));
      if (res.result === 'success') {
        setDeleteId(undefined);
        navigate(PARTNERS_ROUTES.COFRAC);
      }
      setIsDeleting(false);
    }
  };

  return (
    <>
      <SubHeader
        leftPart={
          data?.cofrac_date ? (
            <TabsLeftPart
              titlesList={[t('cofrac.tab_suivi'), t('cofrac.tab_detail')]}
              onClick={(title) => setTab(title)}
              activeButton={tab}
            />
          ) : (
            <div />
          )
        }
        rightPart={
          <>
            {data?.cofrac_lots_operations.length === 0 && (
              <ButtonOpx
                label={`${t('cofrac.btn_delete')}`}
                onClick={() => setDeleteId(data?.id)}
                type="secondary"
              />
            )}
            {deleteId && (
              <Modal
                isLoading={isDeleting}
                title={t('cofrac.title_delete')}
                textBtnConfirm={`${t('buttons.confirm')}`}
                onConfirmClick={handleDelete}
                btnCancel
                setIsModal={() => setDeleteId(undefined)}
                backgroundTransparent
              >
                <p className="mt-4">{t('global.confirm_delete')}</p>
              </Modal>
            )}
            {data && !data?.cofrac_date && (
              <ButtonOpx
                onClick={() => setModal(DATE_MODAL.DATE)}
                label={t('cofrac.btn_date')}
                type="secondary"
                disabled={!data}
              />
            )}
            <ButtonOpx
              label={t('cofrac.btn_export_csv')}
              disabled={exportCofracButtonEnabled}
              isLoading={isLoading}
              onClick={() => {
                if (user?.is_freemium) {
                  updateDisplayModalUpgradeSubscription(true);
                } else {
                  exportCofrac();
                }
              }}
            />
          </>
        }
      />
      {tab === t('cofrac.tab_suivi') && (
        <div>
          <div className="w-full flex flex-wrap justify-between">
            {elements.map((element) => (
              <div key={v4()} className="w-full pb-6 md:w-[24%]">
                <div
                  style={{
                    backgroundColor: 'white',
                    borderColor: borderGrey,
                  }}
                  className="border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem] min-h-[7.2rem] flex items-center w-full"
                >
                  <InfosWithIcon
                    loading={loading}
                    icon={element.icon}
                    infos={element.infos}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              backgroundColor: 'white',
              borderColor: borderGrey,
            }}
            className="my-[1rem] border-[1px] px-[2rem] py-[2.125rem] rounded-[.25rem]"
          >
            <MultipleProgressBar
              addClass="!h-[0.313rem]"
              showLegend
              data={[
                {
                  ratio: data
                    ? (Number(data.volume.classique) * 100) /
                      Number(data.volume.total) /
                      100
                    : 0,
                  color: iconBlue,
                  label: `${t('partners.deposits.volume_classic')} ${
                    data ? convertKiloToMega(data.volume.classique) : '-'
                  } MWhc`,
                },
                {
                  ratio: data
                    ? (Number(data.volume.precaire) * 100) /
                      Number(data.volume.total) /
                      100
                    : 0,
                  color: purple,
                  label: `${t('partners.deposits.volume_precarity')} ${
                    data ? convertKiloToMega(data.volume.precaire) : '-'
                  } MWhc`,
                },
              ]}
              loading={loading}
            />
            {loading ? (
              <div className="flex justify-end">
                <LoaderSkeleton
                  height="0.75rem"
                  addClass="mt-2 w-[10rem] text-right"
                />
              </div>
            ) : (
              <p className="text-right font-medium">
                {`${t('contract.tab_volume_detail.volume_total')}: ${
                  data ? convertKiloToMega(data.volume.total) : '-'
                } MWhc`}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export { CofracPageHeader };
