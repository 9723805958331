import { useState, Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import {
  ChevronDownIcon,
  NotificationsIcon,
  WatchPageIcon,
} from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import { useFormContext } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { ColorCube } from '@components/atomic/ColorCube';
import { OneOperationCard } from '@models/worksiteCreation/components/simulation/stepChoiceOperation/OneOperationCard';
import { IElementData } from '@models/settings/utils/settingsTypes';
import { ThemeContext } from '@context/ThemeContext';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { convertHexToRGBA } from '@utils/functions';
import ColorPicker from './ColorPicker';

interface ButtonElementsProps {
  onUpdateElement: Dispatch<SetStateAction<IElementData[]>>;
  setShowMainPagePreview: Dispatch<SetStateAction<boolean>>;
}

function ButtonElements({
  onUpdateElement,
  setShowMainPagePreview,
}: ButtonElementsProps) {
  const { t } = useTranslation();
  const { themeData } = useContext(ThemeContext);

  const { watch } = useFormContext();

  const primaryElements = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.PRIMARY
  );

  const secondaryElements = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );

  // États locaux pour gérer les couleurs des boutons
  const [loginButtonColor, setLoginButtonColor] = useState(
    primaryElements?.background_color || blueOpx
  );
  const [loginButtonTextColor, setLoginButtonTextColor] = useState<
    'white' | 'black'
  >(primaryElements?.text_color || 'white');

  const [secondaryButtonColor, setSecondaryButtonColor] = useState(
    secondaryElements?.background_color || blueOpx
  );
  const [secondaryButtonTextColor, setSecondaryButtonTextColor] = useState<
    'white' | 'black'
  >(secondaryElements?.text_color || 'white');

  return (
    <div className="p-6 space-y-8 border-b border-borderGrey">
      <div className="flex justify-between">
        <p className="font-medium">{t('settings.white_label.buttons')}</p>
        <ButtonOpx
          icon={<WatchPageIcon fill={blueOpx} />}
          label={t('settings.white_label.preview_content_page')}
          type="tab"
          onClick={() => setShowMainPagePreview(true)}
        />
      </div>
      <div className="flex space-x-[8.75rem]">
        <ColorPicker
          name="primary"
          label={`${t('settings.white_label.login_button')} / ${t(
            'settings.white_label.platform_main_button'
          )}`}
          initialColor={loginButtonColor}
          initialTextColor={loginButtonTextColor}
          setParentColor={setLoginButtonColor}
          setParentTextColor={setLoginButtonTextColor}
          onUpdateElement={onUpdateElement}
        />
        <div className="flex flex-col flex-grow space-y-6">
          <div className="space-y-2">
            <p className="font-medium">
              {t('settings.white_label.login_button')}
            </p>
            <ButtonOpx
              label={watch('loginButtonText') || t('auth.login')}
              addClass="w-full"
              color={loginButtonTextColor}
              backgroundColor={loginButtonColor}
            />
          </div>
          <InputText
            id="loginButtonText"
            name="loginButtonText"
            label={t('settings.white_label.modify_text')}
            placeholder={t('settings.white_label.login_button')}
            required
          />
          <div className="space-y-2">
            <p className="font-medium">
              {t('settings.white_label.principal_button_example')}
            </p>
            <ButtonOpx
              label={t('contract.new_contract')}
              color={loginButtonTextColor}
              backgroundColor={loginButtonColor}
            />
          </div>
        </div>
      </div>
      <div className="flex space-x-[8.75rem]">
        <ColorPicker
          name="secondary"
          label={`${t('settings.white_label.secondary_button')} / ${t(
            'settings.white_label.small_graphic_elements'
          )}`}
          initialColor={secondaryButtonColor}
          initialTextColor={secondaryButtonTextColor}
          setParentColor={setSecondaryButtonColor}
          setParentTextColor={setSecondaryButtonTextColor}
          onUpdateElement={onUpdateElement}
        />
        <div className="flex flex-col flex-grow space-y-6">
          <div className="space-y-2">
            <p className="font-medium">
              {t('settings.white_label.secondary_button_example')} (
              {t('settings.white_label.opacity_percentage', {
                percentage: 40,
              })}
              )
            </p>
            <ButtonOpx
              label={t(
                'buttons.valid_worksite_creation_and_send_to_beneficiary'
              )}
              addClass="w-full"
              color={secondaryButtonTextColor}
              backgroundColor={secondaryButtonColor}
              backgroundOpacity={40}
              type="tab"
            />
          </div>
          <div className="space-y-2">
            <p className="font-medium">
              {t('settings.white_label.small_graphic_elements')} (
              {t('settings.white_label.opacity_percentage', {
                percentage: 40,
              })}
              )
            </p>
            <div className="flex space-x-6 items-center">
              <ColorCube
                numberOrIcon={<NotificationsIcon />}
                color={secondaryButtonTextColor}
                backgroundColor={convertHexToRGBA(secondaryButtonColor, 0.4)}
                size="2.5rem"
              />
              <ButtonOpx
                label={t('global.operations')}
                color={secondaryButtonTextColor}
                backgroundColor={secondaryButtonColor}
                backgroundOpacity={40}
                type="tab"
              />
              <ColorCube
                numberOrIcon={<ChevronDownIcon />}
                color={secondaryButtonTextColor}
                backgroundColor={convertHexToRGBA(secondaryButtonColor, 0.4)}
                size="1.5rem"
              />

              <ButtonOpx
                icon={<NotificationsIcon />}
                label="0 tâche"
                color={secondaryButtonTextColor}
                backgroundColor={convertHexToRGBA(secondaryButtonColor, 0.4)}
                addClass="!border-none"
                textJustify="justify-center"
              />
            </div>
          </div>
          <div className="space-y-2">
            <p className="font-medium">
              {t('settings.white_label.selected_operation_background')} (
              {t('settings.white_label.opacity_percentage', {
                percentage: 10,
              })}
              )
            </p>
            <OneOperationCard
              nameOperation="Isolation des murs"
              code="BAR-EN-102"
              isChecked
              handleCheck={() => {
                return null;
              }}
              addClass=""
              pictureOperationUrl=""
              disabled={false}
              incompatible={false}
              backgroundColorCheck={secondaryButtonColor}
              textColor={secondaryButtonTextColor}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ButtonElements;
