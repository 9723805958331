import { Dispatch, SetStateAction } from 'react';
import { axiosClient } from '@api/apiClient';
import {
  urlDeleteCustomField,
  urlGetCustomFields,
  urlGetOrPostTheme,
  urlGetThemeBySlug,
  /*   urlGetThemeBySlug, */
  urlGetThemesList,
  urlPostCustomField,
  urlUpdateCustomField,
} from '@api/apiUrls';
import { displayError } from '@utils/format';
import {
  IElementData,
  IImageData,
  IObjectWhiteLabelToSend,
} from '../utils/settingsTypes';

export const getListCustomFields = async (
  setData: Dispatch<SetStateAction<any>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  const response = await axiosClient.post(urlGetCustomFields);
  setData(response.data);
  setIsLoading(false);
  return response.data;
};

export const addCustomField = async (
  data: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(urlPostCustomField, data);
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const updateCustomField = async (
  customFieldId: number,
  data: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(
      urlUpdateCustomField(customFieldId),
      data
    );
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const deleteCustomField = async (
  customFieldId: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    await axiosClient.post(urlDeleteCustomField(customFieldId));
    setIsLoading(false);
    return true;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const updateTheme = async (
  entityId: number,
  data: IObjectWhiteLabelToSend
) => {
  try {
    const formData = new FormData();

    data.images.forEach((image: IImageData, index: number) => {
      formData.append(`images[${index}][image]`, image.image);
      if (image.initial) {
        formData.append(`images[${index}][initial]`, image.initial);
      }
      if (image.custom) {
        formData.append(`images[${index}][custom]`, image.custom);
      }
      formData.append(
        `images[${index}][brightness]`,
        image.brightness.toString()
      );
      formData.append(`images[${index}][contrast]`, image.contrast.toString());
      formData.append(
        `images[${index}][saturation]`,
        image.saturation.toString()
      );
      formData.append(`images[${index}][crop]`, JSON.stringify(image.crop));
      if (image.croppedAreaPixels) {
        formData.append(
          `images[${index}][croppedAreaPixels]`,
          JSON.stringify(image.croppedAreaPixels)
        );
      }
    });

    data.elements.forEach((element: IElementData, index: number) => {
      formData.append(`elements[${index}][element]`, element.element);
      formData.append(`elements[${index}][text_color]`, element.text_color);
      formData.append(
        `elements[${index}][background_color]`,
        element.background_color
      );
    });

    formData.append('login_button_text', data.loginButtonText);

    const response = await axiosClient.post(
      urlGetOrPostTheme(entityId),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};

export const getTheme = async (entityId: number) => {
  try {
    const response = await axiosClient.get(urlGetOrPostTheme(entityId));
    return response;
  } catch (error) {
    return false;
  }
};

export const getThemesList = async () => {
  try {
    const response = await axiosClient.get(urlGetThemesList);
    return response;
  } catch (error) {
    return false;
  }
};

export const getThemeBySlug = async (slug: string) => {
  try {
    const response = await axiosClient.get(urlGetThemeBySlug(slug));
    return response.data.data;
  } catch (error) {
    return false;
  }
};
