export const THEME_ELEMENTS_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MENU: 'menu',
  MENU_BUTTON: 'menu_button',
};

export const THEME_IMAGE_TYPES = {
  LOGO_AUTH: 'logo_auth',
  LOGO_MENU: 'logo_menu',
  BACKGROUND_IMAGE_AUTH: 'background_image_auth',
};
