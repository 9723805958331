import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { IThemeResponse } from '@models/settings/utils/settingsTypes';
import { Dispatch, SetStateAction } from 'react';

const backgroundBody = '#F4F4F4';
const white = 'var(--white)';
const black = 'var(--textBlack)';

const greyOpacity20 = 'var(--textGreyOpacity20)';
const borderGrey = 'var(--borderGrey)';

// Primary color
const blueOpx = 'var(--blueOpx)';
const initialBlueOpx = '#1730bf';
// const blueAuthBg = '#2A30BF';
const blueSecondary = 'var(--iconBlue)';
const darkBlue = 'var(--darkBlue)';
const blueOpacity = 'var(--blueOpacity)';

const backgroundGrey = 'var(--backgroundBody)';
const grey = 'var(--borderGrey)';
const lightGrey = '#D0D0D0';
const purple = 'var(--purple)';

// Text color
const textGrey = 'var(--textGrey)';
const textGreyInfos = 'var(--textGreyInfos)';

// Icones color
const iconBlue = 'var(--iconBlue)';
const initialIconBlue = '#4f95ff';
const iconBlueOpacity = 'var(--iconBlueOpacity)';
const lightBlue = 'var(--lightBlue)';

// Secondary color
const green = 'var(--green)';
const darkGreen = 'var(--darkGreen)';
const lightGreen = 'var(--lightGreen)';
const red = 'var(--red)';
const lightRed = 'var(--lightRed)';
const orange = 'var(--orange)';

/**
 * Applique les styles du thème ou réinitialise les styles par défaut.
 *
 * @param themeData Les données de thème à appliquer. Si non défini, les styles par défaut sont restaurés.
 */
const applyTheme = (
  themeData: IThemeResponse | undefined,
  setThemeData?: Dispatch<SetStateAction<IThemeResponse | undefined>>
): void => {
  if (themeData) {
    if (setThemeData) {
      setThemeData(themeData);
    }
    const primaryElement = themeData?.elements?.find(
      (element) => element.element === THEME_ELEMENTS_TYPES.PRIMARY
    );
    const secondaryElement = themeData?.elements?.find(
      (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
    );

    if (primaryElement) {
      document.documentElement.style.setProperty(
        '--blueOpx',
        primaryElement.background_color
      );
      document.documentElement.style.setProperty(
        '--blueOpxOpacity10',
        `${primaryElement.background_color}1a`
      );
    }

    if (secondaryElement) {
      document.documentElement.style.setProperty(
        '--iconBlue',
        secondaryElement.text_color
      );
    }

    document.documentElement.style.setProperty('--textPrimary', black);

    // Injection du style pour les liens
    const styleId = 'global-link-styles';
    let styleElement = document.getElementById(styleId);
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = styleId;
      document.head.appendChild(styleElement);
    }
    styleElement.innerHTML = `
      a {
        font-weight: bold;
      }
    `;
  } else {
    if (setThemeData) {
      setThemeData(undefined);
    }
    // Réinitialiser les propriétés CSS personnalisées
    document.documentElement.style.removeProperty('--blueOpx');
    document.documentElement.style.removeProperty('--blueOpxOpacity10');
    document.documentElement.style.removeProperty('--iconBlue');
    document.documentElement.style.removeProperty('--textPrimary');

    // Supprimer le style injecté pour les liens
    const styleElement = document.getElementById('global-link-styles');
    if (styleElement) {
      styleElement.innerHTML = '';
    }
  }
};

export {
  orange,
  white,
  black,
  blueOpx,
  initialBlueOpx,
  darkBlue,
  blueOpacity,
  backgroundBody,
  textGrey,
  iconBlue,
  initialIconBlue,
  iconBlueOpacity,
  greyOpacity20,
  grey,
  lightGrey,
  backgroundGrey,
  borderGrey,
  green,
  red,
  textGreyInfos,
  blueSecondary,
  purple,
  lightRed,
  lightGreen,
  lightBlue,
  darkGreen,
  applyTheme,
};
