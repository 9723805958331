/* eslint-disable react/no-array-index-key */
import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { formScenariosAndCalculations } from '@models/mar/utils/datas';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import OneScenario from '@models/mar/components/worksiteCompletion/stepsCompletion/scenariosAndCalculations/OneScenario';
import { getEnergyClass } from '@models/mar/utils/functions';
import { validateScenariosAndCalculations } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { IScenario, IWorksiteMar } from '@models/mar/utils/marTypes';
import { createFileWithLinkedFile } from '@utils/functions';
import { TextError } from '@components/TextError';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';

function ScenariosAndCalculations() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateButtonNextDisabled,
    updateWorksiteData,
    updateStepCompletion,
    operationsList,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [isPrefill, setIsPrefill] = useState(false);
  const [prefillScenariosValues, setPrefillScenariosValues] = useState<any>();

  const { watch, setValue, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `scenario`,
  });

  const performanceEnergeticClass = watch('energy_class');
  const eges = watch('eges');
  const cep = watch('cep');
  const cef = watch('cef');
  const scenarios: IScenario[] = watch('scenario');

  const everyCostsAreFilled =
    scenarios &&
    scenarios.every(
      (scenario) => scenario.total_cost_ttc && scenario.mpr_amount
    );

  const hasUnsavedScenarios = () => {
    if (
      prefillScenariosValues &&
      worksiteData?.scenarios.every((scenario) => !!scenario.mpr_amount)
    ) {
      return prefillScenariosValues !== JSON.stringify(watch('scenario'));
    }
    return false;
  };

  const minScenariosValidated = (): boolean => {
    if (worksiteData && worksiteData.scenarios) {
      const nbScenariosToValidate = 1;
      // Compte les scénarios validés en utilisant filter et length
      const nbScenariosValidated = worksiteData.scenarios.filter(
        (scenario) => scenario.mpr_amount
      ).length;

      // Vérifie si le nombre de scénarios validés est supérieur ou égal au minimum requis
      return nbScenariosValidated >= nbScenariosToValidate;
    }
    return false;
  };

  const hasBothStepTypes = (): boolean => {
    if (scenarios) {
      const hasStepType1 = scenarios.some(
        (scenario) => scenario.step_type?.toString() === '1'
      );
      const hasStepType2 = scenarios.some(
        (scenario) => scenario.step_type?.toString() === '2'
      );

      const scenariosWithStepType = scenarios.filter(
        (scenario) => scenario.step_type
      );

      return (
        (hasStepType1 && hasStepType2) || scenariosWithStepType.length === 1
      );
    }
    return false;
  };

  const checkIfScenariosAndGeneralInfosReady =
    minScenariosValidated() && performanceEnergeticClass && eges && cep && cef;

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const response = await validateScenariosAndCalculations(
      worksiteData?.id || 0
    );
    if (response) {
      updateWorksiteData((prev) => ({
        ...(prev as IWorksiteMar),
        scenarios: response.scenarios,
        step: response.step,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.QUOTES);
    }
    setButtonNextLoading(false);
  };

  const preFillScenariosAndCalculationsData = async () => {
    if (worksiteData) {
      setValue('cef', worksiteData.cef);
      setValue('cep', worksiteData.cep);
      setValue('eges', worksiteData.eges);
      setValue('energy_class', worksiteData.energy_class);
      setValue('surface', worksiteData.surface);
      worksiteData.scenarios.forEach(async (scenario, index) => {
        setValue(`scenario[${index}].id`, scenario.id);
        setValue(`scenario[${index}].cef`, scenario.cef);
        setValue(`scenario[${index}].cep`, scenario.cep);
        setValue(`scenario[${index}].eges`, scenario.eges);
        setValue(
          `scenario[${index}].step_type`,
          scenario.step_type?.toString()
        );
        setValue(`scenario[${index}].energy_class`, scenario.energy_class);
        setValue(
          `scenario[${index}].exemption_message`,
          scenario.exemption_message
        );
        setValue(`scenario[${index}].local_primes`, scenario.local_primes);
        if (scenario.mpr_amount) {
          setValue(`scenario[${index}].mpr_amount`, scenario.mpr_amount);
        }
        setValue(`scenario[${index}].selected`, scenario.selected);
        setValue(
          `scenario[${index}].surface`,
          scenario.surface ? scenario.surface : worksiteData.surface
        );
        if (
          worksiteData.scenarios[index].energy_class !== 'A' &&
          worksiteData.scenarios[index].energy_class !== 'B'
        ) {
          setValue(`scenario[${index}].exemption`, true);
          setValue(
            `scenario[${index}].exemption_message`,
            scenario.exemption_message
          );
          if (
            worksiteData.scenarios[index] &&
            worksiteData.scenarios[index].linked_files.length > 0
          ) {
            const linkedFile = worksiteData.scenarios[index].linked_files[0];
            await createFileWithLinkedFile(
              linkedFile.file_url as string,
              linkedFile.file_name as string
            ).then((file) => {
              setValue(`scenario[${index}].exemption_file`, file);
            });
          }
        }

        scenario.mar_gestes.forEach((geste, indexGeste) => {
          setValue(
            `scenario[${index}.gesture[${indexGeste}].geste_id`,
            geste.geste_id.toString()
          );
          setValue(
            `scenario[${index}.gesture[${indexGeste}].cost_ht`,
            geste.cost_ht
          );
          setValue(
            `scenario[${index}.gesture[${indexGeste}].cost_ttc`,
            geste.cost_ttc
          );
        });
      });
    }
  };

  const handleAddScenario = useCallback(() => {
    append({ surface: worksiteData?.surface });
  }, [append]);

  const handleDeleteScenario = useCallback(
    (indexScenario: number) => {
      remove(indexScenario);
    },
    [remove]
  );

  useEffect(() => {
    if (cep && eges) {
      if (
        worksiteData?.cep !== Number(cep) ||
        worksiteData?.eges !== Number(eges)
      ) {
        getEnergyClass(
          {
            cep,
            eges,
          },
          setValue
        );
      }
    }
  }, [eges, cep]);

  useEffect(() => {
    updateButtonNextDisabled(!checkIfScenariosAndGeneralInfosReady);
  }, [watch(), worksiteData]);

  useEffect(() => {
    if (isPrefill && !prefillScenariosValues && everyCostsAreFilled) {
      setPrefillScenariosValues(JSON.stringify(scenarios));
    }
  }, [isPrefill, everyCostsAreFilled, prefillScenariosValues]);

  useEffect(() => {
    if (fields.length === 0 && worksiteData) {
      worksiteData.scenarios.forEach(() => {
        append({});
      });
    }
    preFillScenariosAndCalculationsData().then(() => setIsPrefill(true));
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {hasUnsavedScenarios() && (
          <TextError
            errorMessage={t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.scenario_edited_without_save'
            )}
            addClass="!mt-0 text-[.875rem]"
            isWarning
          />
        )}
        {minScenariosValidated() && !hasBothStepTypes() && (
          <TextError
            errorMessage={t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.warning_same_step_type'
            )}
            isWarning
            addClass="!mt-0 text-[.875rem]"
          />
        )}
        <div className="flex flex-col space-y-4">
          <OneMarForm
            dataForm={formScenariosAndCalculations(
              t,
              worksiteData as IWorksiteMar,
              performanceEnergeticClass,
              undefined,
              undefined,
              undefined
            )}
          />
        </div>
        {fields.map((fieldRecord, index) => (
          <OneScenario
            key={fieldRecord.id}
            indexScenario={index}
            nbScenarios={fields.length}
            operationsList={operationsList}
            onDelete={handleDeleteScenario}
            openByDefault={
              index === 0 && !worksiteData?.scenarios[0].mpr_amount
            }
            setPrefillScenariosValues={setPrefillScenariosValues}
          />
        ))}
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.add_another_scenario'
          )}`}
          small
          onClick={handleAddScenario}
          type="primary"
          addClass="w-full"
        />
      </div>
    </LayoutMarDetailsStep>
  );
}

export default ScenariosAndCalculations;
