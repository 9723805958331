import { useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '@context/globalContext';

import { Card } from '@components/Card';
import { DocumentCard } from '@components/documents/DocumentCard';
import { DocumentViewer } from '@components/documents/DocumentViewer';

import { getListDocuments } from '@models/contracts/apiRequests/newContractRequests';

import { initDocumentActive } from '@utils/initialState';

import { getLinkedFile, sendLinkedFile } from '@apiRequests/globalRequests';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { DragAndDropZone } from '@models/contractCreation/components/steps/DragAndDropZone';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { ILinkedFile } from '../../../../types/globalTypes';

function StepDocuments() {
  const { relatedContract, referenceOpx, contractType } = useContext(
    ContractCreationContext
  );
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { updateDocumentActive, documentActive } = useContext(GlobalContext);

  const [documentList, setDocumentList] = useState<ILinkedFile[]>([]);

  const isConvention = useMemo(() => {
    return [ContractTypes.CONVENTION, ContractTypes.TRIPARTITE].includes(
      contractType
    );
  }, [contractType]);

  const getList = async () => {
    const res = await getListDocuments(
      relatedContract?.id || 0,
      isConvention ? 'convention' : 'contract'
    );
    setDocumentList(res);
  };

  const getFile = async (fileId: number) => {
    setIsLoading(true);
    getLinkedFile(fileId).then((res) => {
      if (res.data) updateDocumentActive({ list: '', document: res.data });
      setIsLoading(false);
    });
  };

  const handleUpload = async (file: File, document: ILinkedFile) => {
    const relation_ids = document.relation_ids || [0];
    const relation_type = document.relation_type || '';
    const file_type = document.file_type;
    const status = document.status;
    const commentary = document.commentary || '';
    const linked_entity_id = document.linked_entity_id;
    const mandatory = document.mandatory || false;
    const documentId = document.id || undefined;
    const canDuplicate = document.can_duplicate || false;

    setIsLoading(true);

    const res = await sendLinkedFile(
      file,
      relation_ids,
      relation_type,
      file_type,
      status,
      commentary,
      linked_entity_id,
      mandatory,
      canDuplicate,
      documentId,
      getList
    );

    setIsLoading(false);

    getFile(res.data.id);
    return res;
  };

  useEffect(() => {
    updateDocumentActive(initDocumentActive);
    getList();
  }, []);

  const getTitle = (name: string, url: string) => {
    if (name.length > 0) return name;

    const fileName = url.split('/').pop();
    const extension = `.${url.split('.').pop()}`;
    return fileName?.split(extension)[0];
  };

  const addText = useMemo(() => {
    switch (contractType) {
      case ContractTypes.MANDAT:
      case ContractTypes.MANDAT_NON_DEPOSANT:
        return t('contract.document.add_mandat');
      case ContractTypes.CONVENTION:
        return t('contract.document.add_convention');
      case ContractTypes.TRIPARTITE:
        return t('contract.document.add_tripartite');
      case ContractTypes.VENTE:
      case ContractTypes.DELEGATION:
        return t('contract.document.add_sale');
      default:
        return '';
    }
  }, [contractType]);

  return (
    <div className="border border-borderGrey rounded-default bg-white p-6">
      <div className="flex flelx-wrap">
        <div className="w-full md:w-[50%] md:pr-3">
          <Card title={t('contract.documents')} isLoading={isLoading}>
            <div>
              <DragAndDropZone
                onUpload={handleUpload}
                document={{
                  id: null,
                  relation_ids: relatedContract ? [relatedContract.id] : [],
                  relation_type: isConvention ? 'convention' : 'contract',
                  file_type: isConvention ? 16 : 15,
                  status: 1,
                  isolate_file: false,
                  file_url: null,
                  file_name: null,
                  linked_entity_id: null,
                  commentary: null,
                  file: null,
                  created_at: null,
                  uploaded_at: null,
                  is_deletable: null,
                  file_hash: null,
                  custom_field_id: null,
                }}
                addClass="mb-5 rounded-default"
              >
                <div className="p-4 flex justify-between items-center">
                  <div>{addText}</div>
                  <ColorCube
                    size="1.5rem"
                    numberOrIcon={
                      <div className="text-[1.2rem] relative bottom-[1px] ">
                        +
                      </div>
                    }
                    color={blueOpx}
                    dataTestId="upload_document"
                  />
                </div>
              </DragAndDropZone>
              {documentList?.length > 0 ? (
                <div>
                  {documentList.map((document) => (
                    <div key={v4()} className="mb-4">
                      <DocumentCard
                        document={document}
                        onSelect={(file) => {
                          if (file.id) getFile(file.id);
                          else {
                            updateDocumentActive({
                              ...initDocumentActive,
                              list: '',
                            });
                          }
                        }}
                        isActive={documentActive.document?.id === document.id}
                        isLoading={false}
                        onDelete={(file) => console.log('onDelete', file)}
                        customTitle={getTitle(
                          String(document.file_name),
                          String(document.file_url)
                        )}
                        hideActionButton
                        headerButtonsList={['download']}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div />
              )}
            </div>
          </Card>
        </div>
        <div className="w-full md:w-[50%]">
          <DocumentViewer
            isLoading={isLoading}
            setIsLoading={() => ''}
            reference={referenceOpx}
            canEdit
          />
        </div>
      </div>
    </div>
  );
}

export { StepDocuments };
