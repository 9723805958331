/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx, green, red } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { ChevronLeftIcon, TrashIcon } from '@assets/images/svgComponents';
import { useFieldArray, useFormContext } from 'react-hook-form';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { formScenariosAndCalculations } from '@models/mar/utils/datas';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { appendFormData, getEnergyClass } from '@models/mar/utils/functions';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { InputTypes } from '@utils/utils';
import { InputText } from '@components/atomic/inputs/InputText';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import {
  getScenarioPrime,
  validateOneScenario,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { IGestureField, IWorksiteMar } from '@models/mar/utils/marTypes';
import { TextError } from '@components/TextError';
import OneGesture from '@models/mar/components/worksiteCompletion/stepsCompletion/scenariosAndCalculations/OneGesture';
import { convertHexToRGBA, numFormatSpace } from '@utils/functions';
import { ILabelValue } from '../../../../../../types/globalTypes';

interface IOneScenarioProps {
  indexScenario: number;
  nbScenarios: number;
  operationsList: ILabelValue[] | undefined;
  onDelete: (index: number) => void;
  openByDefault: boolean;
  setPrefillScenariosValues: Dispatch<SetStateAction<any>>;
}

function OneScenario({
  indexScenario,
  nbScenarios,
  operationsList,
  onDelete,
  openByDefault,
  setPrefillScenariosValues,
}: IOneScenarioProps) {
  const { t } = useTranslation();
  const { worksiteData, updateWorksiteData } = useContext(
    WorksiteCreationMarContext
  );

  const [
    buttonScenarioValidationDisabled,
    setButtonScenarioValidationDisabled,
  ] = useState<boolean>(true);
  const [debouncedData, setDebouncedData] = useState<any>({});
  const [debouncedForPrime, setDebouncedForPrime] = useState<any>({});
  const [dataPrimeCalculation, setDataPrimeCalculation] = useState<any>({});
  const [isLoadingValidation, setIsLoadingValidation] =
    useState<boolean>(false);
  const [isLoadingPrime, setIsLoadingPrime] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!openByDefault); // État pour gérer l'ouverture/fermeture

  const { watch, setValue, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `scenario.${indexScenario}.gesture`,
  });

  const mprAmountAlreadySaved =
    worksiteData?.scenarios[indexScenario]?.mpr_amount;

  const prefix = `scenario.${indexScenario}.`;
  const gestures: IGestureField[] = watch(`${prefix}gesture`);
  const isSelected = watch(`${prefix}selected`);

  const totalHTFieldName = `${prefix}total_cost_ht`;
  const totalHT = watch(totalHTFieldName);
  const totalTTCFieldName = `${prefix}total_cost_ttc`;
  const totalTTC = watch(totalTTCFieldName);

  const performanceEnergeticClass = watch(`${prefix}energy_class`);
  const needDerogation =
    performanceEnergeticClass &&
    performanceEnergeticClass !== 'A' &&
    performanceEnergeticClass !== 'B';

  const initialClass = watch('energy_class');
  const precarityType = worksiteData?.precarity_type;
  const localPrimes = watch(`${prefix}local_primes`);
  const mprAmount = watch(`${prefix}mpr_amount`);

  const eges = watch(`${prefix}eges`);
  const cep = watch(`${prefix}cep`);
  const exemption = watch(`${prefix}exemption`);
  const exemptionReason = watch(`${prefix}exemption_message`);

  const oneScenarioIsAlreadySelected = worksiteData?.scenarios.some(
    (scenario) => scenario.selected && scenario.id !== watch(`${prefix}id`)
  );

  const readyForPrimeCalculation =
    performanceEnergeticClass &&
    performanceEnergeticClass !== '' &&
    initialClass &&
    initialClass !== '' &&
    precarityType &&
    totalTTC &&
    totalTTC !== 0;

  const readyForValidation =
    readyForPrimeCalculation &&
    mprAmount &&
    watch('cep') &&
    watch('eges') &&
    watch('cef') &&
    watch('surface') &&
    watch(`${prefix}cep`) &&
    watch(`${prefix}eges`) &&
    watch(`${prefix}cef`) &&
    watch(`${prefix}surface`) &&
    watch(`${prefix}step_type`) &&
    localPrimes < totalTTC &&
    (needDerogation ? exemption && exemptionReason : true) &&
    gestures &&
    gestures.length > 0 &&
    gestures.every(
      (gesture) =>
        gesture.geste_id &&
        gesture.cost_ttc &&
        gesture.cost_ttc !== '0' &&
        gesture.cost_ttc !== ''
    );

  const noHtEmpty =
    gestures &&
    gestures.length > 0 &&
    gestures.every(
      (gesture) =>
        gesture.cost_ht && gesture.cost_ht !== '0' && gesture.cost_ht !== ''
    );

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Bascule l'état ouvert/fermé
  };

  const calculateTotalHTAndTTC = () => {
    let totalHTCount = 0;
    let totalTTCCount = 0;

    if (gestures) {
      gestures.forEach((gesture) => {
        const costHT = gesture.cost_ht;
        const costTTC = gesture.cost_ttc;

        totalHTCount += costHT ? parseFloat(costHT) : 0;
        totalTTCCount += costTTC ? parseFloat(costTTC) : 0;
      });

      if (totalHT !== totalHTCount && noHtEmpty) {
        setValue(totalHTFieldName, totalHTCount);
      }
      if (totalTTC !== totalTTCCount) {
        setValue(totalTTCFieldName, totalTTCCount);
      }
    }
  };

  const calculateScenarioPrime = async () => {
    if (readyForPrimeCalculation) {
      if (
        JSON.stringify(debouncedData) !== JSON.stringify(dataPrimeCalculation)
      ) {
        const dataToSend = {
          initial_class: initialClass as string,
          final_class: performanceEnergeticClass as string,
          precarity_type: Number(precarityType),
          local_primes: localPrimes ? Number(localPrimes) : 0,
          cost_ht: noHtEmpty ? totalHT : null,
          cost_ttc: totalTTC,
        };

        setIsLoadingPrime(true);
        const response = await getScenarioPrime(dataToSend);
        if (response !== undefined && response !== false) {
          setDataPrimeCalculation({
            ...debouncedData,
            costHT: debouncedData.cost_ht || response.cost_ht,
          });
          setValue(`${prefix}mpr_amount`, response.prime);
          if (!noHtEmpty) {
            setValue(totalHTFieldName, response.cost_ht);
          }
        }
        setIsLoadingPrime(false);
      }
    } else if (mprAmount !== undefined) {
      setValue(`${prefix}mpr_amount`, undefined);
    }
  };

  const validateScenario = async () => {
    setIsLoadingValidation(true);
    const dataToSend = {
      cep: watch('cep'),
      eges: watch('eges'),
      cef: watch('cef'),
      energy_class: initialClass,
      surface: watch('surface'),
      scenario: {
        id: watch(`${prefix}id`) || null,
        selected: isSelected ? 1 : 0,
        step_type: Number(watch(`${prefix}step_type`)),
        cep,
        eges,
        cef: watch(`${prefix}cef`),
        energy_class: performanceEnergeticClass,
        surface: watch(`${prefix}surface`),
        local_primes: localPrimes,
        mpr_amount: mprAmount,
        exemption_message: needDerogation ? exemptionReason : undefined,
        file: needDerogation
          ? watch(`${prefix}exemption_file`) || null
          : undefined,
        gestes: gestures,
      },
    };

    const formData = new FormData();

    appendFormData(dataToSend, formData);

    const response = await validateOneScenario(worksiteData?.id || 0, formData);
    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      setIsCollapsed(true);
      setPrefillScenariosValues(undefined);
    }
    setIsLoadingValidation(false);
  };

  useEffect(() => {
    calculateScenarioPrime();
  }, [debouncedData]);

  // Debounce the relevant values
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedData({
        initialClass,
        finalClass: performanceEnergeticClass,
        precarityType: Number(precarityType),
        localPrimes: localPrimes ? Number(localPrimes) : 0,
        costHT: totalHT,
        costTTC: totalTTC,
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [
    initialClass,
    performanceEnergeticClass,
    precarityType,
    localPrimes,
    totalHT,
    totalTTC,
  ]);

  // Debounce the relevant values for prime calculation
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedForPrime({
        eges,
        cep,
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [eges, cep]);

  useEffect(() => {
    const egesDebounced = debouncedForPrime.eges;
    const cepDebounced = debouncedForPrime.cep;
    if (egesDebounced && cepDebounced) {
      if (
        !worksiteData?.scenarios[indexScenario] ||
        worksiteData?.scenarios[indexScenario].cep !== Number(cepDebounced) ||
        worksiteData?.scenarios[indexScenario].eges !== Number(egesDebounced)
      ) {
        getEnergyClass(
          {
            cep: cepDebounced,
            eges: egesDebounced,
            indexScenario,
          },
          setValue
        );
      }
    }
  }, [debouncedForPrime]);

  useEffect(() => {
    calculateTotalHTAndTTC();
    setButtonScenarioValidationDisabled(!readyForValidation);
  }, [watch()]);

  useEffect(() => {
    if (fields && fields.length === 0) {
      append({});
    }
  }, []);

  return (
    <div className="w-full flex flex-col space-y-6 p-4 border border-borderGrey rounded-default">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggleCollapse}
      >
        <div className="flex space-x-2 items-center">
          <ColorCube
            size="1.5rem"
            numberOrIcon={indexScenario + 1}
            color={blueOpx}
            opacity
          />
          <p className="text-[1.125rem] font-medium leading-0">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.scenario'
            )}
          </p>
          {mprAmount !== undefined && (
            <Tag
              color={green}
              label={`${numFormatSpace(mprAmount)} €`}
              addClass="!ml-4"
            />
          )}
          {nbScenarios > 2 && (
            <ColorCube
              size="2rem"
              numberOrIcon={<TrashIcon />}
              color={red}
              opacity
              onClick={() => onDelete(indexScenario)}
              addClass="!ml-4"
            />
          )}
        </div>
        <ColorCube
          numberOrIcon={
            <ChevronLeftIcon
              className={`${
                !isCollapsed ? 'rotate-[90deg]' : 'rotate-[-90deg]'
              } transform duration-200 m-auto`}
            />
          }
          size="1.5rem"
          color={blueOpx}
          opacity
        />
      </div>
      {!isCollapsed && (
        <>
          {oneScenarioIsAlreadySelected && isSelected && (
            <TextError
              errorMessage={t(
                'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.one_scenario_already_selected'
              )}
              addClass="!mt-0 text-[.875rem]"
            />
          )}
          <OneMarForm
            dataForm={formScenariosAndCalculations(
              t,
              worksiteData as IWorksiteMar,
              performanceEnergeticClass,
              indexScenario,
              needDerogation,
              watch(`${prefix}step_type`)
            )}
          />
          {needDerogation && !(exemption && exemptionReason) && (
            <TextError
              errorMessage={t(
                'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.need_exemption'
              )}
              addClass="!mt-0 text-[.875rem]"
            />
          )}
          <OneMarForm
            dataForm={{
              fields: [
                {
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.reference_surface_area'
                  ),
                  name: `${prefix}surface`,
                  type: InputTypes.NUMBER,
                  fullWidth: true,
                },
              ],
            }}
          />
          <div className="flex flex-col space-y-4">
            <p className="font-medium text-[1.125rem]">{`${t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.recommended_gestures'
            )}`}</p>
            <div className="w-full flex flex-col space-y-4 items-center">
              {operationsList &&
                fields.map((fieldRecord, index) => (
                  <OneGesture
                    key={fieldRecord.id}
                    prefixScenario={prefix}
                    index={index}
                    operationsList={operationsList}
                    nbFields={fields ? fields.length : 0}
                    removeGesture={remove}
                  />
                ))}
              <ButtonOpx
                label={`${t(
                  'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.add_another_gesture'
                )}`}
                small
                onClick={() => append({})}
                type="tierciary"
                addClass="col-span-2 text-[.875rem] w-full"
              />
              <div className="grid grid-cols-4 gap-2 text-[.75rem] items-center">
                <InputText
                  id={totalHTFieldName}
                  type="text"
                  name={totalHTFieldName}
                  label={`${t(
                    'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.total_estimated_cost_ht'
                  )}`}
                  placeholder=""
                  disabled
                  required
                  typeNumber
                  resetSignal={totalHT === 0 || !totalHT ? 1 : 0}
                />
                <InputText
                  id={totalTTCFieldName}
                  type="text"
                  name={totalTTCFieldName}
                  label={`${t(
                    'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.total_estimated_cost_ttc'
                  )}`}
                  placeholder=""
                  disabled
                  required
                />
                <DynamicInputEdit
                  isEditMode
                  label={`${t(
                    'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.total_local_aid'
                  )}`}
                  name={`${prefix}local_primes`}
                  inputType={InputTypes.NUMBER}
                  placeholder=""
                  required
                  noUnregister
                />
                <div className="flex flex-col space-y-2">
                  <div className="text-textGrey text-[.75rem] leading-[.75rem]">
                    {`${t(
                      'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.mpr_bonus'
                    )}`}
                  </div>
                  <div
                    className="w-full border rounded-default focus:outline-none p-[.7rem] text-[.875rem]"
                    style={{
                      backgroundColor: convertHexToRGBA(green, 0.1),
                      borderColor: convertHexToRGBA(green, 0.1),
                      color: green,
                    }}
                  >
                    {mprAmount !== undefined
                      ? `${numFormatSpace(mprAmount)}`
                      : '\u00A0'}
                  </div>
                </div>
              </div>
              {totalTTC !== 0 && localPrimes >= totalTTC && (
                <TextError
                  errorMessage={t(
                    'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.aid_exceed'
                  )}
                  addClass="!mt-0 text-[.875rem]"
                />
              )}
              <ButtonOpx
                label={
                  mprAmountAlreadySaved
                    ? `${t(
                        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.edit_scenario'
                      )}`
                    : `${t(
                        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.validate_scenario'
                      )}`
                }
                small
                onClick={validateScenario}
                disabled={
                  buttonScenarioValidationDisabled ||
                  isLoadingPrime ||
                  isLoadingValidation
                }
                isLoading={isLoadingValidation}
                type="tab"
                addClass="col-span-2 w-full"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OneScenario;
