import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IElementData,
  IImageData,
  IObjectWhiteLabelToSend,
} from '@models/settings/utils/settingsTypes';
import { updateTheme } from '@models/settings/apiRequests/settingsRequests';
import { GlobalContext } from '@context/globalContext';
import { ThemeContext } from '@context/ThemeContext';
import LoginPageElements from './LoginPageElements';
import ButtonElements from './ButtonElements';
import MainMenuElements from './MainMenuElements';
import LoginPageElementsPreview from './LoginPageElementsPreview';
import MainPagePreview from './MainPagePreview';

function WhiteLabel() {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { userView } = useContext(GlobalContext);
  const { updateThemeData, themeData } = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(false);

  // États pour gérer les images et éléments
  const [images, setImages] = useState<IImageData[]>([]);
  const [elements, setElements] = useState<IElementData[]>([]);

  // Etats pour gérer les modal de preview
  const [showLoginPageElementsPreview, setShowLoginPageElementsPreview] =
    useState(false);
  const [showMainPagePreview, setShowMainPagePreview] = useState(false);

  const methods = useForm({
    defaultValues: {
      loginButtonText: themeData?.login_button_text || t('auth.login'),
    },
  });

  const loginButtonText: string =
    methods.getValues('loginButtonText') ?? t('auth.login');

  const refetchWhiteLabel = async () => {
    updateTitleHeader(`${t('settings.white_label.title')}`);
    refreshHeader(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const objectToSend: IObjectWhiteLabelToSend = {
      images,
      elements,
      loginButtonText,
    };
    const data = await updateTheme(userView?.entity_id || 0, objectToSend);
    if (data) {
      updateThemeData(data.theme);
    }
    setIsLoading(false);
  };

  const actionsButtons = useMemo<JSX.Element>(
    () => (
      <div className="flex space-x-4">
        <ButtonOpx label={t('buttons.cancel')} type="secondary" />
        <ButtonOpx
          label={t('settings.white_label.apply_modifications')}
          type="primary"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </div>
    ),
    [t, images, elements, isLoading]
  );

  useEffect(() => {
    refetchWhiteLabel();
  }, []);

  return (
    <Card
      title={t('settings.white_label.card_title')}
      addClass="w-full"
      actionButtons={actionsButtons}
    >
      <FormProvider {...methods}>
        <div>
          <LoginPageElements
            onUpdateImage={setImages}
            setShowLoginPageElementsPreview={setShowLoginPageElementsPreview}
          />
          {showLoginPageElementsPreview && (
            <LoginPageElementsPreview
              elements={elements}
              loginButtonText={loginButtonText}
              images={images}
              setShowLoginPageElementsPreview={setShowLoginPageElementsPreview}
            />
          )}
          <ButtonElements
            onUpdateElement={setElements}
            setShowMainPagePreview={setShowMainPagePreview}
          />
          <MainMenuElements
            onUpdateElement={setElements}
            onUpdateImage={setImages}
          />
          {showMainPagePreview && (
            <MainPagePreview
              elements={elements}
              setShowMainPagePreview={setShowMainPagePreview}
            />
          )}
        </div>
      </FormProvider>
    </Card>
  );
}

export { WhiteLabel };
