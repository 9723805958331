import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@context/globalContext';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { UpdateWorksitePrimesModal } from '@models/worksites/components/worksiteDetails/worksiteInformations/UpdateWorksitePrimesModal';
import {
  postWorksiteUpdateAmounts,
  updateWorksitePrimes,
} from '@models/worksites/apiRequests/worksitesRequests';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { InputTypes } from '@utils/utils';
import { Card } from '@components/Card';
import ActionButtons from '@models/worksites/components/worksiteDetails/worksiteInformations/ActionButtons';
import {
  canEditWorksite,
  getAhGeneralData,
  totalBonus,
} from '@models/worksites/utils/utils';
import { beneficiaryData } from '@models/worksites/utils/tab';
import { OperationTypeEnum } from '@utils/enums';
import { findKeyByValue, fiscalReferenceIsNotValid } from '@utils/functions';
import { IAidesData } from '@models/worksites/utils/worksitesTypes';
import { AuthContext } from '@context/authContext';

interface ICardBeneficiaryProps {
  iconsList: { [x: string]: JSX.Element };
}

type BeneficiaryData =
  | {
      [x: string]: string;
    }
  | undefined;

function CardBeneficiary({ iconsList }: ICardBeneficiaryProps) {
  const { t } = useTranslation();

  const { worksiteDetails, updateWorksiteDetails, updateAhGeneralData } =
    useContext(WorksitesContext);
  const { globalEnum } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const { beneficiary, housing: worksiteHousing } = worksiteDetails;
  const isCreator = user?.current_entity_type === worksiteDetails.created_by;
  const { precarity_type, occupation_type } = globalEnum;

  const methods = useForm();
  const {
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = methods;
  const haveFormErrors = Object.keys(errors).length > 0;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [newPayload, setNewPayload] = useState<{
    [x: string]: string | number;
  }>();

  const updateData = async (res: any, beneficiaryDatas: BeneficiaryData) => {
    const worksiteRes = await updateWorksitePrimes(
      String(worksiteDetails.id),
      res,
      beneficiaryDatas
    );

    if (worksiteRes.data) {
      updateWorksiteDetails(worksiteRes.data);
      await getAhGeneralData(worksiteDetails.id, updateAhGeneralData);
    } else setNewPayload(undefined);
  };

  const isB2b = worksiteDetails.operation_type === OperationTypeEnum.B2B;

  const getData = async (
    aidesData: IAidesData,
    beneficiaryDatas: BeneficiaryData
  ) => {
    if (!isLoadingUpdate) {
      setIsLoadingUpdate(true);

      if (!isB2b) {
        const res = await postWorksiteUpdateAmounts(
          String(worksiteDetails.id),
          aidesData
        );

        const totalPrimes = totalBonus(worksiteDetails.worksites_operations);

        if (Number(res.totalPrimesEuros) !== totalPrimes) {
          setNewPayload(res);
        } else if (res) {
          await updateData(res, beneficiaryDatas);
        }
      } else {
        await updateData([], beneficiaryDatas);
      }

      setIsEditMode(false);
      setIsLoadingUpdate(false);
    }
  };

  const noChange = (data: FieldValues) => {
    if (worksiteDetails.operation_type !== OperationTypeEnum.B2B) {
      if (data.beneficiary.firstname !== beneficiary.firstname) return false;
      if (data.beneficiary.lastname !== beneficiary.lastname) return false;
    } else if (data.beneficiary.company_name !== beneficiary.company_name)
      return false;

    const fiscalDeclaration = worksiteDetails.fiscalDeclarations?.[0];

    const fiscalReference = data.beneficiary.fiscal_reference || '';
    const fiscalNumber = data.beneficiary.fiscal_number || '';

    return (
      data.beneficiary.email === beneficiary.email &&
      data.beneficiary.mobile_phone === beneficiary.mobile_phone &&
      data.beneficiary.address.address === beneficiary.address.address &&
      data.beneficiary.address.city === beneficiary.address.city &&
      data.beneficiary.address.postal_code ===
        beneficiary.address.postal_code &&
      data.incomes === precarity_type[worksiteDetails.precarity_type] &&
      data.persons === String(worksiteHousing.persons) &&
      data.client === occupation_type[worksiteHousing.occupation_type] &&
      fiscalReference === fiscalDeclaration?.fiscal_reference &&
      fiscalNumber === fiscalDeclaration?.fiscal_number
    );
  };

  const handleError = (data: FieldValues) => {
    const fiscalReference = data.beneficiary.fiscal_reference || '';
    const fiscalNumber = data.beneficiary.fiscal_number || '';

    if (data.incomes !== precarity_type[4] && !isB2b) {
      const referenceError =
        fiscalReference.length < 13 ||
        fiscalReferenceIsNotValid(fiscalReference);
      const numberError = fiscalNumber.length !== 13;

      if (
        (fiscalNumber === '' && fiscalReference !== '') ||
        (fiscalNumber !== '' && numberError)
      ) {
        setError('beneficiary.fiscal_number', {
          message: `${t(
            'worksite_creation.create_worksite.tax_household.error_length_tax_number'
          )}`,
        });
      }

      if (
        (fiscalNumber !== '' && fiscalReference === '') ||
        (fiscalReference !== '' && referenceError)
      ) {
        const referenceMessage =
          fiscalReference.length < 13
            ? 'error_length_notice_reference'
            : 'error_year_notice_reference';

        setError('beneficiary.fiscal_reference', {
          message: `${t(
            `worksite_creation.create_worksite.tax_household.${referenceMessage}`
          )}`,
        });
      }
      if (
        (fiscalNumber !== '' && referenceError) ||
        (fiscalReference !== '' && numberError)
      )
        return true;
    }

    clearErrors();

    return false;
  };

  const onSubmit = (data: FieldValues) => {
    if (!handleError(data)) {
      const ownerValue = Number(findKeyByValue(occupation_type, data.client));

      let owner = 'other';

      if (ownerValue === 1) owner = 'owner';
      if (ownerValue === 3) owner = 'landlord-owner';

      const aidesData: IAidesData = {
        general_owner: owner,
        general_income_precarityType: findKeyByValue(
          precarity_type,
          data.incomes
        ),
        general_persons: data.persons,
      };

      if (!noChange(data)) getData(aidesData, data.beneficiary);
      else setIsEditMode(false);
    }
  };

  const beneficiaryInputs = beneficiaryData(
    worksiteDetails,
    t,
    isEditMode,
    iconsList,
    globalEnum,
    watch('incomes')
  );

  const handleCancel = () => {
    setIsEditMode(false);
    reset(); // Reset the form to its initial values
  };

  useEffect(() => {
    if (!isEditMode) setIsLoadingUpdate(false);
  }, [isEditMode]);

  return (
    <>
      <Card
        title={t('worksites.beneficiary.title')}
        addClass="h-[max-content] w-full"
        dataTestId="worksite_beneficiary"
        actionButtons={
          user &&
          canEditWorksite(worksiteDetails.status, user.entity_type) &&
          isCreator ? (
            <ActionButtons
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              onSubmit={handleSubmit(onSubmit)}
              isLoading={isLoadingUpdate}
              disabled={haveFormErrors || isLoadingUpdate}
              onCancel={handleCancel}
            />
          ) : undefined
        }
      >
        <FormProvider {...methods}>
          <div className="grid gap-2 grid-cols-2 pb-[2rem] w-full">
            {beneficiaryInputs.top.map((input) =>
              input.empty ? null : (
                <DynamicInputEdit
                  key={input.name}
                  isEditMode={input.isEditable || false}
                  name={input.name || ''}
                  inputType={input.inputType || InputTypes.TEXT}
                  label={input.label || ''}
                  initialValue={input.initialValue}
                  addClass="w-full mt-4"
                  icon={input.icon}
                  maxLength={input.maxLength}
                  required={!input.notRequired}
                />
              )
            )}
          </div>
          {worksiteDetails.operation_type !== OperationTypeEnum.B2B && (
            <div className="grid gap-2 grid-cols-2 pb-4 pt-[1rem] border-t w-full">
              {beneficiaryInputs.bottom.map((input) => (
                <DynamicInputEdit
                  key={input.name}
                  isEditMode={input.isEditable || false}
                  name={input.name || ''}
                  inputType={input.inputType || InputTypes.TEXT}
                  label={input.label || ''}
                  options={input.options}
                  initialValue={input.initialValue}
                  addClass="w-full mt-4"
                  icon={input.icon}
                  required
                />
              ))}
            </div>
          )}
        </FormProvider>
      </Card>

      {newPayload && (
        <UpdateWorksitePrimesModal
          onError={() => setNewPayload(undefined)}
          onClickCancel={() => setNewPayload(undefined)}
          newPayload={newPayload}
          setNewPayload={setNewPayload}
        />
      )}
    </>
  );
}

export { CardBeneficiary };
