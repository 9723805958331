import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { WorksitesList } from '@models/worksites/components/WorksitesList';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { initialStateWorksiteDetails } from '@utils/initialState';
import { GlobalContext } from '@context/globalContext';
import { WorksiteDetails } from '@models/worksites/components/WorksiteDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '@context/authContext';
import { toast } from 'react-toastify';
import { HeaderContext } from '@context/headerContext';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { WORKSITES_ROUTES_WITH_ID } from '@utils/routesUrls';
import { IAhExtraDataItem } from '../types/globalTypes';

interface IWorksitesProps {
  adminMode?: boolean;
}

function Worksites({ adminMode }: IWorksitesProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { globalEnum } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const { refreshHeader } = useContext(HeaderContext);

  const [worksitesListDatas, setWorksitesListDatas] = useState<
    IWorksiteDetails[]
  >([]);
  const [worksiteId, setWorksiteId] = useState<string>(id || '');
  const [worksiteDetails, setWorksiteDetails] = useState<IWorksiteDetails>(
    initialStateWorksiteDetails
  );
  const [ahGeneralData, setAhGeneralData] = useState<IAhExtraDataItem[]>();
  const [forceOpenDocumentsTab, setForceOpenDocumentsTab] =
    useState<boolean>(false);

  const isWorksitesList = useMemo(() => !id, [id]);

  const worksitesContextValue = useMemo(
    () => ({
      worksitesListDatas,
      updateWorksitesListDatas: setWorksitesListDatas,
      worksiteId,
      updateWorksiteId: setWorksiteId,
      worksiteDetails,
      updateWorksiteDetails: setWorksiteDetails,
      ahGeneralData,
      updateAhGeneralData: setAhGeneralData,
      forceOpenDocumentsTab,
      updateForceOpenDocumentsTab: setForceOpenDocumentsTab,
    }),
    [
      worksitesListDatas,
      worksiteId,
      globalEnum,
      worksiteDetails,
      ahGeneralData,
      forceOpenDocumentsTab,
    ]
  );

  const cantAccessWorksites = useMemo(
    () => !user?.permissions_names.includes('gerer-chantier'),
    [user]
  );

  const clearWorksiteDatas = () => {
    setWorksiteId('');
    setWorksiteDetails(initialStateWorksiteDetails);
    setAhGeneralData([]);
    setForceOpenDocumentsTab(false);
  };

  useEffect(() => {
    if (!isWorksitesList) {
      // redirect to nouveau-chantier:id if ever status is still in creation
      if (worksiteDetails.status === WORKSITE_STATUS.WORKSITE_CREATION)
        navigate(WORKSITES_ROUTES_WITH_ID(id as string).WORKSITES_NEW_ID, {
          replace: true,
        });

      setWorksiteId(id as string);
    } else {
      clearWorksiteDatas();
    }
  }, [isWorksitesList, id, worksiteDetails]);

  useEffect(() => {
    if (user && cantAccessWorksites) {
      toast(t('global.no_permission'), {
        type: 'error',
      });
      navigate('/', { replace: true });
    }
    refreshHeader();

    // (window as any).Beacon('close');
  }, []);

  return (
    <WorksitesContext.Provider value={worksitesContextValue}>
      {!cantAccessWorksites &&
        (isWorksitesList ? (
          <WorksitesList adminMode={adminMode} />
        ) : (
          <WorksiteDetails adminMode={adminMode} />
        ))}
    </WorksitesContext.Provider>
  );
}

export { Worksites };

Worksites.defaultProps = {
  adminMode: false,
};
