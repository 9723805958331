import { v4 } from 'uuid';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

interface ISelectOptions {
  options: { label: string; value: string }[];
  onClick: (option: { label: string; value: string }) => void;
  notInListAction?: () => void;
  notInListText?: string;
  dataTestId?: string;
  isLoading?: boolean;
  loadingItems?: number;
}

function SelectOptions({
  options,
  onClick,
  dataTestId,
  notInListAction,
  notInListText,
  isLoading,
  loadingItems,
}: ISelectOptions): JSX.Element {
  return (
    <div data-test-id="options_list">
      {options.map((option) => (
        <button
          type="button"
          onClick={() => onClick(option)}
          className="flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey hover:font-medium"
          key={option.label}
          data-test-id={dataTestId}
        >
          {option.label}
        </button>
      ))}
      {notInListAction && (
        <button
          type="button"
          onClick={notInListAction}
          className="flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey rounded-b-default font-normal text-primaryText hover:font-medium"
          data-test-id="not_in_list_cta"
        >
          {notInListText}
        </button>
      )}
      {isLoading &&
        [...Array(loadingItems || 10)].map(() => (
          <div
            key={v4()}
            className="w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey"
          >
            <LoaderSkeleton height="1.5rem" />
          </div>
        ))}
    </div>
  );
}

SelectOptions.defaultProps = {
  dataTestId: '',
  notInListAction: undefined,
  notInListText: '',
  isLoading: false,
  loadingItems: 10,
};

export { SelectOptions };
