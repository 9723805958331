import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useMemo,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  getTheme,
  getThemesList,
} from '@models/settings/apiRequests/settingsRequests';
import {
  IThemeResponse,
  IThemesList,
  IThemeInList,
} from '@models/settings/utils/settingsTypes';
import { applyTheme } from '@assets/color';
import { GlobalContext } from '@context/globalContext';
import { ENTITY_TYPES } from '@utils/roles';
import { AuthContext } from './authContext';

/**
 * Interface pour les valeurs du contexte des thèmes.
 */
interface ThemeContextProps {
  themeData: IThemeResponse | undefined;
  updateThemeData: (themeDataToUpdate: IThemeResponse | undefined) => void;
  themesList: IThemesList;
  updateThemesList: Dispatch<SetStateAction<IThemesList>>;
  themeSlug: string | undefined;
  updateThemeSlug: Dispatch<SetStateAction<string | undefined>>;
}

/**
 * Création du contexte des thèmes.
 */
export const ThemeContext = createContext({} as ThemeContextProps);

/**
 * Fournisseur de contexte pour gérer les thèmes de l'application.
 * @param {ReactNode} children - Les composants enfants.
 * @returns {JSX.Element} Le fournisseur de contexte.
 */
function ThemeContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { userView, partnersMenu, globalEnum, updateLayoutIsLoading } =
    useContext(GlobalContext);
  const { token } = useContext(AuthContext);
  const [themeData, setThemeData] = useState<IThemeResponse>();
  const [themesList, setThemesList] = useState<IThemesList>([]);
  const [themeSlug, setThemeSlug] = useState<string | undefined>();
  const [datasLoaded, setDatasLoaded] = useState(false);

  /**
   * Met à jour les données du thème et applique le thème.
   * @param {IThemeResponse | undefined} themeDataToUpdate - Les données du thème à mettre à jour.
   */
  const updateThemeData = (
    themeDataToUpdate: IThemeResponse | undefined
  ): void => {
    setThemeData(themeDataToUpdate);
    applyTheme(themeDataToUpdate, setThemeData);
  };

  const resetThemeData = () => {
    updateThemeData(undefined);
    setThemeSlug(undefined);
  };

  /**
   * Récupère les données d'un thème spécifique par ID d'entité.
   * @param {number} entityId - L'ID de l'entité.
   */
  const getThemeData = async (entityId: number): Promise<void> => {
    const response = await getTheme(entityId);
    if (response) {
      if (response.status === 200) {
        updateThemeData(response.data.data.theme);
        setThemeSlug(response.data.data.slug);
      } else {
        resetThemeData();
      }
    } else {
      resetThemeData();
    }
    setDatasLoaded(true);
  };

  /**
   * Récupère la liste des thèmes disponibles.
   */
  const getThemesListData = async (): Promise<void> => {
    const response = await getThemesList();
    if (response) {
      const list = response.data.data;
      if (list.themes && list.themes.length > 0) {
        const themes = list.themes.filter(
          (themeInList: IThemeInList) => themeInList.theme
        );
        if (themes.length > 0) {
          if (themes.length === 1) {
            updateThemeData(themes[0].theme);
            setThemeSlug(themes[0].company_name.toLowerCase());
          } else {
            const themeId = localStorage.getItem('whitelabel_id');
            if (themeSlug) {
              const theme = themes.find(
                (themeInList: IThemeInList) =>
                  themeInList.company_name.toLowerCase() ===
                  themeSlug.toLowerCase()
              );
              updateThemeData(theme?.theme);
            } else if (themeId) {
              const theme = themes.find(
                (themeInList: IThemeInList) =>
                  themeInList.entity_id === parseInt(themeId, 10)
              );
              setThemeSlug(theme?.company_name.toLowerCase());
              updateThemeData(theme?.theme);
            }
          }
          setThemesList(themes);
        } else {
          resetThemeData();
        }
      } else {
        resetThemeData();
      }
    } else {
      resetThemeData();
    }
    setDatasLoaded(true);
  };

  useEffect(() => {
    if (
      Object.keys(globalEnum).length > 1 &&
      (!token || (partnersMenu.length > 0 && datasLoaded))
    ) {
      updateLayoutIsLoading(false);
    }
  }, [globalEnum, partnersMenu, datasLoaded]);

  /**
   * Effet secondaire qui réagit aux changements de la vue utilisateur pour gérer les thèmes.
   */
  useEffect(() => {
    if (userView) {
      if (userView.entity_type === ENTITY_TYPES.INSTALLATEUR) {
        setThemesList([]);
        getThemesListData();
      } else if (userView.entity_id) {
        setThemesList([]);
        getThemeData(userView.entity_id);
      }
    }
  }, [userView]);

  /**
   * Mémoïsation des valeurs du contexte pour optimiser les performances.
   */
  const contextValue = useMemo(
    () => ({
      themeData,
      themesList,
      themeSlug,
      updateThemeData,
      updateThemesList: setThemesList,
      updateThemeSlug: setThemeSlug,
    }),
    [themeData, themesList, themeSlug]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContextProvider };
