import { Modal } from '@components/Modal';
import { WORKSITES_ROUTES } from '@utils/routesUrls';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ModalErrorIsFromApi() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Modal
      title={t('global.impossible_access_worksite')}
      textBtnConfirm="Retour"
      onConfirmClick={() => {
        navigate(WORKSITES_ROUTES.WORKSITES);
        localStorage.removeItem('worksite_in_risky_state');
        localStorage.removeItem('error_worksite_from_api');
      }}
    >
      <p className="mt-4">{t('global.error_worksite_created_from_api')}</p>
    </Modal>
  );
}

export default ModalErrorIsFromApi;
