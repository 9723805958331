/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable no-await-in-loop */
import { pdfjs } from 'react-pdf';
import jsPDF from 'jspdf';

/**
 * Convertit un fichier PDF en une liste d'images (Data URLs).
 * @param file - Fichier PDF à convertir.
 * @returns Promise contenant les images converties.
 */
export async function convertPdfToImages(file: File): Promise<string[]> {
  const images: string[] = [];
  const arrayBuffer = await file.arrayBuffer();
  const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;

  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum += 1) {
    // eslint-disable-next-line no-await-in-loop
    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: 2 }); // Ajustez l'échelle selon vos besoins
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    if (context) {
      const renderContext = {
        canvasContext: context,
        viewport,
      };

      // eslint-disable-next-line no-await-in-loop
      await page.render(renderContext).promise;
      const dataUrl = canvas.toDataURL('image/png');
      images.push(dataUrl);
    }
  }

  return images;
}

/**
 * Convertit un Data URL en Blob.
 * @param dataUrl - Data URL à convertir.
 * @returns Blob correspondant.
 */
export function dataURLToBlob(dataUrl: string): Blob {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

/**
 * Convertit un fichier en Data URL.
 * @param file - Fichier à convertir.
 * @returns Promise contenant le Data URL.
 */
export function fileToDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result && typeof e.target.result === 'string') {
        resolve(e.target.result);
      } else {
        reject(new Error('Erreur lors de la lecture du fichier.'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Crée un PDF à partir d'une liste d'images.
 * @param imageFiles - Tableau de fichiers image.
 * @returns Promise contenant le fichier PDF généré ou `null` en cas d'échec.
 */
export async function createPdfFromImages(
  imageFiles: File[],
  fileName: string
): Promise<File | null> {
  const pdf = new jsPDF();

  for (let i = 0; i < imageFiles.length; i += 1) {
    const file = imageFiles[i];
    const imageData = await fileToDataURL(file);

    const img = new Image();
    img.src = imageData;

    await new Promise<void>((resolve) => {
      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
        if (i < imageFiles.length - 1) {
          pdf.addPage();
        }
        resolve();
      };
    });
  }

  const pdfBlob = pdf.output('blob');
  const pdfFile = new File([pdfBlob], `${fileName}`, {
    type: 'application/pdf',
  });

  return pdfFile;
}

/**
 * Vérifie la signature d'un fichier PDF.
 * @param file - Fichier à vérifier.
 * @returns Promise résolvant `true` si le fichier est un PDF valide, sinon `false`.
 */
export function verifyPdfSignature(file: File): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target || !(e.target.result instanceof ArrayBuffer)) {
        reject();
        return;
      }
      const uint = new Uint8Array(e.target.result);
      const bytes: string[] = [];
      uint.forEach((byte) => {
        bytes.push(String.fromCharCode(byte));
      });
      const header = bytes.join('');

      // Vérifie si le fichier commence par "%PDF-"
      if (header.includes('%PDF-')) {
        resolve(true);
      } else {
        resolve(false);
      }
    };

    reader.onerror = () => {
      reject();
    };

    // Lire les premiers 5 octets du fichier pour vérifier la signature PDF
    reader.readAsArrayBuffer(file.slice(0, 5));
  });
}
