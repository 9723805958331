import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { InputTypes } from '@utils/utils';
import { ColorCube } from '@components/atomic/ColorCube';
import { TrashIcon } from '@assets/images/svgComponents';
import { red } from '@assets/color';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { IGestureField } from '@models/mar/utils/marTypes';
import { ILabelValue } from '../../../../../../types/globalTypes';

interface IOneGestureProps {
  prefixScenario: string;
  index: number;
  operationsList: ILabelValue[] | undefined;
  nbFields: number;
  removeGesture: (index: number) => void;
}

function OneGesture({
  prefixScenario,
  index,
  operationsList,
  nbFields,
  removeGesture,
}: IOneGestureProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredOperations, setFilteredOperations] = useState<
    ILabelValue[] | undefined
  >(operationsList);

  const gestures: IGestureField[] = watch(`${prefixScenario}gesture`);

  const operationsSelected = useMemo(() => {
    return gestures ? gestures.map((gesture) => gesture.geste_id) : [];
  }, [gestures]);

  const prefixGesture = `${prefixScenario}gesture.${index}.`;
  const operationName = `${prefixGesture}geste_id`;
  const costHTName = `${prefixGesture}cost_ht`;
  const costTTCName = `${prefixGesture}cost_ttc`;

  const preSelectedOperation =
    gestures[index]?.geste_id && operationsList
      ? operationsList.find(
          (op) => op.value === gestures[index]?.geste_id.toString()
        )
      : undefined;

  const handleDeleteGesture = useCallback(
    (indexGesture: number) => {
      removeGesture(indexGesture);
    },
    [removeGesture]
  );

  useEffect(() => {
    if (operationsList) {
      const filtered = operationsList.filter(
        (op) =>
          !operationsSelected.includes(op.value) &&
          op.label.toLowerCase().startsWith(searchValue.toLowerCase())
      );
      setFilteredOperations(filtered);
    }
  }, [operationsList, operationsSelected, searchValue]);

  /**
   * @function callbackOnSearchOperation
   * @description Met à jour la valeur de recherche pour filtrer les opérations.
   * @param {string} value - La valeur saisie par l'utilisateur.
   */
  const callbackOnSearchOperation = (value: string) => {
    setSearchValue(value);
  };

  return (
    <div className="w-full flex items-center">
      <div className="grid grid-cols-10 gap-4 text-[.75rem] items-center">
        <div className="col-span-6">
          <DynamicInputEdit
            isEditMode
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.scenario_gestures'
            )}`}
            name={operationName}
            inputType={InputTypes.SELECT}
            canSearchInOptions
            options={filteredOperations}
            placeholder=""
            initialValue={preSelectedOperation?.label}
            required
            noUnregister
            callbackOnSearch={callbackOnSearchOperation}
          />
        </div>
        <div className="col-span-2">
          <DynamicInputEdit
            isEditMode
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.cost_ht'
            )}`}
            name={costHTName}
            inputType={InputTypes.NUMBER}
            placeholder=""
            noUnregister
          />
        </div>
        <div className="col-span-2">
          <DynamicInputEdit
            isEditMode
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.cost_ttc'
            )}`}
            name={costTTCName}
            inputType={InputTypes.NUMBER}
            placeholder=""
            required
            noUnregister
          />
        </div>
      </div>

      {nbFields > 1 && (
        <ColorCube
          size="2rem"
          numberOrIcon={<TrashIcon />}
          color={red}
          backgroundColor="transparent"
          onClick={() => handleDeleteGesture(index)}
          opacity
        />
      )}
    </div>
  );
}

export default OneGesture;
