import { InputText } from '@components/atomic/inputs/InputText';
import { useTranslation } from 'react-i18next';
import {
  ICustomInputIds,
  IFormAddressDatas,
} from '@components/formAddress/FormAddress';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { useEffect, useState } from 'react';

interface IManualAddressForm {
  datas: IFormAddressDatas;
  updateDatas: (updates: Partial<IFormAddressDatas>) => void;
  customInputIds: ICustomInputIds;
  disabled?: boolean;
}

/**
 * Composant pour le formulaire d'adresse manuelle.
 *
 * @param {IManualAddressForm} props - Les propriétés du composant.
 * @returns {JSX.Element} Le composant JSX.
 */
function ManualAddressForm({
  datas,
  updateDatas,
  customInputIds,
  disabled,
}: IManualAddressForm) {
  const { t } = useTranslation();
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Initialisation du hook usePlacesAutocomplete pour la ville
  const {
    value: cityInput,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* 
        Limiter les suggestions aux villes uniquement en France
        types: ['(cities)'] permet de n'obtenir que des villes
      */
      types: ['(cities)'],
      componentRestrictions: { country: 'fr' },
    },
    debounce: 300,
  });

  /**
   * Gère la sélection d'une suggestion de ville.
   *
   * @param {string} description - La description complète de la ville sélectionnée.
   */
  const handleSelect = async (description: string) => {
    // Supprimez ", France" de la description si présent
    const ville = description.replace(/,\s*France$/, '');

    // Met à jour le champ de saisie avec la ville sélectionnée
    setValue(ville, false);
    clearSuggestions();
    setShowSuggestions(false);
    updateDatas({ city: ville });
  };

  /**
   * Gère le changement de la saisie dans le champ de la ville.
   *
   * @param {string} value - La nouvelle valeur saisie.
   */
  const handleCityChange = (value: string) => {
    setValue(value);
    if (value.length > 2) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
    updateDatas({ city: value });
  };

  /**
   * Gère la perte de focus du formulaire pour effectuer une géocodification.
   */
  const handleSearchOnBlur = async () => {
    if (
      datas.streetNumber &&
      datas.street &&
      datas.zipCode &&
      datas.zipCode.length === 5 &&
      datas.city
    ) {
      const address = `${datas.streetNumber} ${datas.street} ${datas.zipCode} ${datas.city} France`;
      try {
        const results = await getGeocode({ address });
        const latLng = await getLatLng(results[0]);
        updateDatas({
          latitude: latLng.lat,
          longitude: latLng.lng,
          country: 'France',
        });
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des coordonnées géographiques:',
          error
        );
      }
    }
  };

  /**
   * Assure que le pays est toujours défini sur "France".
   */
  useEffect(() => {
    if (datas.country !== 'France') {
      updateDatas({ country: 'France' });
    }
  }, [datas.country, updateDatas]);

  return (
    <div className="flex flex-col space-y-2" onBlur={handleSearchOnBlur}>
      <div className="flex space-x-2">
        <div className="flex-1">
          <InputText
            typeNumber
            placeholder={t('forms.address.manual.number')}
            id={customInputIds.streetNumber}
            name={customInputIds.streetNumber}
            value={datas.streetNumber}
            onChange={(value) => updateDatas({ streetNumber: value as string })}
            valid={datas.streetNumber !== ''}
            dataTestId="input_text_address_manual_number"
            disabled={disabled}
            addClassToInput={disabled ? 'bg-backgroundBody' : ''}
          />
        </div>
        <div className="flex-1">
          <InputText
            placeholder={t('forms.address.manual.street')}
            id={customInputIds.street}
            name={customInputIds.street}
            required
            value={datas.street}
            onChange={(value) => updateDatas({ street: value as string })}
            valid={datas.street !== ''}
            dataTestId="input_text_address_manual_street"
            disabled={disabled}
            addClassToInput={disabled ? 'bg-backgroundBody' : ''}
          />
        </div>
      </div>

      <InputText
        typeNumber
        placeholder={t('forms.address.manual.zip')}
        id={customInputIds.zipCode}
        name={customInputIds.zipCode}
        required
        value={datas.zipCode}
        onChange={(value) => updateDatas({ zipCode: value as string })}
        valid={datas.zipCode !== '' && datas.zipCode.length === 5}
        dataTestId="input_text_address_manual_zip"
        disabled={disabled}
        addClassToInput={disabled ? 'bg-backgroundBody' : ''}
      />

      <div className="relative">
        <InputText
          placeholder={t('forms.address.manual.city')}
          id={customInputIds.city}
          name={customInputIds.city}
          required
          value={cityInput}
          onChange={(value) => handleCityChange(value as string)}
          valid={datas.city !== ''}
          dataTestId="input_text_address_manual_city"
          disabled={disabled}
          addClassToInput={disabled ? 'bg-backgroundBody' : ''}
        />
        {showSuggestions && status === 'OK' && (
          <ul className="absolute z-10 w-full bg-white border border-borderGrey rounded-default mt-1 max-h-60 overflow-y-auto">
            {data.map(({ place_id, description }) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <li
                key={place_id}
                className="px-4 py-2 hover:bg-backgroundBody cursor-pointer text-[.875rem]"
                onClick={() => handleSelect(description)}
                data-test-id={`suggestion_${description}`}
              >
                {description}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Champ pour le pays supprimé car toujours "France" */}
    </div>
  );
}

export { ManualAddressForm };

ManualAddressForm.defaultProps = { disabled: false };
