import { green, red, blueOpx, backgroundGrey } from '@assets/color';
import {
  AddIcon,
  ChatBubbleIcon,
  CheckIcon,
  ChevronDownIcon,
  CrossIcon,
} from '@assets/images/svgComponents';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { IChange } from 'types/changesTypes';
import { useContext, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { getListChanges } from '@apiRequests/changesRequest';
import { GlobalContext } from '@context/globalContext';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';
import { useTranslation } from 'react-i18next';

import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { OneChangeCard } from './OneChangeCard';

interface IListChangesCard {
  loadNote?: boolean;
}

function ListChangesCard({ loadNote }: IListChangesCard) {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const {
    listChanges,
    isLoadingListRequestOrChange,
    updateNumberPageChangesList,
    numberPageChangesList,
    totalChangesList,
    updateListChanges,
    updateTotalChangesList,
    demandIdForNote,
    refreshRequestsAndChanges,
    updateRequestOrChangeTab,
    updateRequestOrChangeEnumInCard,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { route } = useContext(GlobalContext);
  const { t } = useTranslation();

  // Create a new filtered list if demand id sent or return all
  const toLoadListChanges = loadNote
    ? listChanges.filter((demand) => demand.id === demandIdForNote)
    : listChanges;

  const colorChange = (change: IChange) => {
    switch (change.type) {
      case 'message':
        return blueOpx;
      case 'new':
        return blueOpx;
      case 'refused':
        return red;
      case 'validated':
        return green;
      default:
        return blueOpx;
    }
  };

  const iconChange = (change: IChange) => {
    switch (change.type) {
      case 'message':
        return <ChatBubbleIcon />;
      case 'new':
        return <AddIcon />;
      case 'refused':
        return <CrossIcon />;
      case 'validated':
        return <CheckIcon />;
      default:
        return <AddIcon />;
    }
  };

  const infiniteScroll = () => {
    if (listInnerRef.current) {
      // eslint-disable-next-line no-shadow
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight < scrollHeight + 1 &&
        scrollTop + clientHeight > scrollHeight - 1 &&
        !isLoadingList &&
        totalChangesList > listChanges.length
      ) {
        setIsLoadingList(true);
        updateNumberPageChangesList(numberPageChangesList + 1);
        const dataPost = {
          pagination: {
            page: numberPageChangesList + 1,
            perPage: 15,
          },
          relation_id: parseInt(route.to.split('/')[2], 10),
          relation_type: 'worksite',
        };
        getListChanges(
          updateListChanges,
          updateTotalChangesList,
          listChanges,
          dataPost,
          setIsLoadingList
        );
        listInnerRef.current.scrollTop = scrollTop - 2.5;
      }
    }
  };

  useEffect(() => {
    refreshRequestsAndChanges();
  }, [demandIdForNote]);

  return isLoadingListRequestOrChange ? (
    <div className="flex flex-col space-y-[.5rem]">
      <LoaderSkeleton height="6rem" backgroundColor={backgroundGrey} />
      <LoaderSkeleton height="6rem" />
      <LoaderSkeleton height="6rem" />
      <LoaderSkeleton height="6rem" />
    </div>
  ) : (
    <div
      onScroll={() => infiniteScroll()}
      ref={listInnerRef}
      className="py-[1.5rem] pl-[1rem] flex flex-col space-y-[1rem] overflow-y-scroll  max-h-[70vh]"
    >
      {toLoadListChanges.map((change) => (
        <OneChangeCard
          key={v4()}
          lastChange={
            toLoadListChanges.length === toLoadListChanges.indexOf(change) + 1
          }
          isMessage={change.type === 'message'}
          color={colorChange(change)}
          icon={iconChange(change)}
          internalMessages={change.internal_messages}
          dateChange={change.created_at}
          isDocument={
            change.internal_messages &&
            change.internal_messages.length > 0 &&
            change.internal_messages[0].action === 1
          }
          isAccessToRequest={
            change.internal_messages &&
            change.internal_messages.length > 0 &&
            change.internal_messages[0].action === 2
          }
          idRequest={change.demand_id}
          idChange={change.id}
        />
      ))}

      {loadNote && (
        <button
          type="button"
          onClick={() => {
            updateRequestOrChangeTab(null);
            updateRequestOrChangeEnumInCard(
              RequestOrMessageOrChangeEnum.CHANGE
            );
          }}
          className="bg-white rounded-default w-full p-[1rem] pt-0"
        >
          <p className="py-[1px] pl-[1rem] pr-[.25rem] bg-backgroundBody rounded-[4px] flex justify-between m-auto items-center text-[.875rem] mt-[.5rem] font-medium ">
            {t('requests_and_changes.access_to_list_activities')}
            <ColorCube
              size="1rem"
              color={blueOpx}
              opacity
              backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
              numberOrIcon={<ChevronDownIcon className="rotate-[-90deg]" />}
            />
          </p>
        </button>
      )}

      {isLoadingList && (
        <LoaderSkeleton backgroundColor={blueOpx} height=".2rem" />
      )}
    </div>
  );
}

ListChangesCard.defaultProps = {
  loadNote: undefined,
};

export { ListChangesCard };
