import React from 'react';

interface SquarePickerProps {
  colors: string[];
  selectedColor: string;
  onColorChange: (color: string) => void;
}

function SquarePicker({
  colors,
  selectedColor,
  onColorChange,
}: SquarePickerProps) {
  const squares = Array.from({ length: 12 }, (_, index) => {
    const color = colors[index] || '#FFFFFF'; // Blanc par défaut si pas assez de couleurs
    const isClickable = index < colors.length;

    return (
      <button
        type="button"
        key={index}
        onClick={() => isClickable && onColorChange(color)}
        style={{
          backgroundColor: color,
        }}
        className={`rounded-default w-8 h-8 ${
          isClickable ? '' : 'cursor-not-allowed'
        } border ${
          selectedColor === color ? 'border-black' : 'border-borderGrey'
        }`}
      />
    );
  });

  return <div className="grid grid-cols-6 gap-2">{squares}</div>;
}

export default SquarePicker;
