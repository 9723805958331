import { blueOpx, grey, textGrey } from '@assets/color';
import { HomeIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { Modal } from '@components/Modal';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { IElementData } from '@models/settings/utils/settingsTypes';
import { Dispatch, SetStateAction, useMemo } from 'react';
import HousePreviewImg from '@assets/images/house-preview.png';
import { convertHexToRGBA } from '@utils/functions';
import { useTranslation } from 'react-i18next';

interface MainPagePreviewProps {
  elements: IElementData[];
  setShowMainPagePreview: Dispatch<SetStateAction<boolean>>;
}

function MainPagePreview({
  elements,
  setShowMainPagePreview,
}: MainPagePreviewProps) {
  const { t } = useTranslation();

  const primaryButton = useMemo(() => {
    return elements.find(
      (elem) => elem.element === THEME_ELEMENTS_TYPES.PRIMARY
    );
  }, [elements]);

  const secondaryButton = useMemo(() => {
    return elements.find(
      (elem) => elem.element === THEME_ELEMENTS_TYPES.SECONDARY
    );
  }, [elements]);

  const backgroundColorIcon = convertHexToRGBA(
    secondaryButton?.background_color ?? blueOpx,
    0.4
  );

  return (
    <Modal
      title=""
      btnCancelIsIcon
      lightHeader
      setIsModal={setShowMainPagePreview}
      isFullscreen
    >
      <div className="mx-5">
        <div className="flex justify-between items-center mb-6">
          <div className="text-[1.5rem] font-medium pl-2">
            {t('worksite_creation.simulation.summary.title')}
          </div>
          <div className="flex gap-3">
            <ButtonOpx
              type="nav"
              label={t('global.canceled')}
              color={textGrey}
              backgroundColor={grey}
              backgroundOpacity={0.1}
            />
            <ButtonOpx
              type="nav"
              label={t(
                'buttons.valid_worksite_creation_and_send_to_beneficiary'
              )}
              backgroundColor={secondaryButton?.background_color}
              backgroundOpacity={40}
              color={secondaryButton?.text_color}
            />
            <ButtonOpx
              type="primary"
              label={t('buttons.valid_worksite_creation')}
              backgroundColor={primaryButton?.background_color}
              color={primaryButton?.text_color}
            />
          </div>
        </div>

        <div className="flex w-screen gap-6 pr-12">
          <div className="flex flex-col gap-6 w-1/2">
            {/* Typologie du logement */}
            <div className="border border-borderGrey rounded-default w-full pb-5">
              <div className="text-[1.25rem] font-medium p-5 mb-4 border-b">
                {t('worksites.housing.title')}
              </div>
              <div className="p-5 ml-5 grid grid-cols-2 grid-flow-row gap-4">
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t('worksites.housing.housing_type')} :`,
                      subtitle: 'Maison',
                    },
                  ]}
                  icon={<HomeIcon />}
                  colorIcon={secondaryButton?.text_color}
                  backgroundColorIcon={backgroundColorIcon}
                />
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t('worksites.housing.housing_age')} :`,
                      subtitle: 'Plus de 2 ans',
                    },
                  ]}
                  spaceLeft
                />
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t('worksites.housing.surface_area')} :`,
                      subtitle: '100m²',
                    },
                  ]}
                  spaceLeft
                />
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t('worksites.housing.removal')} :`,
                      subtitle: 'Chaudière non condensation gaz',
                    },
                  ]}
                  spaceLeft
                />
              </div>
            </div>

            {/* Opérations */}
            <div className="border border-borderGrey rounded-default">
              <div className="text-[1.25rem] font-medium p-5 mb-4 border-b">
                {t('worksite_create.simulation.summary.operations')}
              </div>
              <div className="space-y-6 pb-5">
                <div className="p-5 ml-5 grid grid-cols-2 grid-flow-row gap-4">
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.operation.title')} 1 :`,
                        subtitle: 'Pompe à chaleur de type air/eau',
                      },
                    ]}
                    icon={1}
                    colorIcon={secondaryButton?.text_color}
                    backgroundColorIcon={backgroundColorIcon}
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.operation.total_cost')} :`,
                        subtitle: '10 000 €',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.operation.bonus_cee')} :`,
                        subtitle: '4240,11 €',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t(
                          'worksite_creation.global_renovation.cee_calculation'
                        )} :`,
                        subtitle: '615,40 MWhc',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t(
                          'worksite_creation.simulation.summary.fost'
                        )} :`,
                        subtitle: 'BAR-TH-171',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t(
                          'worksite_creation.simulation.summary.bonus_type'
                        )} :`,
                        subtitle: 'Coup de pouce',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.ma_prime_renov')} :`,
                        subtitle: ' 259,89 €',
                      },
                    ]}
                    spaceLeft
                  />
                </div>

                <div className="p-5 ml-5 grid grid-cols-2 grid-flow-row gap-4">
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.operation.title')} 2 :`,
                        subtitle: 'Isolation des murs',
                      },
                    ]}
                    icon={2}
                    colorIcon={secondaryButton?.text_color}
                    backgroundColorIcon={backgroundColorIcon}
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.operation.total_cost')} :`,
                        subtitle: '10 000 €',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.operation.bonus_cee')} :`,
                        subtitle: '1102,40 €',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t(
                          'worksite_creation.global_renovation.cee_calculation'
                        )} :`,
                        subtitle: '160 MWhc',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t(
                          'worksite_creation.simulation.summary.fost'
                        )} :`,
                        subtitle: 'BAR-EN-102',
                      },
                    ]}
                    spaceLeft
                  />
                  <InfosWithIcon
                    infos={[
                      {
                        title: `${t('worksites.ma_prime_renov')} :`,
                        subtitle: '6 000 €',
                      },
                    ]}
                    spaceLeft
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 w-1/2">
            {/* Récapitulatif de l'offre */}
            <div className="border border-borderGrey rounded-default">
              <div className="text-[1.25rem] font-medium p-5 mb-4 border-b">
                {t('worksite_creation.simulation.summary.offer_recap')}
              </div>
              <div className="p-5 ml-5 grid grid-cols-2 grid-flow-row gap-4">
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t(
                        'worksite_creation.simulation.summary.total_cee_bonus'
                      )} :`,
                      subtitle: '5 342,51 €',
                    },
                  ]}
                  icon={<HomeIcon />}
                  colorIcon={secondaryButton?.text_color}
                  backgroundColorIcon={backgroundColorIcon}
                />
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t(
                        'worksite_creation.simulation.summary.total_mpr_bonus'
                      )} :`,
                      subtitle: '9 259,89 €',
                    },
                  ]}
                  spaceLeft
                />
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t(
                        'worksite_creation.simulation.summary.total_cee_volume'
                      )} :`,
                      subtitle: '775,40 MWhc',
                    },
                  ]}
                  spaceLeft
                />
                <InfosWithIcon
                  infos={[
                    {
                      title: `${t(
                        'worksite_creation.simulation.summary.offer_validity_date'
                      )} :`,
                      subtitle: '18/09/2024',
                    },
                  ]}
                  spaceLeft
                />
              </div>
            </div>

            {/* Localisation du chantier */}
            <div className="border border-borderGrey rounded-default">
              <div className="text-[1.25rem] font-medium p-5 mb-4 border-b">
                {t(
                  'worksite_creation.simulation.summary.localisation_worksite'
                )}
              </div>
              <img
                src={HousePreviewImg}
                alt="Localisation du chantier"
                className="rounded-md w-full p-4"
              />
              <p className="ml-4 mb-5">
                {t('worksite_creation.worksite_address')} : 50 Rue de Charenton,
                75011 Paris, France, 10000 Troyes
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default MainPagePreview;
