import { Navigate, useLocation } from 'react-router-dom';
import { AUTH_ROUTES, AUTH_ROUTES_WITH_PARAM } from '@utils/routesUrls';
import { useContext } from 'react';
import { ThemeContext } from '@context/ThemeContext';

function ProtectedRedirect() {
  const location = useLocation();
  const { themeSlug } = useContext(ThemeContext);

  if (themeSlug) {
    return (
      <Navigate
        to={AUTH_ROUTES_WITH_PARAM(themeSlug).LOGIN_WITH_SLUG}
        state={{ from: location.pathname }}
      />
    );
  }
  return (
    <Navigate to={AUTH_ROUTES.LOGIN} state={{ from: location.pathname }} />
  );
}

export default ProtectedRedirect;
