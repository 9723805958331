import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { AUTH_ROUTES } from '@utils/routesUrls';

/**
 * Création d'une instance Axios personnalisée avec des configurations de base.
 */
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BACK_OPX_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Retiré 'X-WhiteLabelId' pour le gérer dynamiquement dans l'intercepteur
  },
  withCredentials: true,
});

// Liste des endpoints pour lesquels les doublons sont autorisés
const ALLOWED_DUPLICATE_ENDPOINTS = [
  '/demands/list',
  '/mar/worksites/energyClass',
  '/mar/aides/prime',
  '/materials/brands',
];

// Gestion des requêtes en attente pour éviter les doublons
const pendingRequests = new Map();

/**
 * Intercepteur de requêtes pour gérer les en-têtes dynamiques et les requêtes en double.
 */
axiosClient.interceptors.request.use((config) => {
  let isAllowedDuplicateEndpoint = false;
  if (config && config.url) {
    isAllowedDuplicateEndpoint = ALLOWED_DUPLICATE_ENDPOINTS.some((endpoint) =>
      config.url?.includes(endpoint)
    );
  }

  // Création d'un nouveau token d'annulation
  const cancelTokenSource = axios.CancelToken.source();
  const modifiedConfig = { ...config, cancelToken: cancelTokenSource.token };

  // Génération d'une clé unique pour la requête
  const key = [modifiedConfig.method, modifiedConfig.url].join('&');

  if (!isAllowedDuplicateEndpoint && pendingRequests.has(key)) {
    const existingCancelToken = pendingRequests.get(key);
    existingCancelToken?.cancel('Duplicate request detected.');
  }

  // Mise à jour ou ajout de la requête en attente
  pendingRequests.set(key, cancelTokenSource);

  // Mise à jour dynamique du header 'X-WhiteLabelId'
  const whiteLabelId = localStorage.getItem('whitelabel_id');
  if (whiteLabelId) {
    modifiedConfig.headers['X-WhiteLabelId'] = whiteLabelId;
  } else {
    delete modifiedConfig.headers['X-WhiteLabelId'];
  }

  return modifiedConfig;
});

/**
 * Intercepteur de réponses pour gérer les erreurs et supprimer les requêtes en attente.
 */
axiosClient.interceptors.response.use(
  (response) => {
    const key = [response.config.method, response.config.url].join('&');
    pendingRequests.delete(key);

    /* if (response.data.exception) {
      toast.error(`${response.data.exception} \n\n${response.data.message}`);
    } */
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) {
      if (error.response) {
        const { data, status } = error.response;
        if (
          data.errors !== undefined &&
          data.errors.includes('Unauthenticated.')
        ) {
          Cookies.remove('token');
          window.location.href = AUTH_ROUTES.LOGIN;
          toast.error(`Vous n'êtes pas autorisé à accéder à la plateforme.`);
        }
        console.error('Request made but the server responded with an error :', {
          data,
          status,
        });
      } else if (error.request) {
        console.error(
          'Request made but no response is received from the server :',
          error.request
        );
      } else {
        console.error(
          'Error occurred while setting up the request :',
          error.message
        );
      }
    }

    const config = error.config;
    if (config) {
      const key = [config.method, config.url].join('&');
      pendingRequests.delete(key);
    }

    return Promise.reject(error);
  }
);

export { axiosClient };
