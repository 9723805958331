import { IPartnerView } from '@models/partners/utils/types/partnersType';
import { createContext, Dispatch, SetStateAction } from 'react';
import {
  INavLinkSidebar,
  IGlobalEnumType,
  IActiveDocument,
  IShowDropDownSortAndFilter,
} from 'types/globalTypes';
import Pusher, { Channel } from 'pusher-js';

interface IProps {
  pusher: Pusher | undefined;
  pusherChannelUser: Channel | undefined;
  roleUser: string;
  sidebarMenuIsOpen: boolean;
  updateSidebarMenuIsOpen: Dispatch<SetStateAction<boolean>>;
  partnersMenu: INavLinkSidebar[];
  updatePartnersMenu: Dispatch<SetStateAction<INavLinkSidebar[]>>;
  globalEnum: IGlobalEnumType;
  listUserViews: IPartnerView[];
  updateListUserViews: Dispatch<SetStateAction<IPartnerView[]>>;
  userView: IPartnerView | null;
  updateUserView: Dispatch<SetStateAction<IPartnerView | null>>;
  documentActive: IActiveDocument;
  updateDocumentActive: Dispatch<SetStateAction<IActiveDocument>>;
  route: {
    to: string;
    from: string;
  };
  updateRoute: Dispatch<SetStateAction<{ to: string; from: string }>>;
  addDocumentModalIsActive: boolean;
  updateAddDocumentModalIsActive: Dispatch<SetStateAction<boolean>>;
  layoutIsLoading: boolean;
  updateLayoutIsLoading: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
  isOpaque: boolean;
  updateIsOpaque: Dispatch<SetStateAction<boolean>>;
  displayModalUpgradeSubscription: boolean;
  updateDisplayModalUpgradeSubscription: Dispatch<SetStateAction<boolean>>;
  showDropDownSortAndFilter: IShowDropDownSortAndFilter;
  updateShowDropDownSortAndFilter: Dispatch<
    SetStateAction<IShowDropDownSortAndFilter>
  >;
  inputSelectMenuOpenId: string | undefined;
  updateInputSelectMenuOpenId: Dispatch<SetStateAction<string | undefined>>;
}

export const GlobalContext = createContext({} as IProps);
